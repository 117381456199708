import { IBlock } from "../../../framework/src/IBlock";
import { Message } from "../../../framework/src/Message";
import { BlockComponent } from "../../../framework/src/BlockComponent";
import MessageEnum, {
    getName,
} from "../../../framework/src/Messages/MessageEnum";
import { runEngine } from "../../../framework/src/RunEngine";

// Customizable Area Start
import { getStorageData, setStorageData } from "../../../../packages/framework/src/Utilities";
import moment from "moment";

interface Student {
  student_name: string;
  student_id: string;
  passport_number: string;
}

interface Assignee {
  agent_name: string;
  agent_id: string;
}

interface DataResponse {
  students: Student[];
  assignee: Assignee[];
  university: string[];
  application_id: string[];
  status: string[];
}
// Customizable Area End

export const configJSON = require("./config");

export interface Props {
    navigation: any;
    id: string;
    // Customizable Area Start
    // Customizable Area End
}

interface S {
    // Customizable Area Start
    userDetails: {
        attributes: {
            first_name: string;
            role: string;
            image: {
                url: string;
            },
        },
    },
    toggleDrawer: boolean,
    isVisibleModal: boolean;
    associateValue:string,
    reassignValue:string,
    staffRowData: {
        id: number;
        type: string;
        attributes: {
          renderIndex:number;
          application_id: string;
          title: string;
          student: string;
          assignee: string;
          university_name: string;
          due_date: string;
          days_left: string;
          status: string;
        };
      }[];
      order: "asc" | "desc";
      orderBy: string,
    staffRowDataFilter:{
      id: 0,
      type: "",
      attributes: {
        title: "",
        student: "",
        renderIndex: 0,
        application_id: "",
        assignee: "",
        due_date: "",
        days_left: "",
        university_name: "",
        status: "",
      }
    }[];
    itemsPerPage: number,
    currentPage: number,
    filterOpen: boolean,
    filteredCount:number,
    filtersDetailsData: DataResponse,
    studentFilterSelected: Student[],
    agentFilterSelected:Assignee[],
    selectedUniversity: string[],
    selectedApplicationIDs: string[],
    selectedStatus: string[],
    startDate: Date | null,
    endDate: Date | null,
    formattedStartDate:string,
    formattedEndDate:string
    // Customizable Area End
}

interface SS {
    id: any;
}

export default class TaskManagementController extends BlockComponent<Props, S, SS> {
    // Customizable Area Start
    getProfileAPICallId: string = ""
    getAllTasksAPICallId: string = ""
    getAllFiltersListAPICallId: string = ""
    filterData: string = ""
    // Customizable Area End
    constructor(props: Props) {
        super(props);
        this.receive = this.receive.bind(this);

        this.subScribedMessages = [
            getName(MessageEnum.RestAPIResponceMessage),
            getName(MessageEnum.SessionSaveMessage),
            getName(MessageEnum.SessionResponseMessage),
            // Customizable Area Start
            // Customizable Area End
        ];

        this.state = {
            // Customizable Area Start
            userDetails: {
                attributes: {
                    first_name: '',
                    role: '',
                    image: {
                        url: '',
                    },
                },
            },
            toggleDrawer: false,
            isVisibleModal:false,
            associateValue:"vision",
            reassignValue:"none",
            staffRowData: [
              {
                id: 0,
                type: "",
                attributes: {
                  renderIndex:0,
                  application_id: "",
                  title: "",
                  student: "",
                  assignee: "",
                  university_name: "",
                  due_date: "",
                  days_left: "",
                  status: "",
                }
              }
            ],
            orderBy:"renderIndex",
            order: 'asc',
            staffRowDataFilter: [
              {
                id: 0,
                type: "",
                attributes: {
                  title: "",
                  student: "",
                  renderIndex:0,
                  application_id: "",
                  assignee: "",
                  due_date: "",
                  days_left: "",
                  university_name: "",
                  status: "",
                }
              }
            ],
            itemsPerPage: 10,
            currentPage:0,
            filterOpen: false,
            filteredCount:0,
            filtersDetailsData: {
                students: [],
                assignee: [],
                university: [],
                application_id: [],
                status: [],
            },
            studentFilterSelected:[],
            agentFilterSelected:[],
            selectedUniversity:[],
            selectedApplicationIDs: [],
            selectedStatus: [],
            startDate: null,
            endDate: null,
            formattedStartDate: "",
            formattedEndDate: ""
            // Customizable Area End
        };
        runEngine.attachBuildingBlock(this as IBlock, this.subScribedMessages);

        // Customizable Area Start
        // Customizable Area End
    }

    async componentDidMount() {
        // Customizable Area Start
        await this.getFormApprovalProfile()
        await this.getAllTasks()
        await this.getAllTasksListFilter()
        // Customizable Area End
        super.componentDidMount();
        this.getToken();

    }

    getToken = () => {
        const msg: Message = new Message(
            getName(MessageEnum.SessionRequestMessage)
        );
        this.send(msg);
    };


    onHomeClick = (pageName: string) => {
        setStorageData("LandingPageActive", pageName)
        const navigation = new Message(getName(MessageEnum.NavigationMessage));
        navigation.addData(
            getName(MessageEnum.NavigationTargetMessage),
            "LandingPage"
        );
        navigation.addData(getName(MessageEnum.NavigationPropsMessage), this.props);
        this.send(navigation);
    };

    handleToggle = () => {
        this.setState({
            toggleDrawer: !this.state.toggleDrawer
        });
    };

    navigationToAnyPage = (pageName: string) => {
        const navigation = new Message(getName(MessageEnum.NavigationMessage));
        navigation.addData(getName(MessageEnum.NavigationTargetMessage), pageName);
        navigation.addData(getName(MessageEnum.NavigationPropsMessage), this.props);
        this.send(navigation);
    };

    async receive(from: string, message: Message) {
        // Customizable Area Start
        runEngine.debugLog("Message Received", message);
        const messageId = message.id;
        const responseData = message.getData(getName(MessageEnum.RestAPIResponceDataMessage));

        const responseCallbacks = {
            [this.getProfileAPICallId]: this.getProfileFormSuccessCallBack,
            [this.getAllTasksAPICallId]: this.getAllTasksSuccessCallBack,
            [this.getAllFiltersListAPICallId]: this.AllTaskFilterListSuccessCallBack,
            [this.filterData]: this.FilterSuccessCallBack
        };

        if (getName(MessageEnum.RestAPIResponceMessage) === messageId) {
            const callback = responseCallbacks[responseData];
            if (callback) {
                callback(message);
            }
        }
        // Customizable Area End
    }

    // Customizable Area Start

    apiCall = async (apiData: any) => {
        const { contentType, method, endPoint, body, type } = apiData;

        let token = await getStorageData("token");

        const header = {
            "Content-Type": contentType,
            token: token,
        };
        const requestMessage = new Message(
            getName(MessageEnum.RestAPIRequestMessage)
        );

        requestMessage.addData(
            getName(MessageEnum.RestAPIResponceEndPointMessage),
            endPoint
        );

        requestMessage.addData(
            getName(MessageEnum.RestAPIRequestHeaderMessage),
            JSON.stringify(header)
        );

        requestMessage.addData(
            getName(MessageEnum.RestAPIRequestMethodMessage),
            method
        );

        body && type !== "formData"
            ? requestMessage.addData(
                getName(MessageEnum.RestAPIRequestBodyMessage),
                JSON.stringify(body)
            )
            : requestMessage.addData(
                getName(MessageEnum.RestAPIRequestBodyMessage),
                body
            );

        runEngine.sendMessage(requestMessage.id, requestMessage);

        return requestMessage.messageId;
    };

    getFormApprovalProfile = async () => {
        let userDetails = await getStorageData("userDetails")
        this.getProfileAPICallId = await this.apiCall({
            contentType: "application/json",
            method: 'GET',
            endPoint: configJSON.getProfileEndPoint + userDetails
        });
    }

    getProfileFormSuccessCallBack = (message: Message) => {
        const responseJson = message.getData(getName(MessageEnum.RestAPIResponceSuccessMessage));
        this.setState({ userDetails: responseJson.data });
    };

    showAddModal = () => {
        this.setState({
            isVisibleModal: !this.state.isVisibleModal,
        });
    };


  handleBranchChange = (event: { target: { value: any; }; }) => {
    this.setState({ associateValue: event.target.value });
  };

  getAllTasks = async () => {
    this.getAllTasksAPICallId = await this.apiCall({
      contentType: "application/json",
      method: 'GET',
      endPoint: configJSON.getAllTasks
    });
  }

  getAllTasksSuccessCallBack = (message: Message) => {
    const responseJson = message.getData(getName(MessageEnum.RestAPIResponceSuccessMessage));
    this.setState({
      staffRowData: responseJson.data.map((item: any, index: number) => {
        return {
          ...item,
          attributes: {
            ...item.attributes,
            renderIndex: index + 1,
          }
        };
      }).slice(this.state.currentPage, this.state.itemsPerPage),
      staffRowDataFilter:responseJson.data.map((item: any, index: number) => {
        return {
          ...item,
          attributes: {
            ...item.attributes,
            renderIndex: index + 1,
          }
        };
      })
    });
  };

  handleRequestSort = (property: string) => {
    const orderBy = property;
    let order: "asc" | "desc" = "asc";
    if (this.state.orderBy === property && this.state.order === "asc") {
      order = "desc";
    }
    this.setState({ orderBy, order });
  };

  sortData = (data: any, orderBy: string, order: string) => {
    return [...data].sort((sortingA, sortingB) => {
      const aValue = sortingA.attributes[orderBy];
      const bValue = sortingB.attributes[orderBy];
      if (typeof aValue === "string" && typeof bValue === "string") {
        return order === "asc" ? aValue.localeCompare(bValue) : bValue.localeCompare(aValue);
      } else if (typeof aValue === "number" && typeof bValue === "number") {
        return order === "asc" ? aValue - bValue : bValue - aValue;
      }
      return 0;
    });
  }

  handelPropsChange = (e: unknown[]) => {
    if (e.length !== 0) {
      this.setState({
        staffRowData: e as Array<{
          id: number,
          type: string,
          attributes: {
            title: string,
            student: string,
            renderIndex: number,
            application_id: string,
            assignee: string,
            due_date: string,
            days_left: string,
            university_name: string,
            status: string,
          }
        }>
      })
    }
  }

  onFilterClick = () => {
    this.setState({ filterOpen: !this.state.filterOpen });
    
    if(this.state.filteredCount === 0) {
      this.handleResetBtn(); 
    }
  };

  getAllTasksListFilter = async () => {
    this.getAllFiltersListAPICallId = await this.apiCall({
      contentType: "application/json",
      method: 'GET',
      endPoint: configJSON.getAllTaskFilterList
    });
  }

  AllTaskFilterListSuccessCallBack = (message: Message) => {
    const responseJson = message.getData(getName(MessageEnum.RestAPIResponceSuccessMessage));
    this.setState({filtersDetailsData: responseJson})
  }

  handleReset = (event: React.MouseEvent<SVGSVGElement, MouseEvent>) => {
    event?.stopPropagation()
    this.setState({
      filteredCount: 0,
      selectedApplicationIDs:[],
      selectedUniversity:[],
      selectedStatus:[],
      agentFilterSelected:[],
      studentFilterSelected:[],
      startDate: null,
      endDate: null,
    }, () => {
      this.getAllTasks()
    });
  };

  handleSelectionChange = (event: React.ChangeEvent<{}>, newValue: Student[]) => {
    this.setState({studentFilterSelected:newValue})
  };

  handleRemoveItem = (item: string) => {
    this.setState((prevState) => ({
      studentFilterSelected: prevState.studentFilterSelected.filter(
        (option) => option.student_id !== item
      ),
    }));
  }

  agentChange = (event: React.ChangeEvent<{}>, newValue: Assignee[]) => {
    this.setState({agentFilterSelected:newValue})
  };

  handleRemoveAgent = (item: string) => {
    this.setState((prevState) => ({
      agentFilterSelected: prevState.agentFilterSelected.filter(
        (option) => option.agent_id !== item
      ),
    }));
  }

  universityChange = (event: React.ChangeEvent<{}>, newValue: string[]) => {
    this.setState({selectedUniversity:newValue})
  };

  handleRemoveUniversity = (item: string) => {
    this.setState((prevState) => ({
      selectedUniversity: prevState.selectedUniversity.filter(
        (option) => option !== item
      ),
    }));
  }

  applicationIDChange = (event: React.ChangeEvent<{}>, newValue: string[]) => {
    this.setState({selectedApplicationIDs:newValue})
  };

  handleRemoveApplicationID = (item: string) => {
    this.setState((prevState) => ({
      selectedApplicationIDs: prevState.selectedApplicationIDs.filter(
        (option) => option !== item
      ),
    }));
  }

  statusChange = (event: React.ChangeEvent<{}>, newValue: string[]) => {
    this.setState({selectedStatus:newValue})
  };

  handleRemoveStatus = (item: string) => {
    this.setState((prevState) => ({
      selectedStatus: prevState.selectedStatus.filter(
        (option) => option !== item
      ),
    }));
  }

  handleDateChange = (dates: [Date, Date]) => {
    const [start, end] = dates;
    const startDateFormatted = start ? moment(start).format('YYYY-MM-DD') : "";
    const endDateFormatted = end ? moment(end).format('YYYY-MM-DD') : "";   

    this.setState({
      startDate: start,
      endDate: end,
      formattedStartDate:startDateFormatted,
      formattedEndDate:endDateFormatted
    });
  };

  collectFilterData = async () => {
    const { selectedStatus, selectedApplicationIDs,selectedUniversity, agentFilterSelected, studentFilterSelected, startDate, endDate } = this.state;
  
    const filters = {
      selectedStatus, selectedApplicationIDs,selectedUniversity, agentFilterSelected, studentFilterSelected
    };
  
    let activeFilters = Object.values(filters).filter(val => val && val.length !== 0).length;
  
    if(startDate){
      activeFilters++
    }

    if(endDate){
      activeFilters++
    }

    this.setState({ filteredCount: activeFilters }, () => {
      this.handleFilterPopUp();
    });

    this.callFilterApi()
  };

  handleFilterPopUp = () => {
    this.setState({ filterOpen: !this.state.filterOpen });

    if(this.state.filteredCount === 0) {
      this.handleResetBtn(); 
    }
  }


  callFilterApi = async () => {
    const { selectedStatus, selectedApplicationIDs,selectedUniversity, agentFilterSelected, studentFilterSelected, startDate, endDate } = this.state;
    const baseUrl = configJSON.pending_task_filter;

    const selectedStatusData = selectedStatus.map((item) => `${item}`.toLowerCase().replace(/\s+/g, '_'));
    const selectedApplicationIDsData = selectedApplicationIDs.map((item) => item);
    const selectedUniversityData = selectedUniversity.map((item) => item);
    const agentFilterSelectedData = agentFilterSelected.map((item) => item.agent_id);
    const studentFilterSelectedData = studentFilterSelected.map((item) => item.student_id);

    const formatDate = (date: string) => date ? moment(date).format('YYYY-MM-DD') : null;
  
    const start = formatDate(startDate as unknown as string);
    const end = formatDate(endDate as unknown as string);

    const endPointData = `application_id=${selectedApplicationIDsData}&student_id=${studentFilterSelectedData}&university_name=${encodeURIComponent(JSON.stringify(selectedUniversityData))}&asignee=${agentFilterSelectedData}&from=${start}&to=${end}&status=${encodeURIComponent(JSON.stringify(selectedStatusData))}`

    this.filterData = await this.apiCall({
      contentType: "application/json",
      method: 'GET',
      endPoint: `${baseUrl}?${endPointData}`,
    });
  }

  FilterSuccessCallBack = (message: Message) => {
    const responseJson = message.getData(getName(MessageEnum.RestAPIResponceSuccessMessage));
    this.setState({staffRowData: responseJson.data})
  }

  handleResetBtn = () => {
    this.setState({
      filteredCount: 0,
      selectedApplicationIDs:[],
      selectedUniversity:[],
      selectedStatus:[],
      agentFilterSelected:[],
      studentFilterSelected:[],
      startDate: null,
      endDate: null,
    }, () => {
      this.getAllTasks()
    });
  }
    // Customizable Area End
}
