import React from "react";
// Customizable Area Start
import {
  Button, TextField, Box, Typography, Grid, Select, Snackbar, MenuItem, TableCell, TableRow, TableContainer, Paper, TableHead, Table, TableBody, Card, CardContent, Switch, AppBar, Tabs, Tab, Modal, SvgIcon,
  FormHelperText,
  List,
  ListItem,
  ListItemText,
  RadioGroup,
  FormControlLabel,
  Radio,
  RadioProps,
  Tooltip,
  TooltipProps,
  Checkbox,
  Chip
} from "@material-ui/core";
import { styled } from "@material-ui/core/styles";
import LinearProgress from "@material-ui/core/LinearProgress";
import AppHeader from "../../../components/src/AppHeader.web";
import EditIcon from "@material-ui/icons/Edit";
import DeleteIcon from '@mui/icons-material/Delete';
import "react-datepicker/dist/react-datepicker.css";
import CustomisableUserProfilesController, { configJSON, Props } from "./CustomisableUserProfilesController.web";
import * as IMG_CONST from "./assets";
import Alert from "@material-ui/lab/Alert";
import SideMenuBar from "../../../../packages/components/src/SideMenuBar.web";
import Loader from "../../../components/src/Loader.web"
import { CheckCircle, KeyboardArrowDown, Close } from "@material-ui/icons";
import TodayIcon from "@material-ui/icons/Today";
import Calendar from "react-calendar";
import AddIcon from "@material-ui/icons/Add";
import CloseIcon from '@mui/icons-material/Close';
import ExpandMoreIcon from "@material-ui/icons/ExpandMore";
import ErrorOutlineIcon from "@material-ui/icons/ErrorOutline";
import { Formik, FormikErrors, FormikTouched } from "formik";
import Autocomplete from '@mui/material/Autocomplete';
import CancelOutlinedIcon from '@mui/icons-material/CancelOutlined';

// added for Agreement
import {
  FileDownload as FileDownloadIcon,
  VisibilityOutlined as VisibilityOutlinedIcon,
  PictureAsPdf as PictureAsPdfIcon,
  DeleteOutline as DeleteOutlineIcon,
  CheckCircle as CheckCircleIcon,
  Image as ImageIcon,
  Description as DescriptionIcon
} from '@mui/icons-material';
// end for agreement

// added for manage branch
import CreateIcon from '@mui/icons-material/Create';
import { Bounce, ToastContainer } from "react-toastify";
import { tooltipClasses } from "@mui/material";
import PaginationCompoent from "../../pagination/src/PaginationCompoent.web";
// end for manage branch
type DocumentCategory = 'personal_id' | 'agent_docs' | 'business_registration_certificate';

interface Student {
  student_name: string;
  student_id: string;
}

interface Agent {
  agent_name: string;
  agent_id: string;
}

interface StudentForReassign {
  student_name: string;
  student_id: string;
  passport_number: string
}

interface AgentForReassign {
  id: number;
  agent_name: string;
  agent_id: string;
}

// Customizable Area End

export default class CustomisableUserProfiles extends CustomisableUserProfilesController {
  constructor(props: Props) {
    super(props);
    // Customizable Area Start
    // Customizable Area End
  }
  // Customizable Area Start

  errorCounter = (errors:string | undefined, touched:string | boolean | undefined) =>{
    return errors && touched && (
        <RequiredTxt>{errors}</RequiredTxt>
      )
  }

  renderProfileStatus = () => {
    const { profileStatus } = this.state
    return <ProfileStatusBox>
      {profileStatus === "under_review" && <Typography className="underReview">{configJSON.underReview}</Typography>}
      {profileStatus === "rejected" && <Typography className="rejected">{configJSON.rejected}</Typography>}
      {profileStatus === "conditional_approved" && <Typography className="conditionallyApproved">{configJSON.conditionallyApproved}</Typography>}
      {profileStatus === "fully_approved" && 
      <BootstrapTooltip placement="bottom-start" title="Any changes to your information can be added on backend only.">
        <Typography className="approved">{configJSON.fullyApproved}</Typography>
      </BootstrapTooltip>
      }
    </ProfileStatusBox>
  };

  renderProfileGrid = () => {
    const { selected } = this.state;
    return (
      <Grid item xl={12} lg={12} md={12} sm={12} xs={12} className="profileGrid">
        <Box className="headerBox">
          <Box className="headingBox">
            <Typography className="heading" data-test-id="viewProfileTestID">
              {configJSON.viewProfileTxt}
            </Typography>
            {this.state.UserRole !== "counsellor" && this.renderProgressBar()}
          </Box>
          <Box className="toggleContainer">
            <ToggleContainer>
              <Box
                data-test-id="handleToggleAboveTstId"
                className={selected === "Account" ? `selectedClass ${ this.state.UserRole === "counsellor" && 'ExpandClass' }` : "nonSelectedClass"}
                onClick={() => this.handleToggleAbove("Account")}
              >
                {configJSON.accountTxt}
              </Box>
              {this.state.UserRole !== "counsellor" &&
                <Box
                  data-test-id="handleToggleAboveTstId2"
                  className={selected === "Cp profile" ? "selectedClass" : "nonSelectedClass"}
                  onClick={() => this.handleToggleAbove("Cp profile")}
                >
                  {configJSON.cpText}
                </Box>
              }
            </ToggleContainer>
          </Box>
        </Box>
        <Box className="profileBox">
          <Box className="imageBox">
            {this.state.userDetails?.attributes?.image === null ? (
              <img src={IMG_CONST.defaultImg} alt="Preview" className="userImage" />
            ) : (
              <img src={this.state.userDetails?.attributes?.image.url} alt="Preview" className="userImage" />
            )}
            <label htmlFor="image-upload">
              <input
                id="image-upload"
                type="file"
                accept="image/*"
                className="imageInputBtn"
                onChange={this.handleFileChange}
                data-test-id="profileImgTestId"
              />
              <EditIcon className="editIcon" />
            </label>
          </Box>
          <Box className="textBox">
            <Typography className="userName" data-test-id="companyTestId">
              {this.state.userDetails?.attributes?.company_name}
            </Typography>
            <Box className="userDetailsInnerMainBox">
              <Typography className="userKey">{configJSON.userMemberTxt}</Typography>
              <Typography className="userValue">
                {this.state.userDetails?.attributes?.created_at}
              </Typography>
            </Box>
            <Box className="userDetailsInnerMainBox">
              <Typography className="userKey">{configJSON.teamSizeTxt}</Typography>
              <Typography className="userValue">
                {this.state.userDetails?.attributes?.no_of_employees}
              </Typography>
            </Box>
            <Box className="userDetailsInnerMainBox">
              <Typography className="userKey">{configJSON.contactPersonTxt}</Typography>
              <Typography className="userValue">
              {this.state.userDetails?.attributes?.first_name}
              </Typography>
            </Box>
            <Box className="userDetailsInnerMainBox agentContactDetails">
            {
            this.state.userDetails?.attributes?.phone_number &&
                <Box className="innerBox">
                  <img src={IMG_CONST.phoneIcon} className="detailsIcon" />
                  <Typography className="detailsTxtNew">
                  +{this.state.userDetails?.attributes?.country_code?.toString() + '-' + this.state.userDetails?.attributes?.phone_number}
                  </Typography>
                </Box>
  }
              
              {this.state.userDetails?.attributes?.email !== "" && (
                <Box className="innerBox">
                  <img src={IMG_CONST.smsIcon} className="detailsIcon" />
                  <Typography className="detailsTxtNew">
                    {this.state.userDetails?.attributes?.email}
                  </Typography>
                </Box>
              )}
              {this.state.userDetails?.attributes?.country !== "" && (
                <Box className="innerBox">
                  <img src={IMG_CONST.locationIcon} className="detailsIcon" />
                  <Typography className="detailsTxtNew">
                  {this.state.countryData.filter((item)=>{
                        return item.isoCode === this.state.userDetails?.attributes?.country
                      })[0]?.name}
                  </Typography>
                </Box>
              )}
            </Box>
          </Box>
        </Box>
      </Grid>
    );
  };

  renderProgressBar = () => {
    return (
      <ProgressBox>
        <Typography className="percantageTxt">{parseFloat(this.state.profileCompletionPercentage)||0}{configJSON.percentageSymbol}</Typography>
        <Box className="linearBox">
        <LinearProgress variant="determinate" value={Number(this.state.profileCompletionPercentage)||0} />
        </Box>
        {this.state.profileStatus?this.renderProfileStatus():<Button data-test-id="sendForReviewId" onClick={this.handleSendForReview} className="sendBtn">{configJSON.sendReviewBtn}</Button>}

              </ProgressBox>
    )
  }

  renderMemberBox = () => {
    const isFullyApproved = this.state.profileStatus === "fully_approved"
    return (
      <Box className="formFieldBox">
        <Typography className="fieldText">{configJSON.memberTxt} <span className="starTxt">{configJSON.startTxt}</span>
        </Typography>
        <Box className="inputFieldBox">
          <TextField
            type="text"
            placeholder={configJSON.memberNamePrompt}
            className="inputField"
            onChange={this.memberTxtChange}
            value={this.state.memberTxtValue}
            data-test-id="memberNameTestId"
            disabled={isFullyApproved}
          ></TextField>
        </Box>
          <RequiredTxt>{this.state.memberErrorTxt}</RequiredTxt>
      </Box>
    )
  };

  renderMemberEmail = () => {
    const isFullyApproved = this.state.profileStatus === "fully_approved"
    return (
      <Box className="formFieldBox">
        <Typography className="fieldText">{configJSON.memberEmailTxt} <span className="starTxt">{configJSON.startTxt}</span></Typography>
        <Box className="inputFieldBox">
          <TextField
            type="text"
            placeholder="Enter email address"
            className="inputField"
            data-test-id="emailTestId"
            onChange={this.memberEmailChange}
            value={this.state.memberEmailTxtValue}
            disabled={isFullyApproved}
          ></TextField>
        </Box>
          <RequiredTxt>{this.state.memberEmailErrorTxt}</RequiredTxt>
      </Box>
    )
  }

  renderGenderBox = () => {
    const isFullyApproved = this.state.profileStatus === "fully_approved"
    return (
      <Box className="formFieldBox">
        <Typography className="fieldText">
          {configJSON.genderTxt} <span className="starTxt">{configJSON.startTxt}</span>
        </Typography>
        <Box className="inputFieldBox">
          <SelectInput
            displayEmpty
            MenuProps={{
              disableScrollLock: true,
              anchorOrigin: {
                vertical: "bottom",
                horizontal: "left"
              },
              getContentAnchorEl: null,
            }}
            style={{
              color: this.state.genderValue == "none" ? "#999" : "#000"
            }}
            value={this.state.genderValue}
            data-test-id="genderTestId"
            IconComponent={KeyboardArrowDown}
            onChange={this.genderChange}
            disabled={isFullyApproved}
            className="selectField"
          >
            {this.state.genderValue == "none" && (
              <MenuItem disabled value="none">
                {configJSON.memberGenderPrompt}
              </MenuItem>
            )}
            <MenuItem value="Male">{configJSON.genderMale}</MenuItem>
            <MenuItem value="Female">{configJSON.genderFemale}</MenuItem>
          </SelectInput>
        </Box>
          <RequiredTxt>{this.state.genderErrorTxt}</RequiredTxt>
        
      </Box>
    )
  };

  renderCountryBox = () => {
    const isFullyApproved = this.state.profileStatus === "fully_approved"  
    return (

      <Box className="formFieldBox">
        <Typography className="fieldText">
          {configJSON.countryTxt} <span className="starTxt">{configJSON.startTxt}</span>
        </Typography>
        <Box className="inputFieldBox">
          <Select
            id="myDropdown"
            className="selectField"
            displayEmpty
            disabled={isFullyApproved}
            MenuProps={{
              disableScrollLock: true,
              anchorOrigin: {
                vertical: "bottom",
                horizontal: "left"
              },
              getContentAnchorEl: null,
            }}
            IconComponent={KeyboardArrowDown}
            defaultValue="default"
            onChange={this.handleCountryChange}
            value={this.state.countryValue}
            data-test-id="countryDataTestId"
            style={{
              color: this.state.countryValue == "none" ? "#999" : "#000"
            }}
          >
            {this.state.countryValue == "none" && (
              <MenuItem disabled value="none">
                {configJSON.memberCountryPrompt}
              </MenuItem>
            )}
            {this.state.countryData.map((item) => (
              <MenuItem value={item.isoCode}>{item.name}</MenuItem>
            ))}
          </Select>
        </Box>
        {this.state.countryError && (
          <RequiredTxt>{this.state.countryErrorTxt}</RequiredTxt>
        )}
      </Box>
    )
  };

  renderStateBox = () => {
    const isFullyApproved = this.state.profileStatus === "fully_approved"  
    return (
      <Box className="formFieldBox">
        <Typography className="fieldText">
          {configJSON.stateTxt} <span className="starTxt">{configJSON.startTxt}</span>
        </Typography>
        <Box className="inputFieldBox">
          <Select
            id="myDropdown"
            className="selectField"
            displayEmpty
            disabled={isFullyApproved}
            MenuProps={{
              disableScrollLock: true,
              anchorOrigin: {
                vertical: "bottom",
                horizontal: "left",
              },
              getContentAnchorEl: null,
            }}
            IconComponent={KeyboardArrowDown}
            defaultValue="default"
            value={this.state.stateValue}
            data-test-id="stateDataTestId"
            style={{
              color: this.state.stateValue == "none" ? "#999" : "#000"
            }}
            onChange={this.handleStateChange}
          >
            {(this.state.stateValue) == "none" && (
              <MenuItem disabled value="none">
                {configJSON.memberStatePrompt}
              </MenuItem>
            )}
            {this.state.stateData.map((item: { name: string }) => (
              <MenuItem value={item.name}>{item.name}</MenuItem>
            ))}
          </Select>
        </Box>
        {this.state.stateError && (
          <RequiredTxt>{this.state.stateErrorTxt}</RequiredTxt>
        )}
      </Box>
    )
  };

  renderCityBox = () => {
    const isFullyApproved = this.state.profileStatus === "fully_approved"  
    return (
      <Box className="formFieldBox">
        <Typography className="fieldText">
          {configJSON.cityTxt} <span className="starTxt">{configJSON.startTxt}</span>
        </Typography>
        <Box className="inputFieldBox">
          <TextField
            type="text"
            placeholder="Enter city name"
            className="inputField"
            onChange={this.memberCityChange}
            value={this.state.cityValue}
            data-test-id="cityDataTestId"
            disabled={isFullyApproved}
          ></TextField>
        </Box>
          <RequiredTxt>{this.state.cityErrorTxt}</RequiredTxt>
        
      </Box>
    )
  };

  renderCalendar = () => {
    const isFullyApproved = this.state.profileStatus === "fully_approved"  
    return (
      <Box className="formFieldBox">
        <Typography className="fieldText">{configJSON.birthdayTxt} <span className="starTxt">{configJSON.startTxt}</span></Typography>
        <Box className="inputFieldBox">
          <Box
            className="inputFieldCalender"
            onClick={this.onCalenderClick}
            data-test-id="dateTestID"
          >
             <Typography data-test-id="calenderValueTestId" className={isFullyApproved?" disabled":""}
            >{this.state.birthDateValue?.toString()}</Typography>
            <TodayIcon className="calendarIcon" />
          </Box>
          {this.state.isCalender &&
            <Calendar
              onClickDay={this.handleDateChange}
              value={this.state.birthDate}
              className="calenderBox"
              data-test-id="calenderTestID"
              maxDate={new Date()}
            />
          }
          {this.state.birthdayError && (
            <RequiredTxt>{this.state.birthdayErrorTxt}</RequiredTxt>
          )}
        </Box>
      </Box>
    )
  };

  renderMaritalStatus = () => {
    const isFullyApproved = this.state.profileStatus === "fully_approved"  
    return (
      <Box className="formFieldBox">
        <Typography className="fieldText">
          {configJSON.maritalStatusTxt} <span className="starTxt">{configJSON.startTxt}</span>
        </Typography>
        <Box className="inputFieldBox">
          <SelectInput
            displayEmpty
            MenuProps={{
              disableScrollLock: true,
              anchorOrigin: {
                vertical: "bottom",
                horizontal: "left"
              },
              getContentAnchorEl: null,
            }}
            style={{
              color: this.state.maritalValue == "none" ? "#999" : "#000"
            }}
            value={this.state.maritalValue}
            IconComponent={KeyboardArrowDown}
            onChange={this.maritalChange}
            data-test-id="maritalTestId"
            disabled={isFullyApproved}
            className="selectField"
          >
            {this.state.maritalValue == "none" && (
              <MenuItem disabled value="none">
                {configJSON.memberMaritalStatusPrompt}
              </MenuItem>
            )}
            <MenuItem value="Single">{configJSON.maritalSingle}</MenuItem>
            <MenuItem value="Married">{configJSON.maritalMarried}</MenuItem>
          </SelectInput>
        </Box>
        {this.state.maritalError && (
          <RequiredTxt>{this.state.maritalErrorTxt}</RequiredTxt>
        )}
      </Box>
    )
  };

  renderMarrigeBox = () => {
    const isDisabled = this.state.maritalValue !== "Married"|| this.state.profileStatus === "fully_approved";
    return (
      <Box className="formFieldBox">
        <Typography className="fieldText">{configJSON.marriageTxt}</Typography>
        <Box className="inputFieldBox">
          <Box
            className="inputFieldCalender"
            onClick={!isDisabled ? this.onMarrigeCalendarClick : undefined}
            data-test-id="dateMarriageTestID"
            style={{backgroundColor: isDisabled ? "#e0e0e0" : "transparent",}}
          >
            <Typography
              style={{ color: this.state.marriageDateValue == "Select marriage anniversary" ? "#999" : "#000" }} data-test-id="calenderValueTestId">
               {isDisabled ? "" : this.state.marriageDateValue?.toString()}
            </Typography>
            <TodayIcon className="calendarIcon" />
          </Box>
          {this.state.isMarriageCalender &&
            <Calendar
              onClickDay={this.handleMarrigeDateChange}
              value={this.state.marriageDate}
              className="calenderBox"
              data-test-id="calenderMarriageTestID"
              maxDate={new Date()}
            />
          }
            <RequiredTxt>{this.state.marriageErrorTxt}</RequiredTxt>
          
        </Box>
      </Box>
    )
  };

  renderInfoForm = () => {
    const isFullyApproved = this.state.profileStatus === "fully_approved"  
    return (
      <form data-test-id="submitFormId"
        onSubmit={this.handleSubmit}>
        <Grid item container xl={12} lg={12} md={12} sm={12} xs={12} spacing={2}>
          <Typography data-test-id="personalTxtID" className="heading">{configJSON.personalTxt}</Typography>
          <Grid container className="personalInfo">
            <Grid item xl={4} lg={4} md={6} sm={6} xs={12} 
            className="infoFormField">
              {this.renderMemberBox()}
            </Grid>
            <Grid item xl={4} lg={4} md={6} sm={6} xs={12} 
            className="infoFormField">
              {this.renderCountryBox()}
            </Grid>
            <Grid item xl={4} lg={4} md={6} sm={6} xs={12} 
            className="infoFormField">
              {this.renderMaritalStatus()}
            </Grid>
            <Grid item xl={4} lg={4} md={6} sm={6} xs={12} 
            className="infoFormField">
              {this.renderMobileBox()}
            </Grid>
            <Grid item xl={4} lg={4} md={6} sm={6} xs={12} 
            className="infoFormField">
              {this.renderStateBox()}
            </Grid>
            <Grid item xl={4} lg={4} md={6} sm={6} xs={12} 
            className="infoFormField">
              {this.renderMarrigeBox()}
            </Grid>
            <Grid item xl={4} lg={4} md={6} sm={6} xs={12} 
            className="infoFormField">
              {this.renderMemberEmail()}
            </Grid>
            <Grid item xl={4} lg={4} md={6} sm={6} xs={12} 
            className="infoFormField">
              {this.renderCityBox()}
            </Grid>
            <Grid item xl={4} lg={4} md={6} sm={6} xs={12} 
            className="infoFormField" />
            <Grid item xl={4} lg={4} md={6} sm={6} xs={12} 
            className="infoFormField">
              {this.renderCalendar()}
            </Grid>
            <Grid item xl={4} lg={4} md={6} sm={6} xs={12} 
            className="infoFormField">
              {this.renderGenderBox()}
            </Grid>
          </Grid>
        </Grid>
        <Grid item xl={12} lg={12} md={12} sm={12} xs={12} className="btnGrid">
          <Button
            type="submit"
            className="saveInfo"
            data-test-id="submitBtnTestID"
            disabled={isFullyApproved}
          >
            {configJSON.saveBtnTxt}
          </Button>
        </Grid>
      </form>
    );
  };

  renderSecurityForm = () => {
    return (
      <form onSubmit={this.onPswrdSave} data-test-id="changeSubmitForm">
        <Grid item xl={12} lg={12} md={12} sm={12} xs={12} className="securityGrid">
          <Typography className="heading">{configJSON.securityTxt}</Typography>
          <Grid container className="personalInfo">
            <Grid item xl={4} lg={4} md={4} sm={12} xs={12} className="infoFormField securityFormField">
              <Box className="formFieldBox">
                <Typography className="fieldText">
                  {configJSON.currentPswrdTxt} <span className="starTxt">{configJSON.startTxt}</span>
                </Typography>
                <Box className="inputFieldBox">
                  <TextField
                    type="password"
                    placeholder={configJSON.currentPasswordPrompt}
                    className="securityInputField"
                    value={this.state.curentPswrd}
                    onChange={this.curentPswrdChange}
                    data-test-id="currentTestID"
                  />
                </Box>
                  <RequiredTxt>{this.state.currentPswrdErrorTxt}</RequiredTxt>
              </Box>
            </Grid>
            <Grid item xl={4} lg={4} md={4} sm={12} xs={12} className="infoFormField securityFormField">
              <Box className="formFieldBox">
                <Typography className="fieldText">
                  {configJSON.newPswrdTxt}<span className="starTxt"> {configJSON.startTxt}</span>
                </Typography>
                <Box className="inputFieldBox">
                  <TextField
                    type="password"
                    placeholder={configJSON.newPasswordPrompt}
                    className="securityInputField"
                    value={this.state.newPswrd}
                    onChange={this.newPswrdChange}
                    data-test-id="newTestID"
                  />
                </Box>
                  <RequiredTxt>{this.state.newPswrdErrorTxt}</RequiredTxt>
                
              </Box>
            </Grid>
            <Grid item xl={4} lg={4} md={4} sm={12} xs={12} className="infoFormField securityFormField">
              <Box className="formFieldBox">
                <Typography className="fieldText">
                  {configJSON.confirmPswrdTxt}<span className="starTxt"> {configJSON.startTxt}</span>
                </Typography>
                <Box className="inputFieldBox">
                  <TextField
                    type="password"
                    placeholder={configJSON.confirmPasswordPrompt}
                    className="securityInputField"
                    value={this.state.confirmPswrd}
                    onChange={this.confirmPswrdChange}
                    data-test-id="confrimTestID"
                  />
                </Box>
                  <RequiredTxt>{this.state.confrimPswrdErrorTxt}</RequiredTxt>
                
              </Box>
            </Grid>
          </Grid>
        </Grid>
        <Grid item xl={12} lg={12} md={12} sm={12} xs={12} className="btnGrid">
          <Button type="submit" className="saveInfo" data-test-id="pswrdBtn">
            {configJSON.pswrdBtnTxt}
          </Button>
        </Grid>
      </form>
    );
  };

  renderMobileBox = () => {
    const isFullyApproved = this.state.profileStatus === "fully_approved"  

    const ContryValue = this.state.countryCodes.filter((item)=>{
      return item.alpha2 === this.state.countryValue
    })
    return (
      <Box className="formFieldBox">
        <Typography className="fieldText">{configJSON.mobileTxt} <span className="starTxt">{configJSON.startTxt}</span>
        </Typography>
        <Box className="inputFieldBox">
        <MobileNumberWrapper data-test-id="mobileNumberWrapperTestID" className="renderMobileBoxClass">
            <Box data-test-id="branchCountryCodeBoxTestID">
              <SelectInput
                displayEmpty
                MenuProps={{
                  getContentAnchorEl: null,
                  anchorOrigin: {
                    horizontal: "left",
                    vertical: "bottom"
                  },
                  disableScrollLock: true,
                }}
                style={{
                  color: this.state.stateValue === "none" ? "#999" : "#000"
                }}
                IconComponent={KeyboardArrowDown}
                value={`${ContryValue[0]?.country_code}-${ContryValue[0]?.alpha2}`}
                data-test-id="branchCountryCodeTestId"
                defaultValue="default"
                disabled={isFullyApproved}
                className="selectField"
              >
                {this.state.countryCodes.map((item) => (
                  <MenuItem key={`${item.country_code}-${item.alpha2}`} value={`${item.country_code}-${item.alpha2}`}>
                    {`+${item.country_code} (${item.alpha2})`}
                  </MenuItem>
                ))}
              </SelectInput>
            </Box>
            <Box className="inputFieldBox" data-test-id="branchContactNumberBoxTestID">
            <TextField
                className="inputField"
                onChange={this.mobileChange}
                data-test-id="mobileNumberTstId"
                placeholder={'Enter mobile phone number'}
                value={this.state.mobileNumberValue}
                error={!!this.state.branchMobileNumberError}
                // onBlur={()=>{
                //   this.validateMobile(this.state.mobileNumberValue,`${ContryValue[0]?.country_code}-${ContryValue[0]?.alpha2}`)
                // }}
                name="number"
                type="tel"
                disabled={isFullyApproved}
              />
            </Box>
          </MobileNumberWrapper>
        </Box>
        <RequiredTxt>{this.state.mobileNumberErrorTxt}</RequiredTxt>
        {this.state.mobileNumberErrorTxt === "" && !!this.state.branchMobileNumberError && (
          <RequiredTxt>{this.state.branchMobileNumberError}</RequiredTxt>
        )}
      </Box>
    )
  };

  renderActivityTable = () => {
    return (
      <TableMainBox>
        <Typography className="heading NewActivity">{configJSON.activityTxt}</Typography>
        <TableContainer component={Paper}>
          <Table aria-label="simple table">
            <TableHead>
              <TableRow>
                <TableCell align="center">{configJSON.tableHeader1}</TableCell>
                <TableCell align="center">{configJSON.tableHeader2}</TableCell>
                <TableCell align="center">{configJSON.tableHeader3}</TableCell>
                <TableCell align="center">{configJSON.tableHeader4}</TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {
                this.state.activityTableData.map((rowValue) => {
                  let dataValue = rowValue.attributes;
                  return (
                    <TableRow key={rowValue.id}>
                      <TableCell align="center">{dataValue.action}</TableCell>
                      <TableCell align="center">{dataValue.description}</TableCell>
                      <TableCell align="center">{dataValue.ip_address}</TableCell>
                      <TableCell align="center">{dataValue.date}</TableCell>
                    </TableRow>
                  );
                })
              }
            </TableBody>
          </Table>
        </TableContainer>
      </TableMainBox>)
  };

  renderNotification = () => {
    const { notifications, allowAll } = this.state;
    return (
      <NotificationBox>
        <Typography className="heading maarginBottom">
          {configJSON.notificationsTxt}
        </Typography>
        <Grid container spacing={2}>
          <Grid item xs={12} md={6}>
            <Card>
              <CardContent style={{ padding: "0px" }}>
                <div className="alllowAllBox">
                  <Box className="imageTxtBox">
                    <img src={IMG_CONST.emailImg} className="emailImg" alt="Email Icon" />
                    <Typography className="emailTxt" variant="h6">
                      {configJSON.emailNotification}
                    </Typography>
                  </Box>
                  <div className="imageTxtBox">
                    <Typography className="normalTxt" variant="body1">
                      Allow all
                    </Typography>
                    <IOSSwitch
                      className="handleToggleNotificationsTstid"
                      checked={allowAll}
                      onChange={this.handleAllowAllToggle}
                      color="primary"
                      data-test-id="handleAllowAllToggleTstId"
                    />
                  </div>
                </div>

                <Grid container direction="column">
                  <Grid item className="gridBox">
                    <Typography variant="body1" className="normalTxt">
                      {configJSON.applicationsStage}
                    </Typography>
                    <IOSSwitch
                      data-test-id="switchTestId"
                      checked={notifications.applicationStage}
                      onChange={this.handleToggleNotifications("applicationStage")}
                      color="primary"
                    />
                  </Grid>
                  <Grid item className="gridBox">
                    <Typography variant="body1" className="normalTxt">
                      {configJSON.applicationBulk}
                    </Typography>
                    <IOSSwitch
                      checked={notifications.bulkUpdates}
                      onChange={this.handleToggleNotifications("bulkUpdates")}
                      color="primary"
                    />
                  </Grid>
                  <Grid item className="gridBox">
                    <Typography variant="body1" className="normalTxt">
                      {configJSON.conversationMsg}
                    </Typography>
                    <IOSSwitch
                      checked={notifications.conversationMessage}
                      onChange={this.handleToggleNotifications("conversationMessage")}
                      color="primary"
                    />
                  </Grid>
                  <Grid item className="gridBox">
                    <Typography variant="body1" className="normalTxt">
                      {configJSON.newApplicationRequest}
                    </Typography>
                    <IOSSwitch
                      checked={notifications.newApplicationRequirement}
                      onChange={this.handleToggleNotifications("newApplicationRequirement")}
                      color="primary"
                      data-test-id="newApplicationRequirementTstId"
                    />
                  </Grid>
                </Grid>
              </CardContent>
            </Card>
          </Grid>

          <Grid item xs={12} md={6}>
            {this.renderWhatsappBox()}
          </Grid>
        </Grid>
      </NotificationBox>
    )
  };

  renderWhatsappBox = () => {
    return (
      <WhatsAppBox>
        <Card>
          <CardContent className="insideBox">
            <img src={IMG_CONST.whatsAppIcon} alt="WhatsApp Icon" />
            <Typography className="comingSoonTxt">{configJSON.whatsappComingSoonTitle}</Typography>
            <Typography variant="body2" className="comingSoonDescTxt">
              {configJSON.whatsappComingSoonDesc}
            </Typography>
          </CardContent>
        </Card>
      </WhatsAppBox>
    );
  };

  renderBank = () => {
    const { allBankDetails } = this.state;
    const isFullyApproved = this.state.profileStatus === "fully_approved"

    return (
      <StyledBankCard>
        <Grid container spacing={2}>
          {allBankDetails.map((detail, index) => (
            <Grid item xs={12} sm={12} md={6} key={index}>
              <Card>
                <CardContent style={{ height: '100%' }}>
                  <Typography className="bankHeadingTxt">{detail.attributes.bank_name}</Typography>
                  <Box className="rowBox">
                    <Typography className="headingBoldTxt">{configJSON.accountHolderNameLabel}</Typography>
                    <Typography className="detailsTxt">{detail.attributes.bankholder_name}</Typography>
                  </Box>

                  <Box className="rowBox">
                    <Typography className="headingBoldTxt">{configJSON.accountNumberLabel}</Typography>
                    <Typography className="detailsTxt">{detail.attributes.account_number}</Typography>
                  </Box>

                  <Box className="rowBox">
                    <Typography className="headingBoldTxt">{configJSON.bankNameLabel}</Typography>
                    <Typography className="detailsTxt">{detail.attributes.bank_name}</Typography>
                  </Box>

                  <Box className="rowBox">
                    <Typography className="headingBoldTxt">{configJSON.bankAddressLabel}</Typography>
                    <Typography className="detailsTxt">{detail.attributes.bank_address}</Typography>
                  </Box>

                  <Box className="rowBox">
                    <Typography className="headingBoldTxt">{configJSON.banIfscLabel}</Typography>
                    <Typography className="detailsTxt">{detail.attributes.iban}</Typography>
                  </Box>

                  <Box className="rowBox">
                    <Typography className="headingBoldTxt">{configJSON.swiftCodeLabel}</Typography>
                    <Typography className="detailsTxt">{detail.attributes.swiftcode || 'N/A'}</Typography>
                  </Box>

                  <Box className="rowBox">
                    <Typography className="headingBoldTxt">{configJSON.currencyPreferenceLabel}</Typography>
                    <Typography className="detailsTxt">{detail.attributes.currency_preference}</Typography>
                  </Box>

                  <Box className="editDeleteBtnBox">
                    <Box style={{display:"flex", gap:"8px"}}>
                    <Button startIcon={<EditIcon />} data-test-id={`BrachEdit${index}`} className="editBtn" onClick={() => this.modelOpen(detail.attributes.id)} disabled={isFullyApproved}>{configJSON.editButtonText}</Button>
                      {allBankDetails.length>1&&!detail.attributes.primary && <Button startIcon={<DeleteIcon />} className="deleteBtn" data-test-id="onDeleteTestId" onClick={() => this.handleDeleteID(detail.attributes.id, detail.attributes.bank_name)} disabled={isFullyApproved}>{configJSON.deleteButtonText}</Button>}
                    </Box>
                    {detail.attributes.primary &&<Button className="primaryBankButton">Primary</Button>}
                      <Box>
                        <IOSSwitch
                          className="handleToggleNotificationsTstid"
                          checked={detail.attributes.primary}
                          onChange={(event) => this.updateBankPrimary(event, detail.attributes.id)}
                          color="primary"
                          data-test-id={`handlePrimaryBank${index}`}
                          disabled={detail.attributes.primary||isFullyApproved}
                        />
                      </Box>
                  </Box>
                 
                </CardContent>
              </Card>
            </Grid>
          ))}

          {allBankDetails.length <= 1 && (
            <Grid item xs={12} sm={12} md={6}>
              <Card>
                <CardContent className="cardContentBox">
                <Button disabled={isFullyApproved} className="addBankBtn" startIcon={<AddIcon />} onClick={() => this.modelOpen()}>
                    {configJSON.addNewBankAccountButtonText}
                  </Button>
                </CardContent>
              </Card>
            </Grid>
          )}
        </Grid>
      </StyledBankCard>
    );
  };

  renderBankModal = () => {
    const { isBankEdit, bankErrorResponse } = this.state;
    return (
      <MainModal
        disablePortal
        disableEnforceFocus
        disableAutoFocus
        open={this.state.isModal}
        className="modal"
      >
        <Paper className="paper">
        <Typography className="heading">{isBankEdit ? "Edit Bank Account" : configJSON.bankNameHeading}</Typography>

            <Box className="formFieldBox">
              <Typography className="fieldText">
                {configJSON.accountHolderName} <span className="starTxt">{configJSON.startTxt}</span>
              </Typography>
              <Box className="inputFieldBox">
                <TextField
                  type="text"
                  placeholder="Enter Account Holder Name"
                  className="inputField"
                  onChange={this.changeAccountName}
                  data-test-id="accountTestId"
                  value={this.state.accountNameValue}
                />
                {this.state.accountNameError && (
                  <RequiredTxt>{this.state.accountNameErrorTxt}</RequiredTxt>
                )}
              </Box>
            </Box>
            <Box className="formFieldBox">
              <Typography className="fieldText">
                {configJSON.accountName} <span className="starTxt">{configJSON.startTxt}</span>
              </Typography>
              <Box className="inputFieldBox">
                <TextField
                  type="text"
                  placeholder="Enter Account Number"
                  className="inputField"
                  onChange={this.changeAccountNumber}
                  data-test-id="accountNumber"
                  value={this.state.accountNumberValue}
                />
                {this.state.accountNumberError && (
                  <RequiredTxt>{this.state.accountNumberErrorTxt}</RequiredTxt>
                )}
              </Box>
            </Box>
            <Box className="formFieldBox">
              <Typography className="fieldText">
                {configJSON.bankNameTxt} <span className="starTxt">{configJSON.startTxt}</span>
              </Typography>
              <Box className="inputFieldBox">
                <TextField
                  type="text"
                  placeholder="Enter bank name"
                  className="inputField"
                  onChange={this.changeAccountBankName}
                  data-test-id="bankTestID"
                  value={this.state.accountBankNameValue}
                />
                {this.state.accountBankNameError && (
                  <RequiredTxt>{this.state.accountBankNameErrorTxt}</RequiredTxt>
                )}
              </Box>
            </Box>
            <Box className="formFieldBox">
              <Typography className="fieldText">
                {configJSON.bankAdrsTxt} <span className="starTxt">{configJSON.startTxt}</span>
              </Typography>
              <Box className="inputFieldBox">
                <TextField
                  type="text"
                  placeholder="Enter bank address"
                  className="inputField"
                  onChange={this.changeBankAdrs}
                  data-test-id="accountAdrsTestId"
                  value={this.state.bankAdrsValue}
                />
                {this.state.bankAdrsError && (
                  <RequiredTxt>{this.state.bankAdrsErrorTxt}</RequiredTxt>
                )}
              </Box>
            </Box>
            <Box className="formFieldBox">
              <Typography className="fieldText">
                {configJSON.ibanTxt} <span className="starTxt">{configJSON.startTxt}</span>
              </Typography>
              <Box className="inputFieldBox">
                <TextField
                  type="text"
                  placeholder="Enter IBAN/IFSC"
                  className="inputField"
                  onChange={this.changeIban}
                  data-test-id="ibanTestID"
                  value={this.state.ibanValue}
                />
                {this.state.ibanError && (
                  <RequiredTxt>{this.state.ibanErrorTxt}</RequiredTxt>
                )}
              </Box>
            </Box>
            <Box className="formFieldBox">
              <Typography className="fieldText">
                {configJSON.swiftCodeTxt}
              </Typography>
              <Box className="inputFieldBox">
                <TextField
                  type="text"
                  placeholder={configJSON.shiftCodePlaceHolder}
                  className="inputField"
                  onChange={this.changeShiftCode}
                  data-test-id="shiftTestID"
                  value={this.state.shiftValue}
                />
                {this.state.shiftError && (
                  <RequiredTxt>{this.state.shiftErrorTxt}</RequiredTxt>
                )}
              </Box>
            </Box>

            <Box className="formFieldBox">
              <Typography className="fieldText" data-test-id="currencyCode">
                {configJSON.currencyPreferenceLabel} <span className="starTxt">{configJSON.startTxt}</span>
              </Typography>
              <SelectInput
                displayEmpty
                MenuProps={{
                  getContentAnchorEl: null,
                  anchorOrigin: {
                    horizontal: "left",
                    vertical: "bottom"
                  },
                }}
                style={{
                  color: this.state.stateValue === "none" ? "#999" : "#000"
                }}
                value={this.state.selectedCurrency}
                IconComponent={KeyboardArrowDown}
                data-test-id="bankCurrencySelect"
                defaultValue="default"
                onChange={this.handleChangeCurrency}
                error={!!this.state.currencyError}
              >
                <MenuItem key={0} value={"none"}>{`Select Currency preference`}</MenuItem>
                {this.state.currencyCode?.map((item) => (
                  <MenuItem key={item.code} value={item.code}>{`${item.country} (${item.code})`}</MenuItem>
                ))}
              </SelectInput>
              {this.state.currencyError && (
                  <RequiredTxt>{this.state.currencyErrorTxt}</RequiredTxt>
                )}
            </Box>

            <Box className="formFieldBox">
                      <List style={{ padding: 0 }}>
            {bankErrorResponse && bankErrorResponse.map((errorObj: any, index) => (
              Object.keys(errorObj).map((key) => (
                <ListItem key={`${index}-${key}`} style={{ padding: '8px 0' }}>
                  <ListItemText
                    primary={`${key}: ${errorObj[key].join(', ')}`}
                    primaryTypographyProps={{ style: { color: 'red' } }}
                  />
                </ListItem>
              ))
            ))}
          </List>
            </Box>
            <Box className="buttonBox">
              <Button
                className="cancelBtn"
                onClick={() => this.modelOpen()}
                data-test-id="modelOpenTestID"
              >
                {configJSON.cancleBtnTxt}
              </Button>
              <Button
                type="submit"
                className="addBtn"
                data-test-id="submitBankInfo"
                onClick={isBankEdit ? this.updateBankInfo : this.saveBankInfo}
              >
                {isBankEdit ? "Update Bank Account" : configJSON.addBtnTxt}
              </Button>
            </Box>
        </Paper>
      </MainModal>
    );
  };
    
  renderReassignPopup = () => {
    const { reassignData: {students, agents}, studentData, associateValue } = this.state;
    return (
      <Modal open={this.state.openReassign}
        onClose={this.openReassignPopUp} data-test-id="filterModal">
        <ModalContent>
          <BoxPopupClose>
            <Close data-test-id="modalTestID" onClick={this.openReassignPopUp} />
          </BoxPopupClose>
          <ModaInnerBox >
            <Box style={{ width: "100%" }}>
              <BoxPopUpTitle className="filterTitle">
                <FilterModalTitle>Reassign application</FilterModalTitle>
                <FilterModalSubTitle>Choose member</FilterModalSubTitle>
              </BoxPopUpTitle>
            </Box>
            <Box className="insideBox">
              <Box className="innerContent">
                <Typography className="inputTxt">
                  Student
                </Typography>
                <Autocomplete
                  data-test-id="selectStudent"
                  className="selectInput autocompleteField"
                  multiple
                  options={students}
                  getOptionLabel={(option:StudentForReassign) => option.student_name}
                  value={studentData}
                  disableCloseOnSelect
                  onChange={this.handleSelectionChange}
                  renderOption={(props, option: StudentForReassign, { selected }) => {
                    const { key, ...otherProps } = props;
                    return (
                      <Box component="li" key={option.student_id} {...otherProps}>
                        <StyledCheckBox checked={selected} style={{ marginRight: 8 }} />
                        <ListItemText
                          primary={option.student_name}
                          secondary={
                            <>
                              <strong>Student ID:</strong> {option.student_id},  <strong>Passport No:</strong> {option.passport_number}
                            </>
                          }
                        />
                      </Box>
                    );
                  }}
                  renderInput={(params) => (
                    <TextField
                      {...params}
                      variant="standard"
                      placeholder="Select Students"
                      InputLabelProps={{ shrink: true,  ...params.InputProps }}
                    />
                  )}
                  renderTags={() => null}
                />
                <Box mt={2}>
                  {studentData.map((option, index) => (
                    <StyledChip
                      data-test-id="selectedStudent"
                      key={option.student_id}
                      label={option.student_name}
                      onDelete={() => this.handleRemoveItem(option.student_id)}
                      deleteIcon={<SvgIcon component={CancelOutlinedIcon} style={{ color: 'red' }} />}
                    />
                  ))}
                </Box>
                {this.state.reassignStudentError && (
                  <RequiredTxt>{this.state.reassignStudentError}</RequiredTxt>
                )}
              </Box>

              <Box className="innerContent">
                <Typography className="inputTxt">
                  Assign To
                </Typography>
                <Select
                  className="selectInput"
                  displayEmpty
                  MenuProps={{
                    anchorOrigin: {
                      vertical: "bottom",
                      horizontal: "left"
                    },
                    getContentAnchorEl: null,
                  }}
                  style={{
                    color: associateValue == "none" ? "#999" : "#000"
                  }}
                  value={associateValue}
                  data-test-id="selectBranch"
                  IconComponent={KeyboardArrowDown}
                  onChange={this.handleBranchChange}
                >
                  <MenuItem value="none">
                    Select Agent/Counsellor 
                  </MenuItem>
                  {agents.map((item) => (
                    <MenuItem key={item.id} value={item.id}>
                      <MenuListItems>
                        <Box className="cardLogoImg">
                          <img
                            width="100%"
                            height="100%"
                            src={item.profile_image?.url}
                            alt={item.agent_name}
                            className="cardLogoImg"
                          />
                        </Box>
                        <Box>
                          <Box className="listContentBox">
                            <Typography className="listContentTitle">ID:</Typography>
                            <Typography className="listContentValue">{item.agent_id}</Typography>
                          </Box>
                          <Box className="listContentBox">
                            <Typography className="listContentTitle">Name:</Typography>
                            <Typography className="listContentValue">{item.agent_name}</Typography>
                          </Box>
                          <Box className="listContentBox">
                            <Typography className="listContentTitle">Designation:</Typography>
                            <Typography className="listContentValue">{item.designation}</Typography>
                          </Box>
                          <Box className="listContentBox">
                            <Typography className="listContentTitle">Email:</Typography>
                            <Typography className="listContentValue">{item.email}</Typography>
                          </Box>
                        </Box>
                      </MenuListItems>
                    </MenuItem>
                  ))}
                </Select>
                {this.state.reassignAgentError && (
                  <RequiredTxt>{this.state.reassignAgentError}</RequiredTxt>
                )}
              </Box>
            </Box>
            <StyledBoxButtonWrapper>
              <StyledApplyFilterButton onClick={this.reassignStudent} data-test-id="applyFilter"><StyledTypoGraphyApply>Reassign application to chosen member</StyledTypoGraphyApply></StyledApplyFilterButton>
            </StyledBoxButtonWrapper>
          </ModaInnerBox>
        </ModalContent>
      </Modal>
    )
  };

  renderManageStaff = () => {
    const { expandedRowId } = this.state;
    return (
      <TablePaper>
        <Table className="table" aria-label="simple table">
          <TableHead>
            <TableRow>
              <TableCell>{configJSON.staffSerialNo}</TableCell>
              <TableCell>{configJSON.staffFirstName}</TableCell>
              <TableCell>{configJSON.staffLastName}</TableCell>
              <TableCell>{configJSON.staffEmail}</TableCell>
              <TableCell>{configJSON.staffDesignation}</TableCell>
              <TableCell>{configJSON.staffRole}</TableCell>
              <TableCell>{configJSON.staffMobile}</TableCell>
              <TableCell>
              {configJSON.staffStatus} <ExpandMoreIcon className="expandMore"/>
              </TableCell>
              <TableCell>{configJSON.staffActions}</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {Array.isArray(this.state.staffRowData) && this.state.staffRowData.length > 0 ? this.state.staffRowData.map((row, index) => (
              <React.Fragment key={row.id}>
                <TableRow>
                  <TableCell component="th" scope="row">
                    {index + 1}
                  </TableCell>
                  <TableCell data-test-id={`manageStaffRowName${index}`}>{row.attributes.first_name}</TableCell>
                  <TableCell>{row.attributes.last_name}</TableCell>
                  <TableCell>{row.attributes.email}</TableCell>
                  <TableCell>{row.attributes.designation}</TableCell>
                  <TableCell>{row.attributes.role}</TableCell>
                  <TableCell>
                    + {row.attributes.country_code} -{" "}
                    {row.attributes.phone_number}
                  </TableCell>
                  <TableCell align="right">
                    <span
                      className={
                        row.attributes.status === "active"
                          ? "statusActive"
                          : "statusInactive"
                      }
                    >
                      {row.attributes.status}
                    </span>
                  </TableCell>
                  <TableCell>
                     <Box className="tcAssigned">
                      <Button onClick={()=>{
                        this.EditStaffModelOpen(row)
                      }} variant="outlined" size="small" data-test-id={'EditStaff'+index} className="borderedSpan">
                        <EditIcon className="editIconTable" />{" "}
                      </Button>
                      <Button onClick={() => this.handleRowClick(row.id)} data-test-id={`manageStaffRow${index}`} variant="outlined" size="small" className="borderedSpan">
                        <ExpandMoreIcon className="expandMore" />
                      </Button>
                    </Box>
                  </TableCell>
                </TableRow>

                {expandedRowId === row.id && (
                  <TableRow>

                    <TableCell colSpan={12} className="tableCellExpanded">
                      <ExpandedBox>
                        <UpperTable container spacing={2}>
                          <Grid item xs={12} md={6} lg={6}>
                            <Box className="cards">
                              <Box className="cardImageContainer">
                                <img src={IMG_CONST.TotalApplicationIcon} width="100%" height="100%" />
                              </Box>
                              <Box className="cardContentContainer">
                                <Typography style={webStyles.cardTitle}>
                                  Total Applications
                                </Typography>
                                <Typography style={webStyles.cardContent}>
                                {row.attributes.application_count}
                                </Typography>
                              </Box>
                            </Box>
                          </Grid>
                          <Grid item xs={12} md={6} lg={6}>
                            <Box className="cards">
                              <Box className="cardImageContainer">
                                <img src={IMG_CONST.enrolledImg} width="100%" height="100%" />
                              </Box>
                              <Box className="cardContentContainer">
                                <Typography style={webStyles.cardTitle}>
                                  Total students
                                </Typography>
                                <Typography style={webStyles.cardContent}>
                                {row.attributes.student_count}
                                </Typography>
                              </Box>
                            </Box>
                          </Grid>
                        </UpperTable>
                      </ExpandedBox>
                      <ReassignActions>
                        <Box className="reassignLeft">
                          <Button disabled={row.attributes.student_count === 0 && row.attributes.application_count === 0} onClick={() => this.getAllStudentListForReassign(row.id)} data-test-id={`openReassignPopUp${index}`} className="reassignToAllBtn">
                            {configJSON.reassignAllToTxt}
                          </Button>
                        </Box>
                        <Box className="reassignRight">
                          <span>
                            <ErrorOutlineIcon className="iconWarning" />
                          </span>
                          <span className="reassignWarnText">
                           {configJSON.reassignWarnTxt}
                          </span>
                          {row.attributes.status === "active" && (<Button data-test-id={`deactivate${index}`} onClick={() => this.ActiveDeactive(row.id)} disabled={row.attributes.student_count > 0 && row.attributes.application_count > 0} className="deactivateBtn">{configJSON.deactivateBtntxt}</Button>)}
                          {row.attributes.status === "inactive" && (<Button onClick={() => this.ActiveDeactive(row.id)} data-test-id={`activate`} className="reassignToAllBtn">
                            Activate
                          </Button>)}
                        </Box>
                      </ReassignActions>
                    </TableCell>
                  </TableRow>
                )}
              </React.Fragment>
            )) :
            <TableRow>
              <TableCell>
                <Typography align="center">
                  No Data
                </Typography>
              </TableCell>
            </TableRow>
            }
          </TableBody>
        </Table>
        <Box style={{padding:"8px 8px"}}>
          <PaginationCompoent data-test-id="PagiNationCompoentId" sendData={this.handelPropsChange} data={this.state.FilterStaffRowData} itemsPerPage={this.state.itemsPerPage} navigation={undefined} />
        </Box>
        {this.renderMangeStafModel()}
      </TablePaper>
    );
  };

  StaffMobileTextFieldRender = (
    setFieldValue: (field: string, value: string, shouldValidate?: boolean | undefined) => void,
    values: { mobile: string }, errors: FormikErrors<{
      name: string;
      lastName: string;
      email: string;
      mobile: string;
      role: string;
      designation: string;
    }>, touched: FormikTouched<{
      name: string;
      lastName: string;
      email: string;
      mobile: string;
      role: string;
      designation: string;
    }>
  ) => {
    const { branchCountryCode, countryCodes } = this.state
    return (
      <Grid item xs={12}>
        <Box className="formFieldBox" data-test-id="branchMobileFieldBoxTestID">
          <BranchTextLabelsTypography data-test-id="branchMobileLabelTestID">
            {configJSON.branchMobile} <span className="starTxt">{configJSON.startTxt}</span>
          </BranchTextLabelsTypography>
          <MobileNumberWrapper className="MangeStaffMobileContainer" data-test-id="mobileNumberWrapperTestID">
            <Box data-test-id="branchCountryCodeBoxTestID">
              <SelectInput
                displayEmpty
                MenuProps={{
                  getContentAnchorEl: null,
                  anchorOrigin: {
                    horizontal: "left",
                    vertical: "bottom"
                  },
                }}
                style={{
                  color: this.state.stateValue === "none" ? "#999" : "#000"
                }}
                value={branchCountryCode}
                IconComponent={KeyboardArrowDown}
                data-test-id="MangeStaffCountryCodeTestId"
                defaultValue="default"
                onChange={this.changeBranchCountryCode}
              >
                {countryCodes.map((item) => (
                  <MenuItem key={`${item.country_code}-${item.alpha2}`} value={`${item.country_code}-${item.alpha2}`}>
                    {`+${item.country_code} (${item.alpha2})`}
                  </MenuItem>
                ))}
              </SelectInput>
            </Box>
            <Box className="inputFieldBox" data-test-id="branchContactNumberBoxTestID">
              <TextField
                type="tel"
                placeholder={'Enter Mobile Number'}
                className="inputField"
                fullWidth
                onChange={(e) => {
                  this.onChangeOfMangeStaff(e, 'mobile', setFieldValue)
                }}
                data-test-id="MangeStaffPhoneNumberTestId"
                name="mobile"
                value={values.mobile}
                error={!!errors.mobile}
                onBlur={this.validateMobileNo}
              />
            </Box>
          </MobileNumberWrapper>
          {!!this.state.branchMobileNumberError && (
            <RequiredTxt>{this.state.branchMobileNumberError}</RequiredTxt>
          )}
          {!this.state.branchMobileNumberError && values.mobile === '' && this.errorCounter(errors.mobile, touched.mobile)}
        </Box>
      </Grid>
    )
  }

  renderMangeStafModel = () => {
    const { isMangeStaffEdit } = this.state;
    return (
      <MainModal
        onClose={() => this.OpenCloseMangeStaff()}
        disablePortal
        disableEnforceFocus
        disableAutoFocus
        open={this.state.MangeStaffModel}
        className="modal"
        data-test-id="MangeStaffModel"
      >
        <UpdatedMainModelStyle className="MangeStaff-paper" data-test-id="ManeStaffpaperTestID">
          <Box style={{ width: "100%" }}>
            <Box className="HeadingContainer">
              <Box>
                <Typography className="NewHeading" data-test-id="MangeStaffheadingTestID">
                  {isMangeStaffEdit ? "Edit member's info" : `Add New Staff`}
                </Typography>
                {
                  !isMangeStaffEdit &&
                  <Typography
                    data-test-id="MangeStaffheadingTestID"
                  >
                    Enter a new member's information
                  </Typography>
                }
              </Box>
              <BoxPopupClose data-test-id="MangeStaffPopupCloseTestID">
                <CloseIcon
                  data-test-id="MangeStaffcloseIconTestID"
                  onClick={() => this.OpenCloseMangeStaff()}
                />
              </BoxPopupClose>
            </Box>
            <Box style={{ width: "100%" }}>
              <Formik
                initialValues={{
                  name: this.state.MangeStaffFormData.name || '',
                  lastName: this.state.MangeStaffFormData.lastName || '',
                  email: this.state.MangeStaffFormData.email || '',
                  mobile: this.state.MangeStaffFormData.mobile || '',
                  role: this.state.MangeStaffFormData.role || "",
                  designation: this.state.MangeStaffFormData.designation || ''
                }}
                validateOnChange={true}
                validationSchema={this.AddNewStaffSchema}
                validateOnBlur={true}
                onSubmit={(values) => {
                  if(!this.state.branchMobileNumberError){
                    this.CreateMangeStaff(values)
                  }
                }}
              >{({ handleSubmit, setFieldValue, handleBlur, errors, values, touched }) => {
                return (
                  <form data-test-id="MangeStaffSubmitTestID" onSubmit={handleSubmit} autoComplete="off">
                    <Box style={{ width: "100%" }}>
                      <Grid container spacing={2}>
                        <Grid item xs={12} md={6}>
                          <Box data-test-id="MangeStaffFieldBoxTestID">
                            <BranchTextLabelsTypography data-test-id="MangeStaffLabelTestID">
                            First name<span className="starTxt">{configJSON.startTxt}</span>
                            </BranchTextLabelsTypography>
                            <Box className="inputFieldBox" data-test-id="MangeStaffInputBoxTestID">
                              <TextField
                                type="text"
                                placeholder={"Enter Name"}
                                className="inputField"
                                onChange={(e) => {
                                  this.onChangeOfMangeStaff(e, 'name', setFieldValue)
                                }}
                                name="name"
                                value={values.name}
                                onBlur={handleBlur}
                                data-test-id="MangeStaffNameTestIdName"
                                fullWidth
                                error={!!errors.name}
                                inputProps={{
                                  maxLength: 30,
                                }}
                              />
                              {this.errorCounter(errors.name ,touched.name)}
                            </Box>
                          </Box>
                        </Grid>
                        <Grid item xs={12} md={6}>
                          <Box data-test-id="MangeStaffFieldBoxTestID">
                            <BranchTextLabelsTypography data-test-id="MangeStaffLabelTestID">
                              Last name<span className="starTxt">{configJSON.startTxt}</span>
                            </BranchTextLabelsTypography>
                            <Box className="inputFieldBox" data-test-id="MangeStaffInputBoxTestID">
                              <TextField
                                type="text"
                                placeholder={'Enter Last Name'}
                                className="inputField"
                                onChange={(e) => {
                                  this.onChangeOfMangeStaff(e, 'lastName', setFieldValue)
                                }}
                                value={values.lastName}
                                data-test-id="MangeStaffLastNameTestId"
                                name="lastName"
                                onBlur={handleBlur}
                                error={!!errors.lastName}
                                inputProps={{
                                  maxLength: 30,
                                }}
                              />
                              {this.errorCounter(errors.lastName ,touched.lastName)}
                            </Box>
                          </Box>
                        </Grid>
                        <Grid item xs={12}>
                          <Box data-test-id="MangeStaffFieldBoxTestID">
                            <BranchTextLabelsTypography data-test-id="MangeStaffLabelTestID">
                              E-mail<span className="starTxt">{configJSON.startTxt}</span>
                            </BranchTextLabelsTypography>
                            <Box className="inputFieldBox" data-test-id="MangeStaffInputBoxTestID">
                              <TextField
                                type="text"
                                placeholder={"Enter Email ID"}
                                className="inputField"
                                data-test-id="MangeStaffEmailTestId"
                                onChange={(e) => {
                                  this.onChangeOfMangeStaff(e, 'email', setFieldValue)
                                }}
                                value={values.email}
                                fullWidth
                                name="email"
                                onBlur={handleBlur}
                                error={!!errors.email}
                                inputProps={{
                                  maxLength: 30,
                                }}
                              />
                              {this.errorCounter(errors.email, touched.email)}
                            </Box>
                          </Box>
                        </Grid>
                        {this.StaffMobileTextFieldRender(setFieldValue, values, errors, touched)}
                        <Grid item xs={12}>
                          <Box className="formFieldBox" data-test-id="branchMobileFieldBoxTestID">
                            <BranchTextLabelsTypography data-test-id="branchMobileLabelTestID">
                              Role <span className="starTxt">{configJSON.startTxt}</span>
                            </BranchTextLabelsTypography>
                            <RadioGroup
                              row
                              aria-labelledby="demo-row-radio-buttons-group-label"
                              name="row-radio-buttons-group"
                              value={values.role}
                              data-test-id="RadioGroupTest"
                            >
                              <FormControlLabel
                                data-test-id="RoleTestId"
                                name="counsellor"
                                value="counsellor"
                                control={<RadioButton />}
                                label="Counseller"
                                onChange={() => {
                                  this.OnChangeMangeStaffRadioButton("counsellor", 'role', setFieldValue)
                                }}
                              />
                              <FormControlLabel
                                name="Sub admin"
                                value="sub_admin"
                                control={<RadioButton />}
                                label="Sub admin"
                                onChange={() => {
                                  this.OnChangeMangeStaffRadioButton("sub_admin", 'role', setFieldValue)
                                }}
                              />
                            </RadioGroup>
                            {this.errorCounter(errors.role, touched.role)}
                          </Box>
                        </Grid>
                        <Grid item xs={12}>
                          <Box data-test-id="MangeStaffFieldBoxTestID">
                            <BranchTextLabelsTypography data-test-id="MangeStaffLabelTestID">
                              Designation <span className="starTxt">{configJSON.startTxt}</span>
                            </BranchTextLabelsTypography>
                            <Box className="inputFieldBox" data-test-id="MangeStaffInputBoxTestID">
                              <TextField
                                type="text"
                                placeholder={'Enter designation'}
                                className="inputField"
                                onChange={(e) => { this.onChangeOfMangeStaff(e, 'designation', setFieldValue)}}
                                data-test-id="MangeStaffDesignationTestId"
                                fullWidth
                                name="designation"
                                onBlur={handleBlur}
                                value={values.designation}
                                error={!!errors.designation}
                                inputProps={{ maxLength: 30 }}
                              />
                              {this.errorCounter(errors.designation, touched.designation)}
                            </Box>
                          </Box>
                        </Grid>
                        <Grid item xs={12}>
                          <Box className="buttonBoxBranch" data-test-id="buttonBoxBranchTestID">
                            <Button
                              className="addBtn"
                              data-test-id="MangeStaffSubmitButton"
                              type="submit"
                            >
                              {isMangeStaffEdit ? "Save changes" : `Add new member`}
                            </Button>
                          </Box>
                        </Grid>
                      </Grid>
                    </Box>
                  </form>
                )
              }}
              </Formik>
            </Box>
          </Box>
        </UpdatedMainModelStyle>
      </MainModal>
    )
  }
  
  rednerTab = () => {
    return (
      <MainTabBox>
        <div >
          <AppBar position="static">
            <Tabs
              value={this.state.value}
              onChange={this.handleChange}
              variant="scrollable"
              scrollButtons="auto"
              aria-label="scrollable auto tabs example"
              data-test-id="tabChangeTestId"
            >
              <Tab className="tabLabel" label={configJSON.bankInformationTxt} value={0} data-test-id="bankInfoTestId" />
              <Tab className="tabLabel" label={configJSON.documentsnTxt} value={1} />
              <Tab className="tabLabel" label={configJSON.agreementTxt} value={2} />
              <Tab className="tabLabel" label={configJSON.staffTxt} value={3} data-test-id="manageStaffTab"/>
              <Tab className="tabLabel" label={configJSON.manageBranch} value={4} />
              {this.state.value === 4 && (<AddBranchButton data-test-id="addBranchButton" onClick={() => this.openBranchModal()}><SvgIcon className="SvgIconAddBranch" component={AddIcon} /><AddBranchTypography>{configJSON.addBranchBtn}</AddBranchTypography></AddBranchButton>)}
              {this.state.value === 3 && (<AddBranchButton onClick={this.OpenCloseMangeStaff} data-test-id="addBranchButton"><SvgIcon className="SvgIconAddBranch" component={AddIcon} /><AddBranchTypography>{configJSON.addStaffBtnText}</AddBranchTypography></AddBranchButton>)}
            </Tabs >
          </AppBar >
          {this.tabPanel(this.renderBank(), this.state.value, 0)}
          {this.tabPanel(this.renderDocument(), this.state.value, 1)}
          {this.tabPanel(this.renderAgreement("initial"), this.state.value, 2)}
          {this.tabPanel(this.renderAgreement("success"), this.state.value, 2)}
          {this.tabPanel(this.renderAgreement("failure"), this.state.value, 2)}
          {this.tabPanel(this.renderManageStaff(), this.state.value, 3)}
          {this.tabPanel(this.renderBranch(), this.state.value, 4)}
        </div >
      </MainTabBox >
    );
  };

  tabPanel(children: {}, value: number, index: number) {
    return (
      <div
        role="tabpanel"
        hidden={value !== index}
        id={`simple-tabpanel-${index}`}
        aria-labelledby={`simple-tab-${index}`}
      >
        {value === index && (
          <TabBox>
            <Typography>{children}</Typography>
          </TabBox>
        )}
      </div>
    );
  };

  renderDelete = () => {
    return (
      <DeleteModalBox open={this.state.isDelete} onClose={this.handleDelete}>
        <Card className="cardBox">
          <CardContent className="cardContent">
            <Box className="box">
            <CloseIcon className="closeIcon" onClick={this.handleDelete} />
            </Box>
            <Typography className="deleteTxtBtn marginTop30">
              {configJSON.deleteBankAccountTitle}
            </Typography>
            <Typography className="confirmationTxt">
            {configJSON.deleteBankAccountConfirmation} <span className="deleteTxtBtnBranch">{this.state.bankEditName}</span> account?
            </Typography>
            <Box className="deleteBtnBottomBox">

            <Button className="deleteModalInsideBtn" onClick={this.deleteBankInfo}>
                {configJSON.deleteButtonText}
              </Button>

              <Button className="confirmModalInsideBtn" onClick={this.handleDelete}>
                {configJSON.goBackBtnText}
              </Button>
            </Box>
          </CardContent>
        </Card>
      </DeleteModalBox>
    );
  };
  

  renderStatusContent = (status:string,id:string,agentData:any,isDocumentViewed:any) => {
    const isFullyApproved = this.state.profileStatus === "fully_approved";
    const { attributes } = agentData || {};
    const digitalSignatureURL = attributes?.digital_signature_url;
    const fileName = digitalSignatureURL?.split("/").pop();
    const shortFileName = fileName?.slice(0, 8);
    const fileSize = attributes?.file_size;
    const isFixRequired = attributes?.status === "fix_required";
    const isDone = attributes?.status === "done";
    
    const renderFile = () => (
      <FileUploadedError className={isFixRequired ? "error" : ""}>
        {this.getIconForFileType(digitalSignatureURL)}
        <Box>
          <FileNameTitleTypography noWrap style={{ maxWidth: "100px", overflow: "hidden", textOverflow: "ellipsis" }}>
            {isFixRequired ? shortFileName : fileName}
          </FileNameTitleTypography>
          <FilesizeTypography>{fileSize}</FilesizeTypography>
        </Box>
        <ActionButtonsWrapper>
          <a href={digitalSignatureURL} target="_blank" rel="noopener noreferrer" download>
            <DownloadIconButton>
              <SvgIcon component={FileDownloadIcon} style={{ color: '#64748B' }} />
            </DownloadIconButton>
          </a>
          {!isDone && (
            <DeleteIconButton
              disabled={isFullyApproved}
              onClick={(event: React.MouseEvent<HTMLButtonElement>) => this.handleDeleteDocAgree(event, attributes.id)}
              data-test-id={"isDoneBtn"}
            >
              <SvgIcon component={DeleteOutlineIcon} style={{ color: '#DC2626' }} />
            </DeleteIconButton>
          )}
        </ActionButtonsWrapper>
        {isFixRequired && (
          <FileUploadErrorText dangerouslySetInnerHTML={{ __html: attributes.comment }} />
        )}
      </FileUploadedError>
    );
    
    const renderUploadButton = () => (
      <>
        <DownloadDocumentText>{configJSON.uploadFile}</DownloadDocumentText>
        <AddDocumentBox className="agreement-upload" style={{ margin: "0 auto", height: "41px", border: "none" }}>
          <input
            accept=".png, .jpg, .pdf, .doc, .docx"
            style={{ display: "none" }}
            disabled={!isDocumentViewed}
            id={`input-agree-${id}`}
            type="file"
            data-test-id="fileInputTestIDnew"
            onChange={(event) => this.handleFileChangeDocAgreement(event, id)}
          />
          <label htmlFor={`input-agree-${id}`}>
            <UploadDocumentButton
              data-test-id="addDocumentTestId"
              style={{
                cursor: isDocumentViewed ? 'pointer' : 'not-allowed',
                opacity: isDocumentViewed ? 1 : 0.5,
                backgroundColor: isDocumentViewed ? 'white' : '#e0e0e0',
              }}
              onClick={() => document.getElementById(`input-agree-${id}`)?.click()}
              disabled={isFullyApproved}
            >
              <SvgIcon component={AddIcon} style={{ color: "#34D399" }} />
              <DocUpLoadText>{configJSON.documentAddText}</DocUpLoadText>
            </UploadDocumentButton>
          </label>
        </AddDocumentBox>
      </>
    );
    
    return (
      <DownloadAgreementButton>
        {digitalSignatureURL ? renderFile() : renderUploadButton()}
      </DownloadAgreementButton>
    );
    
  };

  renderDoneComponent = (status:string,type:string) => {
    console.log(type,'typee')
    switch (type) {
      case 'pending':
        return (
          <>
            <SvgIcon component={CheckCircleIcon} style={{ color: "#94A3B8B2" }} />
            <DoneTypography>{configJSON.pendingText}</DoneTypography>
          </>
        );
      case 'done':
        return (
          <>
            <SvgIcon component={CheckCircleIcon} style={{ color: "#34D399" }} />
            <DoneTypography>{configJSON.doneText}</DoneTypography>
          </>
        );
      case 'fix_required':
        return <FixedRequiredText>{configJSON.failedText}</FixedRequiredText>
    }
  };
  

  renderAgreement = (currentStatus: string) => {
    if (currentStatus === 'initial') {
      return (
        this?.state?.allAgreementsData?.map((doc: any) => {
          const agentId = this?.state?.allAgreementsData[0].attributes?.agent_id || '';
          const isDocumentViewed = agentId ? 
      (this.state.viewedDocuments[agentId] || []).includes(doc.id) : false;

    const agentAgreementStatus = doc?.attributes?.agent_agreement?.attributes?.status;

          let FirstHalfComponent;
        if (isDocumentViewed) {
          FirstHalfComponent = <BackgroundLineSuccess2 />; // Green line if viewed
        } else {
          FirstHalfComponent = <BackgroundLine />; // Default line if not viewed
        }
  
          let SecondHalfComponent;
        if (!doc?.attributes?.agent_agreement?.attributes?.digital_signature_url) {
          SecondHalfComponent = <BackgroundLine />; // No file uploaded (gray line)
        } else {
          switch (agentAgreementStatus) {
            case 'pending':
              SecondHalfComponent = <BackgroundLinePending />; // Yellow line for pending
              break;
            case 'fix_required':
              SecondHalfComponent = <BackgroundLineFailure />; // Red line for rejected
              break;
            case 'done':
              SecondHalfComponent = <BackgroundLineSuccess />; // Green line for success
              break;
            default:
              SecondHalfComponent = <BackgroundLine />; // Default yellow line for pending
          }
        }

        return (
          <TermsAndServiceWrapper key={doc.id}>
            <SectionTitle>{doc.attributes.title}</SectionTitle>
            <InstructionTitle>{configJSON.acceptedFileFormats}</InstructionTitle>
            <AgreementContainer>
              <DownloadAgreementButton>
                <DownloadDocumentText data-test-id="test-id">{configJSON.downloadText}</DownloadDocumentText>
                <DownloadFileButton>
                  <a
                    href={doc.attributes?.file_attachment[0]?.url}
                    onClick={() => this.handleDocumentViewed(doc.id, agentId)}
                    style={{"textDecoration":"none","display": "flex"}}
                    target="_blank"
                    data-test-id="viewDocId"
                    rel="noopener noreferrer"
                    key={doc.id}
                  >
                    <DownLoadText>{configJSON.downloadBtnText}</DownLoadText>
                    <SvgIcon component={FileDownloadIcon} style={{ color: '#64748B' }} />
                  </a>
                </DownloadFileButton>
              </DownloadAgreementButton>
              <ReadItThrough>
                <ReadWrapper>
                  <SvgIcon component={VisibilityOutlinedIcon} style={{ color: '#64748B' }} />
                  <ReadItTypography>{configJSON.readItThrough}</ReadItTypography>
                </ReadWrapper>
              </ReadItThrough>
              {this.renderStatusContent(currentStatus, doc.attributes?.id, doc?.attributes?.agent_agreement,isDocumentViewed)}
              <ReadItThrough>
                <DoneWrapper>
                  {this.renderDoneComponent(currentStatus,agentAgreementStatus)}
                </DoneWrapper>
              </ReadItThrough>
              <div style={{display:'flex'}}>
                <div>{SecondHalfComponent}</div>
              </div>
              <div>{FirstHalfComponent}</div>
            </AgreementContainer>
            </TermsAndServiceWrapper>
        );
      })
    );
  }

  // If status is not 'initial', return null or handle based on actual need
  return null;
};



  renderBranch = () => {
    return (
      <Grid container spacing={2}>
        {this.state.allBranchData?.map((branch) => (
          <Grid item xs={12} sm={6} md={4}  key={branch.id}>
             <BranchCard>
            <BranchNameAndDateWrapper>
              <BranchName>{branch.attributes.branch_name}</BranchName>
              <BranchCreatedDate>
                Created on {branch.attributes.date} - {branch.attributes.time}
              </BranchCreatedDate>
            </BranchNameAndDateWrapper>
            <BranchDetailsWrapper>
              <DetailRow>
                <DetailLabel>Email</DetailLabel>
                <DetailValue>{branch.attributes.email}</DetailValue>
              </DetailRow>
              <DetailRow>
                <DetailLabel>Phone number</DetailLabel>
                <DetailValue>
                  +{branch.attributes.country} {branch.attributes.phone_number}
                </DetailValue>
              </DetailRow>
              <DetailRow>
                <DetailLabel>Managed by</DetailLabel>
                <DetailValue>{branch.attributes.managed_by || 'N/A'}</DetailValue>
              </DetailRow>
            </BranchDetailsWrapper>
            <ActionButtonWrapper>
              <EditBranchButton onClick={() => this.openBranchModal(branch.attributes.id)}>
                <SvgIcon
                  component={CreateIcon}
                  style={{ color: '#34D399', width: "20px", height: "20px" }}
                />
                <EditBranchTypography>Edit</EditBranchTypography>
              </EditBranchButton>
              <DeleteBranchButton onClick={() => this.handleDeleteBranchID(branch.attributes.id, branch.attributes.branch_name)}>
                <SvgIcon
                  component={DeleteOutlineIcon}
                  style={{ color: '#DC2626', width: "20px", height: "20px" }}
                />
                <DeleteBranchTypography>Delete</DeleteBranchTypography>
              </DeleteBranchButton>
            </ActionButtonWrapper>
          </BranchCard>
          </Grid>
        ))}
      </Grid>
    )
  }

  renderBranchModal = () => {
    const { branchName, branchAgent, branchEmailID, branchCountryCode, branchMobileNumber, countryCodes, isBranchEdit } = this.state;
    return (
      <MainModal
        disablePortal
        disableEnforceFocus
        disableAutoFocus
        open={this.state.branchModal}
        onClose={() => this.openBranchModal()}
        className="modal"
        data-test-id="mainModalTestID"
      >
        <Paper className="paper" data-test-id="paperTestID">
          <BoxPopupClose data-test-id="boxPopupCloseTestID">
            <CloseIcon data-test-id="closeIconTestID" onClick={() => this.openBranchModal()} />
          </BoxPopupClose>
          <Typography className="heading" data-test-id="headingTestID">
            {isBranchEdit ? "Edit Branch" : `${configJSON.addBranchPopupTitle}`}
          </Typography>
          <form data-test-id="branchSubmitTestID" onSubmit={isBranchEdit ? this.handleSubmitBranchEdit : this.handleSubmitBranch}>
            <Box className="formFieldBox" data-test-id="branchNameFieldBoxTestID">
              <BranchTextLabelsTypography data-test-id="branchNameLabelTestID">
                {configJSON.branchName}<span className="starTxt">{configJSON.startTxt}</span>
              </BranchTextLabelsTypography>
              <Box className="inputFieldBox" data-test-id="branchNameInputBoxTestID">
                <TextField
                  type="text"
                  placeholder={configJSON.branchNamePlaceholder}
                  className="inputField"
                  onChange={this.changeBranchName}
                  data-test-id="branchNameTestId"
                  value={branchName}
                  error={!!this.state.branchNameError}
                  inputProps={{
                    maxLength: 30,
                  }}
                />
                {!!this.state.branchNameError && (
                  <RequiredTxt>{this.state.branchNameError}</RequiredTxt>
                )}
              </Box>
            </Box>

            <Box className="formFieldBox" data-test-id="branchEmailFieldBoxTestID">
              <BranchTextLabelsTypography data-test-id="branchEmailLabelTestID">
                {configJSON.branchEmail} <span className="starTxt">{configJSON.startTxt}</span>
              </BranchTextLabelsTypography>
              <Box className="inputFieldBox" data-test-id="branchEmailInputBoxTestID">
                <TextField
                  type="text"
                  placeholder={configJSON.branchEmailPlaceholder}
                  className="inputField"
                  onChange={this.changeBranchEmail}
                  data-test-id="branchEmailID"
                  value={branchEmailID}
                  error={!!this.state.branchEmailError}
                  inputProps={{
                    maxLength: 30,
                  }}
                />
                {!!this.state.branchEmailError && (
                  <RequiredTxt>{this.state.branchEmailError}</RequiredTxt>
                )}
              </Box>
            </Box>

            <Box className="formFieldBox" data-test-id="branchMobileFieldBoxTestID">
              <BranchTextLabelsTypography data-test-id="branchMobileLabelTestID">
                {configJSON.branchMobile} <span className="starTxt">{configJSON.startTxt}</span>
              </BranchTextLabelsTypography>
              <MobileNumberWrapper data-test-id="mobileNumberWrapperTestID">
                <Box data-test-id="branchCountryCodeBoxTestID">
                  <SelectInput
                    displayEmpty
                    MenuProps={{
                      getContentAnchorEl: null,
                      anchorOrigin: {
                        horizontal: "left",
                        vertical: "bottom"
                      },
                    }}
                    style={{
                      color: this.state.stateValue === "none" ? "#999" : "#000"
                    }}
                    value={branchCountryCode}
                    IconComponent={KeyboardArrowDown}
                    data-test-id="branchCountryCodeTestId"
                    defaultValue="default"
                    onChange={this.changeBranchCountryCode}
                  >
                    {countryCodes.map((item) => (
                      <MenuItem key={`${item.country_code}-${item.alpha2}`} value={`${item.country_code}-${item.alpha2}`}>
                        {`+${item.country_code} (${item.alpha2})`}
                      </MenuItem>
                    ))}
                  </SelectInput>
                </Box>
                <Box className="inputFieldBox" data-test-id="branchContactNumberBoxTestID">
                  <TextField
                    name="number"
                    type="tel"
                    placeholder={configJSON.branchMobile}
                    className="inputField"
                    onChange={this.changeBranchMobile}
                    data-test-id="branchContactNumberTestId"
                    value={branchMobileNumber}
                    error={!!this.state.branchMobileNumberError}
                    onBlur={this.validateMobileNo}
                  />
                  {!!this.state.branchMobileNumberError && (
                    <RequiredTxt>{this.state.branchMobileNumberError}</RequiredTxt>
                  )}
                </Box>
              </MobileNumberWrapper>
            </Box>

            <Box className="formFieldBox" data-test-id="manageMemberFieldBoxTestID">
              <BranchTextLabelsTypography data-test-id="manageMemberLabelTestID">
                {configJSON.manageMember} <span className="starTxt">{configJSON.startTxt}</span>
              </BranchTextLabelsTypography>
              <TextField
                  type="text"
                  placeholder={configJSON.manageMember}
                  className="inputField"
                  onChange={this.handleChangeBranchAgent}
                  data-test-id="branchManagedByTestId"
                  value={branchAgent}
                  error={!!this.state.branchAgentError}
                  inputProps={{
                    maxLength: 30,
                  }}
                />
              {!!this.state.branchAgentError && (
                <RequiredTxt>{this.state.branchAgentError}</RequiredTxt>
              )}
            </Box>

            <Box className="buttonBoxBranch" data-test-id="buttonBoxBranchTestID">
              <Button
                type="submit"
                className="addBtn"
                data-test-id="addBranchButtonTestID"
              >
                {isBranchEdit ? "Update Branch" : `${configJSON.addBranchBtn}`}
              </Button>
            </Box>
          </form>
        </Paper>
      </MainModal>
    );
  };


  getIconForFileType = (fileName:any) => {
    if (fileName.endsWith('.pdf')) return <PictureAsPdfIcon style={{ color: '#000000' }} />;
    console.log(fileName)
    if (fileName.endsWith('.png') || fileName.endsWith('.jpg')) return <ImageIcon style={{ color: '#000000' }} />;
    if (fileName.endsWith('.doc') || fileName.endsWith('.docx')) return <DescriptionIcon style={{ color: '#000000' }} />;
  };

  renderDocument = () => {
    const isFullyApproved = this.state.profileStatus === "fully_approved"
    const renderDocumentItems = (title: string, documents: Document[], category: DocumentCategory) => {
      const fileInputId = `file-upload-input-${category}`;
      return (
        <DocumentWrapper>
          <DocumentInnerWrapper>
            <UploadDocTitle>{title}</UploadDocTitle>
            <DocumentUploadRule>Accepted file format: .png, .jpg, .pdf, .doc, .docx</DocumentUploadRule>
            <InnerBox>
  {documents.map((doc) => (
    <UploadDocumentWrapper key={doc.id}>
      {doc.attributes.status === 'fix_required' && (
                <FileUploadedErrorNew className="FileUploadedErrorNew">
        <DocumentSelected>
          <DocumentDetailWrapper>
            <DocumentAndDelete>
              <PDFDocumentBox>
                {this.getIconForFileType(doc.attributes.name)}
              </PDFDocumentBox>
              <Tooltip title={doc.attributes.status}>
              <IconManage className="iconManage" style={{ marginLeft: '10px',marginTop:'7px' }}>
                {this.getStatusIcon(doc.attributes.status)}
              </IconManage>
              </Tooltip>
              <DeleteDocumentBox
              data-test-id="handleDeleteDoc"
                onClick={(event) => this.handleDeleteDoc(event as React.MouseEvent<HTMLDivElement, MouseEvent>, doc.id)}>
                <SvgIcon component={DeleteOutlineIcon} className="DeleteDocument" style={{ color: '#DC2626' }} />
              </DeleteDocumentBox> </DocumentAndDelete>
            <DocumentDetails><DocumentNameTypography>{doc.attributes.name}</DocumentNameTypography>
              <DocumentSizeTypography>{doc.attributes.file_size}</DocumentSizeTypography></DocumentDetails>
            <DownloadDocumentButton>
              <a
                style={{ textDecoration: 'none', display: 'flex' }}
                target="_blank"
                rel="noopener noreferrer"
                href={doc.attributes.url.url}
              >
                <DownLoadText>{configJSON.downloadBtnText}</DownLoadText><SvgIcon component={FileDownloadIcon} style={{ color: '#64748B' }} />
              </a>
            </DownloadDocumentButton> </DocumentDetailWrapper>
            </DocumentSelected>
            {doc.attributes.comment && (<FileUploadErrorText dangerouslySetInnerHTML={{ __html: doc.attributes.comment }} />)}
            </FileUploadedErrorNew>
      )}

{doc.attributes.status === 'pending' && (
        <FileUploadedErrorNew className="FileUploadedErrorNew">
        <DocumentSelected className="DocumentSelected">
          <DocumentDetailWrapper className="DocumentDetailWrapper">
            <DocumentAndDelete className="DocumentAndDelete">
              <PDFDocumentBox className="PDFDocumentBox">
                {this.getIconForFileType(doc.attributes.name)}
              </PDFDocumentBox >
              <Tooltip title={doc.attributes.status}>
              <IconManage style={{ marginLeft: '10px',marginTop:'7px' }} className="iconManage">
                {this.getStatusIcon(doc.attributes.status)}
              </IconManage>
              </Tooltip>
              <DeleteDocumentBox
                onClick={(event) => this.handleDeleteDoc(event as React.MouseEvent<HTMLDivElement, MouseEvent>, doc.id)}>
                <SvgIcon component={DeleteOutlineIcon} className="DeleteDocument" style={{ color: '#DC2626' }} />
              </DeleteDocumentBox > </DocumentAndDelete >
            <DocumentDetails className="DocumentDetails"><DocumentNameTypography className="DocumentNameTypography">{doc.attributes.name}</DocumentNameTypography >
              <DocumentSizeTypography className="DocumentSizeTypography">{doc.attributes.file_size}</DocumentSizeTypography ></DocumentDetails >
            <DownloadDocumentButton className="DownloadDocumentButton">
              <a
              target="_blank"
                rel="noopener noreferrer"
                style={{ display: 'flex', textDecoration: 'none', }}
                href={doc.attributes.url.url}
              >
                <DownLoadText>{configJSON.downloadBtnText}</DownLoadText><SvgIcon style={{ color: '#64748B' }} component={FileDownloadIcon}/>
              </a>
            </DownloadDocumentButton> 
            </DocumentDetailWrapper>
            </DocumentSelected>
            {doc.attributes.comment && (<FileUploadErrorText dangerouslySetInnerHTML={{ __html: doc.attributes.comment }} />)}
            </FileUploadedErrorNew>
      )}

      {doc.attributes.status === 'done' && (
        <DocumentSelected>
        <DocumentDetailWrapper>
          <DocumentAndDelete>
            <PDFDocumentBox>
              {this.getIconForFileType(doc.attributes.name)}
            </PDFDocumentBox>
            <Tooltip title={doc.attributes.status}>
            <IconManage className="iconManage" style={{ marginLeft: '10px',marginTop:'7px' }}>
              {this.getStatusIcon(doc.attributes.status)}
            </IconManage>
            </Tooltip>
          </DocumentAndDelete>
          <DocumentDetails>
            <DocumentNameTypography>{doc.attributes.name}</DocumentNameTypography>
            <DocumentSizeTypography>{doc.attributes.file_size}</DocumentSizeTypography>
          </DocumentDetails>
          <DownloadDocumentButton>
            <a
              style={{ textDecoration: 'none', display: 'flex' }}
              href={doc.attributes.url.url}
              target="_blank"
              rel="noopener noreferrer"
            >
              <DownLoadText>{configJSON.downloadBtnText}</DownLoadText>
              <SvgIcon component={FileDownloadIcon} style={{ color: '#64748B' }} />
            </a>
          </DownloadDocumentButton>
        </DocumentDetailWrapper>
      </DocumentSelected>
      )}
    </UploadDocumentWrapper>
  ))}
  {documents.length < 5 && (
    <AddDocumentBox>
      <input
        accept=".png, .jpg, .pdf, .doc, .docx"
        style={{ display: 'none' }}
        id={fileInputId}
        type="file"
        data-test-id="fileInputTestID"
        onChange={(event) => this.handleFileChangeDoc(event, category)}
        disabled={isFullyApproved}
      />
      <label htmlFor={fileInputId}>
        <UploadDocumentButton
          data-test-id="addDocumentTestId"
          onClick={() => document.getElementById(fileInputId)?.click()}
          disabled={isFullyApproved}
        >
          <SvgIcon component={AddIcon} style={{ color: '#34D399' }} />
          <DocUpLoadText>{configJSON.documentAddText}</DocUpLoadText>
        </UploadDocumentButton>
      </label>
    </AddDocumentBox>
  )}
</InnerBox>
          </DocumentInnerWrapper>
        </DocumentWrapper>
      );
    };
  
    interface DocumentAttributes {
      document_category: DocumentCategory;
      name: string;
      url: { url: string };
      file_size: string;
      status:string;
      comment:string;
    }
  
    interface Document {
      attributes: DocumentAttributes;
      id: string;
    }
  
    const documentsByCategory: Record<DocumentCategory, Document[]> = {
      personal_id: [],
      agent_docs: [],
      business_registration_certificate: []
    };
  
    this.state.allDocumentsData.forEach((document: Document) => {
      documentsByCategory[document.attributes.document_category].push(document);
    });
  
    return (
      <>
        {renderDocumentItems("Add Documents", documentsByCategory.agent_docs, "agent_docs")}
        {renderDocumentItems("Business Registration Certificate", documentsByCategory.business_registration_certificate, "business_registration_certificate")}
        {renderDocumentItems("Personal ID", documentsByCategory.personal_id, "personal_id")}
      </>
    );
  };

  renderBranchDelete = () => {
    return (
      <DeleteModalBox onClose={this.handlBrancheDelete} open={this.state.isBranchDelete}>
        <Card className="cardBox">
          <CardContent className="cardContent">
            <Box className="box">
            <CloseIcon onClick={this.handlBrancheDelete} className="closeIcon"/>
            </Box>
            <Typography className="deleteTxtBtn marginTop30">
              {configJSON.deleteBankAccountTitle}
            </Typography>
            <Typography className="confirmationTxt">
            {configJSON.deleteBankAccountConfirmation} <span className="deleteTxtBtnBranch">{this.state.branchName}</span>?
            </Typography>
            <Box className="deleteBtnBottomBox">

            <Button className="deleteModalInsideBtn" onClick={this.handleDeleteBranch}>
                {configJSON.deleteButtonText}
              </Button>

              <Button className="confirmModalInsideBtn" onClick={this.handlBrancheDelete}>
                {configJSON.goBackBtnText}
              </Button>
            </Box>
          </CardContent>
        </Card>
      </DeleteModalBox>
    );
  };

  showErrorToast = () => {
    return (
      <ToastContainer
        position="top-right"
        autoClose={3000}
        hideProgressBar={false}
        closeOnClick={true}
        pauseOnHover={true}
        draggable={true}
        theme="colored"
        transition={Bounce}
      />
    )
  }
  
  // Customizable Area End

  render() {
    // Customizable Area Start
    return (
      <Box>
        {this.showErrorToast()}
        <Loader loading={this.state.isLoader} />

        <AppHeader
          onHomeClick={this.onHomeClick}
          onLoginClick={undefined}
          userName={this.state.userDetails?.attributes?.first_name}
          onSignUpClick={undefined}
          toggleData={this.state.toggleDrawer}
          data-test-id="appHeaderTestId"
          handleToggle={this.handleToggle}
          isUserLogin={true}
          isLogin={false}
          role={this.state.userDetails?.attributes?.role}
          profileImg={this.state.userDetails?.attributes?.image}
        />
          <UserProfileBox>
              <SideMenuBar data-test-id="sidebarNavigationBtn"
          navigationToAnyPage={this.navigationToAnyPage} activeComponent="CustomisableUserProfiles" />
          <MainGrid>
            <Box>
            {this.renderProfileGrid()}
            {this.state.selected === "Account"
              ?
              <>
                {this.renderInfoForm()}
                {this.renderSecurityForm()}
                {this.renderNotification()}
                {this.renderActivityTable()}
              </> :
              <Box>
                {this.rednerTab()}
              </Box>
            }
            </Box>
          </MainGrid>
          {this.renderDelete()}
          {this.renderBranchDelete()}
          {this.state.isModal && this.renderBankModal()}
          {this.state.branchModal && this.renderBranchModal()}
          <Snackbar
            open={this.state.isAlert}
            autoHideDuration={1000}
            anchorOrigin={{ horizontal: "left", vertical: "top" }}
            onClose={this.oncloseAlert}
            data-test-id="alertTestId"
          >
            <Alert severity={this.state.alertType} data-test-id="alertsTestId">{this.state.alertMsg}</Alert>
          </Snackbar>
          {this.state.openReassign && this.renderReassignPopup()}
        </UserProfileBox>
      </Box>
    );
    // Customizable Area End
  }
}

// Customizable Area Start
// for documents

const webStyles = {
  container: {
    display: "grid",
    gridTemplateColumns: "3fr 7fr",
    boxShadow: "rgba(0, 0, 0, 0.35) 0px 5px 15px",
    width: "32rem",
    padding: "0.5rem 1rem",
    minHeight: "12rem",
    gap: "2rem"
  },
  defaultTxt: {
    fontFamily: "Plus Jakarta Sans",
    fontSize: "16px"
  },
  defaultTxtBold: {
    fontFamily: "Plus Jakarta Sans",
    fontSize: "16px",
    fontWeight: 700
  },
  totalCandidates: {
    display: "grid",
    gridTemplateRows: "3fr 7fr",
    alignItems: "start",
    justifyContent: "center",
    justifyItems: "center",
    color: "black",
    margin: "1rem 0rem"
  },
  italicStyle: {
    fontWeight: 500,
    fontFamily: "Plus Jakarta Sans",
    fontSize: "14px",
    fontStyle: "italic",
    color: "#212121",
    opacity: "40%"
  },
  candidatesFontSize: {
    fontSize: "1rem"
  },
  flexCenter: {
    display: "flex",
    justifyContent: "center",
    alignItems: "center"
  },
  columnCenter: {
    display: "flex",
    justifyContent: "center",
    alignItems: "center"
  },
  spaceAround: {
    display: "flex",
    justifyContent: "space-around",
    alignItems: "center"
  },
  spaceBetween: {
    display: "flex",
    justifyContent: "space-between",
    alignItems: "center",
    width: "100%"
  },
  paddingRight: {
    paddingRight: "40px"
  },
  cardContent: {
    fontWeight: 700,
    fontSize: "22px",
    lineHeight: "1",
    "&. MuiTypography-body1": {
      lineHeight: "1"
    }
  },
  cardTitle: {
    fontWeight: 400,
    fontSize: "14px",
    color: "#64748B"
  },
  fontBig: {
    fontFamily: "Plus Jakarta Sans",
    fontWeight: 400,
    fontSize: "16px",
    color: "#64748B"
  },
  secondCardHeader: {
    fontFamily: "Plus Jakarta Sans",
    fontWeight: 700,
    fontSize: "20px",
    color: "#212121"
  },
  btn: {
    backgroundColor: "blue",
    color: "white",
    borderRadius: "0",
    marginTop: "0.5rem",
    padding: "0.125rem"
  },
  flexEnd: {
    display: "flex",
    justifyContent: "flex-end",
    alignItems: "center"
  },
  fontSizeForType: { fontSize: "1 rem" },
  mediumFontSizeForType: { fontSize: "3rem" },
  itemStyle: { margin: "1rem 0rem" },
  type: { fontSize: "0.8rem", textTransform: "capitalize" },
  titletext: {
    color: "grey",
    fontWeight: "lighter",
    fontSize: "3rem"
  },
  typeText: { fontSize: "0.8rem", textTransform: "capitalize" },
  regularFont: { fontSize: "14px", color: "#212121", lineHeight: "17.64px", opacity: "80%" },
  listPadding: { padding: "0 11px" },
  backgroundColor: {
    fontFamily: "Plus Jakarta Sans",
    background: "#F7F7F7"
  },
  paddingAll: {
    padding: "20px"
  }
};

const ModalContent = styled(Box)({
  position: "relative",
  display: "flex",
  alignItems: "center",
  flexDirection: "column",
  gap: "24px",
  flexShrink: 0,
  borderRadius: "8px",
  backgroundColor: "#fff",
  width: "90%",
  maxWidth: "448px",
  height: "auto",
  minHeight: "575px",
  boxShadow: "0px 25px 50px 0px rgba(0, 0, 0, 0.09), 0px 4px 8px 0px rgba(0, 0, 0, 0.03), 0px 8px 32px 0px rgba(0, 0, 0, 0.06)",
  margin: "auto",
  "@media (min-width: 600px)": {
    width: "80%",
    maxWidth: "500px",
  },
  "@media (min-width: 960px)": {
    width: "70%",
    maxWidth: "600px",
  },
  "@media (min-width: 1280px)": {
    width: "60%",
    maxWidth: "600px",
  },
});

const ModaInnerBox = styled(Box)({
  display: "flex",
  flexDirection: "column",
  alignItems: "center",
  justifyContent: "center",
  width: "100%",
  maxWidth: "400px",
  borderRadius: "8px",
  backgroundColor: "#fff",
  padding: "20px",
  boxSizing: "border-box",
  "& .filterTitle": {
    width: "100%",
    display: "flex",
    justifyContent: "space-between",
    color: "var(--Basic-Black, #000)",
    fontFamily: "Plus Jakarta Sans",
    fontSize: "20px",
    fontStyle: "normal",
    fontWeight: 700,
    lineHeight: "24px"
  },
  "& .insideBox": {
    width: "100%",
    padding: "16px 30px",
    "& .innerContent": {
      paddingTop: "16px"
    }
  },
  "& .inputTxt": {
    fontSize: "12px",
    fontFamily: "Plus Jakarta Sans",
    fontWeight: 700,
    color: "#334155"
  },
  "& .selectInput": {
    margin: "5px 0px",
    display: "flex",
    justifyContent: "center",
    width: "100%",
    backgroundColor: "#fff",
    border: "1px solid #CBD5E1",
    borderRadius: "8px",
    height: "auto",
    padding: "0px 10px",
    boxSizing: "border-box",
    
    "& .MuiInput-root": {
      "&:focus": {
        outline: "none"
      },
      "&::before": {
        content: "none"
      },
      "&::after": {
        content: "none"
      }
    },
    "& .MuiSelect-icon": {
      color: "#0E172A",
      right: "5px"
    },
    "&.MuiInput-underline:before": {
      borderBottom: "0px solid red"
    },
    "&:hover:not(.Mui-disabled):before": {
      borderBottom: "0px solid red"
    },
    "&:hover:not(.Mui-disabled):after": {
      borderBottom: "0px solid red"
    },
    "&.MuiInput-underline:after": {
      borderBottom: "0px solid red"
    },
    "& .MuiInput-input": {
      padding: "10px"
    },
    "& .MuiSelect-select:focus": {
      backgroundColor: "transparent"
    }
  },
  "& .autocompleteField": {
    "& input": {
      marginTop: "10px"
    }
  },
  "@media (min-width: 600px)": {
    width: "90%",
    maxWidth: "400px",
    padding: "30px",
    "& .filterTitle": {
      fontSize: "20px",
      lineHeight: "24px"
    },
    "& .insideBox": {
      padding: "20px 40px",
      "& .innerContent": {
        paddingTop: "20px"
      }
    },
    "& .inputTxt": {
      fontSize: "14px"
    },
  },
  "@media (min-width: 960px)": {
    width: "80%",
    maxWidth: "400px",
    padding: "40px 0",
    "& .filterTitle": {
      fontSize: "22px"
    },
    "& .insideBox": {
      padding: "20px 50px",
      "& .innerContent": {
        paddingTop: "24px"
      }
    },
    "& .inputTxt": {
      fontSize: "16px"
    },
  },
  "@media (min-width: 1280px)": {
    width: "80%",
    maxWidth: "500px",
    padding: "50px 0",
    "& .filterTitle": {
      fontSize: "24px",
      lineHeight: "30px"
    },
    "& .insideBox": {
      padding: "50px 0",
      "& .innerContent": {
        paddingTop: "24px"
      }
    },
    "& .inputTxt": {
      fontSize: "18px"
    },
  },
});
const BoxPopUpTitle = styled(Box)({
  width: "94px",
  height: "32px",
  display: "flex",
  flexDirection:"column",
  gap: "12px",
  "@media (min-width: 600px)": {
    width: "84px",
    height: "28px",
  },
  "@media (min-width: 960px)": {
    width: "74px",
    height: "24px",
  }
});

const FilterModalTitle = styled(Typography)({
  color: "var(--Basic - Black, #000)",
  fontFamily: "Plus Jakarta Sans",
  fontSize: "20px",
  fontStyle: "normal",
  fontWeight: 700,
  lineHeight: '24px'
})

const FilterModalSubTitle = styled(Typography)({
  fontFamily: "Plus Jakarta Sans",
  fontSize: "16px",
  fontWeight: 400,
  lineHeight: "24px",
  textAlign: "left",
  textUnderlinePosition: "from-font",
  textDecorationSkipInk: "none",
  color: "#000000",
})

const StyledChip = styled(Chip)({
  display: "inline-flex",
  justifyContent: "center",
  alignItems: "center",
  paddingRight: "4px",
  backgroundColor: "white !important",
  "& .MuiChip-label": {
    color: "var(--Neutrals-Cool-gray-600, #475569)",
    fontFamily: "Plus Jakarta Sans",
    fontSize: "14px",
    fontStyle: "normal",
    fontWeight: 700,
    lineHeight: "22px",
    "@media (min-width: 600px)": {
      fontSize: "13px",
      lineHeight: "20px"
    },
    "@media (min-width: 960px)": {
      fontSize: "12px",
      lineHeight: "18px"
    }
  }
});

const StyledBoxButtonWrapper = styled(Box)({
  display: "flex",
  alignItems: "flex-start",
  gap: "24px",
  "@media (min-width: 600px)": {
    gap: "20px"
  },
  "@media (min-width: 960px)": {
    gap: "16px"
  }
});

const StyledApplyFilterButton = styled(Button)({
  display: "flex",
  padding: "10px 16px",
  justifyContent: "center",
  alignItems: "center",
  gap: "8px",
  borderRadius: "8px",
  background: "var(--Primary-green, #34D399)",
  "&:hover": {
    backgroundColor: "var(--Primary-green, #34D399) !important"
  },
  "@media (min-width: 600px)": {
    padding: "8px 14px"
  },
  "@media (min-width: 960px)": {
    padding: "6px 12px"
  }
});

const StyledTypoGraphyApply = styled(Typography)({
  color: "var(--Basic-White, var(--0, #FFF))",
  fontFamily: "Plus Jakarta Sans",
  fontSize: "16px",
  fontStyle: "normal",
  fontWeight: 700,
  lineHeight: "24px",
  textTransform:"capitalize"
});

const MenuListItems = styled(Box)({
  display: "flex",
  alignItems:"center",
  gap: "16px",
  "& .cardLogoImg": {
    height: "64px",
    width: "64px",
    borderRadius: "50%",
    backgroundColor: "#F8FAFC",
    objectFit: "cover",
    display: "block"
  },
  "& .listContentBox": {
    display: "flex",
  },
  "& .listContentTitle": {
    fontFamily: "Plus Jakarta Sans",
    fontSize: "14px",
    fontWeight: 400,
    lineHeight: "22px",
    textAlign: "left",
    textUnderlinePosition: "from-font",
    textDecorationSkipInk: "none",
    color: "#0F172A",
    minWidth: "120px"
  },
  "& .listContentValue": {
    fontFamily: "Plus Jakarta Sans",
    fontSize: "14px",
    fontWeight: 700,
    lineHeight: "22px",
    textAlign: "left",
    textUnderlinePosition: "from-font",
    textDecorationSkipInk: "none",
    color: "#0F172A"
  }
})

const UpperTable = styled(Grid)({

  "& .cards": {
    display: "flex",
    alignItems: "center",
    width: "100%",
    height: "90px",
    borderRadius: "8px",
    boxShadow: "rgba(149, 157, 165, 0.2) 0px 8px 24px",
    paddingLeft: "20px",
    bgcolor: "white",
    boxSizing: "border-box",
    backgroundColor: "#FFFFFF"
  },
  "& .cardImageContainer": {
    display: "flex",
    flexDirection: "column",
    justifyContent: "center",
    alignItems: "start",
    width: "40px",
    height: "40px"
  },
  "& .cardContentContainer": {
    display: "flex",
    flexDirection: "column",
    justifyContent: "center",
    alignItems: "start",
    padding: "0px 16px"
  }
});

const UserProfileBox = styled(Box)({
  display:"flex",
});
const PrimaryWrapper = styled(Box)({
  display:"flex",
  gap:'6px'
})

const PrimaryText = styled(Typography)({
  fontFamily: "Plus Jakarta Sans",
  fontSize: "16px",
  fontWeight: 400,
  lineHeight: "24px",
  textAlign: "left",
  color:"#0F172A"
})

const StyledCheckBox = styled(Checkbox)({
  borderRadius: "6px",
  width: "20px",
  height: "20px",
  "&.Mui-checked": {
      color: '#3D70B2',
    },
  "@media (min-width: 600px)": {
    width: "18px",
    height: "18px"
  },
  "@media (min-width: 960px)": {
    width: "16px",
    height: "16px"
  }
});

const DocUpLoadText = styled(Typography)({
  fontFamily: "Plus Jakarta Sans",
  fontSize: "14px",
  fontWeight: 600,
  lineHeight: "16px",
  textAlign: "left",
  color: "#34D399",
  "@media (max-width: 600px)": {
    fontSize: "11px",
  },
  "@media (min-width: 601px) and (max-width: 768px)": {
    fontSize: "13px",
  },
  "@media (min-width: 769px)": {
    fontSize: "14px",
  },
})

const UploadDocumentButton = styled(Button)({
  width: "100%",
  height: "32px",
  padding: "6px 10px",
  gap: "6px",
  borderRadius: "8px",
  border: "1px solid #23CF93",
  display: "flex",
  justifyContent: "center",
  alignItems: "center",
  "@media (max-width: 600px)": {
    height: "auto",
    margin: "8px 0",
  },
  "@media (min-width: 601px) and (max-width: 768px)": {
    height: "auto",
    margin: "8px 0",
  },
  "@media (min-width: 769px)": {
    height: "32px",
    margin: "0",
  },
})

const InnerBox = styled(Box)({
  display: "flex",
  margin: "16px 0",
  gap: "16px",
  "@media (max-width: 600px)": {
    flexDirection: "column",
  },
  "@media (min-width: 601px) and (max-width: 768px)": {
    flexDirection: "column",
    margin: "12px 0",
  },
  "@media (min-width: 769px)": {
    flexDirection: "row",
  }
})

const AddDocumentBox = styled(Box)({
  // width: "216px",
  height: "172px",
  padding: "16px",
  gap: "16px",
  borderRadius: "8px",
  border: "2px dotted #CBD5E1",
  display:"flex",
  alignItems:"center",
  "@media (max-width: 600px)": {
    height: "auto",
    padding: "12px",
    marginBottom: "20px",
  },
  "@media (min-width: 601px) and (max-width: 768px)": {
    height: "auto",
    padding: "14px",
    marginBottom: "20px",
  },
  "@media (min-width: 769px)": {
    height: "172px",
    padding: "16px",
  },
  justifyContent:'center'
})

const DownloadDocumentButton = styled(Button)({
  width: "100%",
  padding: "6px 10px",
  gap: "6px",
  borderRadius: "8px",
  opacity: "0.8",
  border: "1px solid #64748B",
  display: "flex",
  justifyContent: "center",
  alignItems: "center",
  marginTop:"8px",
  "@media (max-width: 600px)": {
    margin: "8px 0",
  },
  "@media (min-width: 601px) and (max-width: 768px)": {
    margin: "8px 0",
  },
  "@media (min-width: 769px)": {
    marginTop: "8px",
  },
})

const DocumentSizeTypography = styled(Typography)({
  fontFamily: "Plus Jakarta Sans",
  fontSize: "12px",
  fontWeight: 400,
  lineHeight: "18px",
  textAlign: "left",
  color: "#64748B",
  paddingTop:"4px",
  "@media (max-width: 600px)": {
    fontSize: "11px",
  },
  "@media (min-width: 601px) and (max-width: 768px)": {
    fontSize: "11px",
  },
  "@media (min-width: 769px)": {
    fontSize: "12px",
  }
})

const DocumentNameTypography = styled(Typography)({
  fontFamily: "Plus Jakarta Sans",
  fontSize: "14px",
  fontWeight: 400,
  lineHeight: "18px",
  textAlign: "left",
  color: "#0F172A",
  whiteSpace:'nowrap',
  overflow:'hidden',
  textOverflow:'ellipsis',
  "@media (max-width: 600px)": {
    fontSize: "12px",
  },
  "@media (min-width: 601px) and (max-width: 768px)": {
    fontSize: "13px",
  },
  "@media (min-width: 769px)": {
    fontSize: "14px"
  }
})

const DocumentDetails = styled(Box)({
  width: "194px",
  height: "38px",
  padding: "12px 0px",
  gap: "2px",
  "@media (max-width: 600px)": {
    width: "100%",
    height: "auto",
  },
  "@media (min-width: 601px) and (max-width: 768px)": {
    width: "100%",
    height: "auto",
  },
  "@media (min-width: 769px)": {
    width: "194px",
    height: "38px"
  }
})

const PDFDocumentBox = styled(Box)({
  width: "42px",
  height: "32px",
  gap: "0px",
  borderRadius: "130px",
  background:"#F8FAFC",
  position:"relative",
  paddingTop:"9px",
  textAlign:'center',
  "& .PDFDocument":{
    marginLeft:'20px',
    width: "24px",
    height: "24px",
    top: "8px",
    left: "8px",
    padding: "0px 1.37px",
    position:"absolute"
  }
})

const DeleteDocumentBox = styled(Box)({
  width: "36px",
  height: "36px",
  gap: "0px",
  borderRadius: "130px",
  background:"#FEE2E2",
  marginTop:'4px',
  position:"relative",
  "& .DeleteDocument":{
    width: "24px",
    height: "24px",
    top: "5px",
    left: "5px",
    padding: "0px 1.37px",
    position:"absolute"
  }
})

const DocumentAndDelete = styled(Box)({
  display:"flex",
  justifyContent:"space-between"
})

const DocumentDetailWrapper = styled(Box)({
  width: "100%",
  height: "92px",
  gap: "12px",
  "@media (max-width: 600px)": {
    height: "auto",
  },
  "@media (min-width: 601px) and (max-width: 768px)": {
    height: "auto",
  },
  "@media (min-width: 769px)": {
    height: "92px"
  }
})

const DocumentWrapper = styled(Box)({
  height: "314px",
  gap: "0px",
  borderRadius: "16px",
  background: "#FFFFFF",
  marginBottom:"30px",
  "@media (max-width: 600px)": {
    height: "auto",
    marginBottom: "20px",
  },
  "@media (min-width: 601px) and (max-width: 768px)": {
    height: "auto",
    marginBottom: "20px",
  },
  "@media (min-width: 769px)": {
    height: "314px",
    marginBottom: "30px",
    paddingBottom:'40px',
  }
})

const DocumentInnerWrapper = styled(Box)({
  paddingLeft: "40px",
  paddingTop: "30px",
  "@media (max-width: 600px)": {
    paddingLeft: "10px",
    paddingTop: "20px",
    paddingRight: "10px",
  },
  "@media (min-width: 601px) and (max-width: 768px)": {
    paddingLeft: "20px",
    paddingTop: "20px",
    paddingRight: "20px",
  },
  "@media (min-width: 769px)": {
    paddingLeft:"40px",
    paddingTop:"30px",
    paddingRight: "0px",
  }
})

const UploadDocTitle = styled(Typography)({
  fontFamily: "Plus Jakarta Sans",
  fontSize: "18px",
  fontWeight: 700,
  lineHeight: "26px",
  textAlign: "left",
  color: "#0F172A",
  "@media (max-width: 600px)": {
    fontSize: "16px",
  },
  "@media (min-width: 601px) and (max-width: 768px)": {
    fontSize: "16px",
  },
  "@media (min-width: 769px)": {
    fontSize: "18px"
  }
})

const DocumentUploadRule = styled(Typography)({
  fontFamily: "Plus Jakarta Sans",
  fontSize: "14px",
  fontWeight: 400,
  lineHeight: "18px",
  textAlign: "left",
  color: "#212121",
  "@media (max-width: 600px)": {
    fontSize: "12px",
  },
  "@media (min-width: 601px) and (max-width: 768px)": {
    fontSize: "12px",
  },
  "@media (min-width: 769px)": {
    fontSize: "14px",
  }
})

const UploadDocumentWrapper = styled(Box)({
  width: "auto",
  height: "172px",
  top: "112px",
  left: "40px",
  gap: "16px",
  "@media (max-width: 600px)": {
    top: "80px",
    left: "20px",
    height: "auto",
  },
  "@media (min-width: 601px) and (max-width: 768px)": {
    top: "80px",
    left: "20px",
    height: "auto",
  },
  "@media (min-width: 769px)": {
    top: "112px",
    left: "40px",
    height: "172px"
  }
})

const DocumentSelected = styled(Box)({
  // width: "216px",
  height: "172px",
  padding: "16px",
  gap: "16px",
  borderRadius: "8px",
  border: "1px solid #E2E8F0",
  "@media (max-width: 600px)": {
    height: "auto",
    padding: "14px",
  },
  "@media (min-width: 601px) and (max-width: 768px)": {
    height: "auto",
    padding: "14px",
  },
  "@media (min-width: 769px)": {
    height: "172px",
    padding: "16px",
  }
})
// document end

// manage staff
const BranchCard = styled(Box)({
  width: "100%",
  maxWidth: "423px",
  height: "auto",
  gap: "24px",
  borderRadius: "8px",
  border: "1px solid #E2E8F0",
  backgroundColor: "#FFFFFF",
  padding: "16px",
  boxSizing: "border-box",
})

const BranchNameAndDateWrapper = styled(Box)({
  width: "100%",
  height: "auto",
  display: "flex",
  flexDirection: "column",
  gap: "8px",
})

const BranchName = styled(Typography)({
  fontFamily: "Plus Jakarta Sans",
  fontSize: "18px",
  fontWeight: 700,
  lineHeight: "24px",
  textAlign: "left",
  color: "#000000",
  "@media (min-width: 600px)": {
    fontSize: "20px",
  },
})

const BranchCreatedDate = styled(Typography)({
  fontFamily: "Plus Jakarta Sans",
  fontSize: "10px",
  fontWeight: 400,
  lineHeight: "18px",
  textAlign: "left",
  color: "#64748B",
  "@media (min-width: 600px)": {
    fontSize: "12px",
  },
})

const BranchDetailsWrapper = styled(Box)({
  width: "100%",
  height: "auto",
  padding: "16px 0",
  boxSizing: "border-box",
})

const DetailRow = styled(Box)({
  width: "100%",
  height: "auto",
  gap: "16px",
  borderBottom: "1px solid #E2E8F0",
  display: "flex",
  flexDirection: "column",
  "@media (min-width: 600px)": {
    flexDirection: "row",
    gap: "54px",
  },
})

const DetailLabel = styled(Typography)({
  fontFamily: "Plus Jakarta Sans",
  fontSize: "12px",
  fontWeight: 400,
  lineHeight: "18px",
  color: "#64748B",
  padding: "12px 0",
  width: "100%",
  boxSizing: "border-box",
  "@media (min-width: 600px)": {
    fontSize: "14px",
    width: "124px",
    padding: "6px 0",
  },
})

const DetailValue = styled(Typography)({
  fontFamily: "Plus Jakarta Sans",
  fontSize: "12px",
  fontWeight: 700,
  lineHeight: "22px",
  textAlign: "left",
  color: "#0F172A",
  padding: "12px 0",
  width: "100%",
  boxSizing: "border-box",
  "@media (min-width: 600px)": {
    fontSize: "14px",
    width: "244px",
    padding: "6px 0",
  },
})

const ActionButtonWrapper = styled(Box)({
  width: "100%",
  height: "auto",
  gap: "4px",
  display: "flex",
  flexDirection: "column",
  "@media (min-width: 600px)": {
    flexDirection: "row",
    gap: "8px",
  },
})

const EditBranchButton = styled(Button)({
  width: "100%",
  height: "32px",
  padding: "6px 10px",
  gap: "6px",
  border: "1px solid #34D399",
  "@media (min-width: 600px)": {
    width: "81px",
  },
})

const EditBranchTypography = styled(Typography)({
  fontFamily: "Plus Jakarta Sans",
  fontSize: "14px",
  fontWeight: 700,
  lineHeight: "18px",
  textAlign: "left",
  color: "#34D399",
  "@media (min-width: 600px)": {
    fontSize: "16px",
  },
})

const DeleteBranchButton = styled(Button)({
  width: "100%",
  height: "32px",
  padding: "6px 10px",
  gap: "6px",
  border: "1px solid #FEE2E2",
  "@media (min-width: 600px)": {
    width: "102px",
  },
})

const DeleteBranchTypography = styled(Typography)({
  fontFamily: "Plus Jakarta Sans",
  fontSize: "14px",
  fontWeight: 700,
  lineHeight: "18px",
  textAlign: "left",
  color: "#DC2626",
  "@media (min-width: 600px)": {
    fontSize: "16px",
  },
})

const MobileNumberWrapper = styled(Box)({
  display:"flex",
  gap:"24px"
})

const BoxPopupClose = styled(Box)({
  display: "flex",
  padding: "4px",
  alignItems: "flex-start",
  gap: "10px",
  position: "absolute",
  right: "16px",
  top: "16px",
  "@media (min-width: 600px)": {
    right: "14px",
    top: "14px"
  },
  "@media (min-width: 960px)": {
    right: "12px",
    top: "12px"
  }
});

const BranchTextLabelsTypography = styled(Typography)({
  fontWeight: 700,
  fontSize: "16px",
  lineHeight: "24px",
  fontFamily: "Plus Jakarta Sans",
  color: "rgba(51, 65, 85, 1)"
})

const AddBranchButton = styled(Button)({
  marginLeft: "auto",
  width: "100%",
  maxWidth: "155px",
  height: "44px",
  padding: "10px 16px",
  gap: "8px",
  borderRadius: "8px",
  background:"transparent",
  justifyContent:"flex-start",
  "&:hover": {
    background: "#373335",
  },
  "@media (min-width: 600px)": {
    width: "95%",
    background: "#373335",
    "&:hover": {
      background: "#373335",
    },
  },
  "@media (min-width: 600px) and (max-width: 959px)":{
    width: "95%",
    background:"transparent",
    justifyContent:"flex-start",
    "&:f": {
      background: "#373335",
    },
  },
  "@media (min-width: 960px)": {
    width: "155px",
  },
})

const AddBranchTypography = styled(Typography)({
  fontFamily: "Plus Jakarta Sans",
  color: "#000",
  fontSize: "14px",
  fontWeight: 500,
  lineHeight: "24px",
  "@media (min-width: 600px)": {
    fontSize: "14px",
    fontWeight: 700,
    lineHeight: "24px",
    textAlign: "center",
    color: "#FFFFFF",
  },
  "@media (min-width: 600px) and (max-width: 959px)":{
    color: "#000",
    fontSize: "14px",
    fontWeight: 500,
    lineHeight: "24px",
  }
})

// manage staff end

const ToggleContainer = styled(Box)({
  display: "flex",
  alignItems: "center",
  borderRadius: "50px",
  overflow: "hidden",
  height: "40px",
  width: "170px",
  "& .selectedClass": {
    borderRadius: "50px",
    padding: "15px",
    fontSize: "14px",
    cursor: "pointer",
    backgroundColor: "#D6F6EB",
    color: "#000",
    fontWeight: "bold",
    transition: "background-color 0.3s, color 0.3s, font-weight 0.3s"
  },
  "& .nonSelectedClass": {
    borderRadius: "50px",
    padding: "15px",
    fontSize: "14px",
    cursor: "pointer",
    backgroundColor: "#FFFFFF",
    color: "#000",
    fontWeight: "normal",
    transition: "background-color 0.3s, color 0.3s, font-weight 0.3s"
  },
  "& .ExpandClass":{
    width: "100%",
    textAlign: "center"
  }
});

const ProgressBox = styled(Box)({
  display: "flex",
  backgroundColor: "#FFFFFF",
  maxWidth: "600px",
  padding: "15px",
  flexDirection: "row",
  alignItems: "center",
  gap: "20px",
  "@media (max-width: 1200px)": {
    maxWidth: "500px",
    gap: "15px",
  },
  "@media (max-width: 992px)": {
    maxWidth: "320px",
    gap: "10px",
  },
  "@media (max-width: 768px)": {
    maxWidth: "500px",
    gap: "8px",
  },
  "@media (max-width: 576px)": {
    maxWidth: "100%",
    flexDirection: "column",
    alignItems: "center",
    gap: "10px",
  },
  "& .linearBox": {
    width: "150px",
    "& .MuiLinearProgress-colorPrimary": {
      borderRadius: "20px",
      backgroundColor: "#D9D9D9",
      height: "10px"
    },
    "& .MuiLinearProgress-barColorPrimary": {
      backgroundColor: "#34D399"
    }
  },

  "& .sendBtn": {
    fontFamily: "Plus Jakarta Sans",
    color: "#64748B",
    textTransform: "unset",
    fontSize: "14px",
    fontWeight: "normal",
    backgroundColor: "#F1F5F9",
    borderRadius: "5px",
    padding: "5px !important",
    "@media (max-width: 1200px)": {
      width: "220px",
    },
    "@media (max-width: 768px)": {
      width: "200px",
    },
    "@media (max-width: 576px)": {
      width: "100%",
    },
    "&:disabled":{
      cursor:"not-allowed"
    }
  },
  "& .percantageTxt": {
    fontFamily: "Plus Jakarta Sans",
    color: "#2A0066",
    textTransform: "unset",
    fontSize: "12px",
    fontWeight: "bold"
  }
});


const MainGrid = styled(Box)(({ theme }) => ({
  width:"100%",
  padding: "50px",
  display: "flex",
  flexDirection: "column",
  gap: "30px !important",
  backgroundColor: "#F7F7F7",
  overflowY:"scroll",
  [theme.breakpoints.down("md")]: {
    marginLeft: "64px",  
  },
  "& .headerBox": {
    display: "flex",
    justifyContent: "space-between",
    gap: "20px",
    "@media (max-width: 800px)": {
      flexDirection: "column",
      alignItems: "center"
    }
  },
"& .profileGrid": {
  marginBottom:"30px"
}, 
  "& .renderMobileBoxClass":{
    gap: '10px'
  },

  "& .headingBox": {
    display: "flex",
    alignItems: "center",
    justifyContent: "space-between",
    gap: "20px",
    "@media (max-width: 800px)": {
      flexDirection: "column",
      alignItems: "center"
    }
  },

  "& .toggleContainer": {
    padding: "4px",
    backgroundColor: "#ffff",
    border: "1px solid #34D399",
    borderRadius: "50px",
    height: "40px",
    width: "170px",

  },

  "& .heading": {
    fontWeight: 700,
    fontSize: "22px !important",
    lineHeight: "27px !important",
    fontFamily: "Plus Jakarta Sans !important",
    color: "rgba(33, 33, 33, 1)"
  },

  "& .profileBox": {
    paddingTop: "20px",
    display: "flex",
    alignItems: "center",
    gap: "30px"
  },

  "& .imageBox": {
    width: "136px",
    height: "136px",
    borderRadius: "10px",
    backgroundColor: "rgba(0,0,0,0.3)"
  },

  "& .userImage": {
    width: "100%",
    height: "100%"
  },
  "& .imageInputBtn": { display: "none" },
  "& .editIcon": {
    borderRadius: "50%",
    border: "5px solid white",
    cursor: "pointer",
    position: "relative",
    bottom: 20,
    left: 115,
    backgroundColor: "rgba(228, 230, 231, 1)"
  },

  "& .textBox": {
    display: "flex",
    flexDirection: "column",
    gap: "10px",
  },
  "& .detailsIcon": {
    height: "15px",
    width: "15px",
    margin: "0px 5px 0px 0px"
  },
  "& .detailsTxt": {
    fontSize: "11px",
    fontFamily: "Plus Jakarta Sans",
    color: "#212121",
  },
  "& .detailsTxtNew": {
    fontSize: "11px",
    fontFamily: "Plus Jakarta Sans",
    color: "#212121",
  },
  "& .innerBox": {
    display: "flex",
    alignItems: "center",
    margin: "0px 10px 0px 0px"
  },
  "& .userDetailsInnerMainBox": {
    display: "flex",
    gap:"8px",
  },
  "& .agentContactDetails": {
    display: "flex",
    gap:"8px",
    "@media (max-width: 480px)": {
      flexDirection: "column"
    }
  },
  "& .userName": {
    fontWeight: 700,
    fontSize: "22px !important",
    lineHeight: "27px !important",
    fontFamily: "Plus Jakarta Sans !important",
    color: "rgba(33, 33, 33, 1)"
  },
  "& .userValue": {
    fontWeight: 400,
    fontSize: "12px !important",
    lineHeight: "15px !important",
    fontFamily: "Plus Jakarta Sans !important",
    color: "rgba(33, 33, 33, 1)"
  },
  "& .userKey": {
    fontWeight: 600,
    fontSize: "12px !important",
    lineHeight: "15px !important",
    fontFamily: "Plus Jakarta Sans !important",
    color: "rgba(33, 33, 33, 1)",
    margin: "0px 5px 0px 0px"
  },

  "& .personalInfo": {
    padding: "30px 0",
    gap: "20px 0px",
    display: "flex",
    "@media (max-width: 800px)": {
      padding: "15px"
    }
  },

  "& .infoFormField": {
    display: "flex",
    alignItems: "flex-start",
    justifyContent: "space-between",
    gap: "10px"
  },

  "& .formFieldBox": {
    width: "350px",
    display: "flex",
    alignItems: "flex-start",
    flexDirection: "column",
    "& .selectField":{
      "& .MuiSelect-select.Mui-disabled":{
        color:"rgba(0, 0, 0, 0.38)"
      }
    }
  },
  "& .NewActivity":{
    "@media (max-width: 768px)":{
      marginTop: "30px"
    },
    "@media (max-width: 600px)":{
      marginTop: "30px"
    }
  },

  "& .inputFieldBox": {
    boxSizing: "border-box",
    width: "100%"
  },

  "& .inputField": {
    backgroundColor: "#fff",
    width: "100%",
    border: "1px solid #CBD5E1",
    borderRadius: "8px",
    height: "50px",
    margin: "5px 0px",
    display: "flex",
    justifyContent: "center",
    padding: "0px 10px",
    boxSizing: "border-box",
    "& .MuiInput-root": {
      "&:focus": {
        outline: "none"
      },
      "&::before": {
        content: "none"
      },
      "&::after": {
        content: "none"
      }
    }
  },

  "& .inputFieldCalender": {
    "& .disabled":{
      color:"rgba(0, 0, 0, 0.38) !important"
    },
    "& .grayTxt":{
      color:"#999"
    },
    "& .whiteTxt":{
    color:"#000"
    },
    backgroundColor: "#fff",
    width: "100%",
    border: "1px solid #CBD5E1",
    borderRadius: "8px",
    height: "50px",
    margin: "5px 0px",
    display: "flex",
    padding: "0px 10px",
    boxSizing: "border-box",
    alignItems: "center",
    cursor: "pointer",
    justifyContent: "space-between",
    "& .MuiInput-root": {
      "&:focus": {
        outline: "none"
      },
      "&::before": {
        content: "none"
      },
      "&::after": {
        content: "none"
      }
    }, "& .calendarIcon": {
      color: "#000"
    },
  },


  "& .calenderBox": {
    position: "absolute",
    zIndex: 50
  },

  "& .react-datepicker__input-container": {
    border: "1px solid #CBD5E1",
    borderRadius: "8px",
    height: "50px",
    margin: "5px 0px"
  },

  "& .dateclass": {
    border: "0px solid #CBD5E1",
    borderRadius: "8px",
    height: "50px",
    width: "100%",
    padding: "0px 10px"
  },

  "& .selectField": {
    backgroundColor: "#fff",
    width: "100%",
    boxSizing: "border-box",
    border: "1px solid #CBD5E1",
    padding: "0px 10px",
    height: "50px",
    margin: "5px 0px",
    borderRadius: "8px",
    display: "flex",
    justifyContent: "center",
    "@media (max-width: 600px)": {
      fontSize: "15px"
    },
    "& .MuiSelect-icon": {
      color: "#0E172A",
      right: "5px"
    },
    "& .MuiInput-root": {
      "&::before": {
        content: "none"
      },
      "&:focus": {
        outline: "none"
      },
      "&::after": {
        content: "none"
      }
    },
    "&.MuiInput-underline:after": {
      borderBottom: "0px solid red"
    },
    "&.MuiInput-underline:before": {
      borderBottom: "0px solid red"
    },
    "&:hover:not(.Mui-disabled):before": {
      borderBottom: "0px solid red"
    },
    "& .MuiSelect-select:focus": {
      backgroundColor: "transparent"
    },
    "&:hover:not(.Mui-disabled):after": {
      borderBottom: "0px solid red"
    },
    "& .MuiSelect-select.Mui-disabled":{
      color:"rgba(0, 0, 0, 0.38)"
    }
  },

  "& .fieldText": {
    fontWeight: 700,
    fontSize: "16px !important",
    lineHeight: "24px !important",
    fontFamily: "Plus Jakarta Sans !important",
    color: "rgba(51, 65, 85, 1)"
  },

  "& .btnGrid": {
    display: "flex",
    alignItems: "center",
    justifyContent: "center"
  },

  "& .saveInfo": {
    width: "263px",
    height: "54px",
    borderRadius: "8px",
    fontWeight: 700,
    fontSize: "16px !important",
    lineHeight: "24px !important",
    fontFamily: "Plus Jakarta Sans !important",
    backgroundColor: "rgba(52, 211, 153, 1)",
    color: "white",
    textTransform: "none",
    "&:disabled":{
      cursor:"not-allowed"
    }
    },

  "& .starTxt": {
    color: "red",
    fontFamily: "Plus Jakarta Sans !important"
  },

  "& .securityInputField": {
    backgroundColor: "#fff",
    width: "100%",
    border: "1px solid #CBD5E1",
    borderRadius: "8px",
    height: "50px",
    margin: "5px 0px",
    display: "flex",
    justifyContent: "center",
    padding: "0px 10px",
    boxSizing: "border-box",
    "& .MuiInput-root": {
      "&:focus": {
        outline: "none"
      },
      "&::before": {
        content: "none"
      },
      "&::after": {
        content: "none"
      }
    }
  },

  "& .emailPrefrencesGrid": {
    width: "100%"

  },

  "& .emailPrefrencesBox": {
    border: "1px solid rgba(203, 213, 225, 1)",
    width: "100%",
    borderRadius: "10px",
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    height: "200px",
    alignSelf: "center",
    backgroundColor: "#fff"
  },

  "& .emailPrefrencesText": {
    fontWeight: 700,
    fontSize: "32px !important",
    lineHeight: "40px !important",
    fontFamily: "Plus Jakarta Sans !important"
  },

  "@media screen and (min-width: 600px) and (max-width: 959px)": {
    "& .securityFormField": {
      justifyContent: "center"
    }
  },
  "@media screen and (min-width: 0px) and (max-width: 599px)": {
    "& .emailPrefrencesText": {
      fontSize: "22px !important",
      lineHeight: "30px !important"
    },
    padding: "20px",
    "& .infoFormField": {
      justifyContent: "center"
    },

    "& .profileBox": {
      flexDirection: "column"
    }
  },
}));

const SelectInput = styled(Select)({
  "@media (max-width: 600px)": {
    fontSize: "15px"
  },
  backgroundColor: "#fff",
  width: "100%",
  border: "1px solid #CBD5E1",
  borderRadius: "8px",
  height: "50px",
  margin: "5px 0px",
  display: "flex",
  justifyContent: "center",
  padding: "0px 10px",
  boxSizing: "border-box",
  "& .MuiSelect-icon": {
    color: "#0E172A",
    right: "5px"
  },
  "& .MuiInput-root": {
    "&:focus": {
      outline: "none"
    },
    "&::before": {
      content: "none"
    },
    "&::after": {
      content: "none"
    }
  },
  "&.MuiInput-underline:before": {
    borderBottom: "0px solid red"
  },
  "&.MuiInput-underline:after": {
    borderBottom: "0px solid red"
  },
  "&:hover:not(.Mui-disabled):before": {
    borderBottom: "0px solid red"
  },
  "&:hover:not(.Mui-disabled):after": {
    borderBottom: "0px solid red"
  },
  "& .MuiSelect-select:focus": {
    backgroundColor: "transparent"
  }
});

const RequiredTxt = styled("span")({
  color: "red",
  fontFamily: "Plus Jakarta Sans",
  fontSize: "15px"
});

const TableMainBox = styled(Box)({
  "& .MuiTableCell-head": {
    color: "#000",
    fontWeight: "600",
    fontSize: "14px"
  },
  "& .MuiTableContainer-root": {
    margin: "30px 0px"
  },
  "& .MuiPaper-elevation1": {
    boxShadow: "none",
    borderRadius: "8px"
  }
});

const NotificationBox = styled(Box)({
  "& .maarginBottom": { marginBottom: "30px" },
  "& .emailTxt": {
    color: "#2A0066",
    fontFamily: "Plus Jakarta Sans",
    fontSize: "16px",
    fontWeight: "700"
  },
  "& .normalTxt": {
    color: "#0F172A",
    fontFamily: "Plus Jakarta Sans",
    fontSize: "16px",
    fontWeight: "400"
  },
  "& .imageTxtBox": {
    display: "flex",
    gap: "10px",
    alignItems: "center"
  },
  "& .alllowAllBox": {
    backgroundColor: "#F8FAFC",
    height: "60px",
    alignItems: "center",
    display: "flex", justifyContent: "space-between",
    padding: "20px",
    borderBottom: "1px solid #E2E8F0",
    "@media (max-width: 480px)": {
      flexDirection: "column"
    }
  },
  "& .emailImg": {
    height: "30px",
    width: "30px"
  },
  "& .gridBox": {
    height: "70px",
    alignItems: "center",
    padding: "20px",
    display: "flex", justifyContent: "space-between",
    borderBottom: "1px solid #E2E8F0"
  }
});

const IOSSwitch = styled(Switch)(({ theme }) => ({
  width: 42,
  height: 26,
  padding: 0,
  "& .MuiSwitch-switchBase": {
    padding: 0,
    margin: 2,
    transitionDuration: "100ms",
    "&.Mui-checked": {
      transform: "translateX(16px)",
      color: "#fff",
      "& + .MuiSwitch-track": {
        backgroundColor: "#6200EA",
        opacity: 1,
        border: "none"
      },
      "& .MuiSwitch-thumb": {
        color: "#fff",
        border: "6px solid #fff"
      },
      "&.Mui-disabled + .MuiSwitch-track": {
        opacity: 0.5
      }
    },
    "&.Mui-disabled .MuiSwitch-thumb": {
      color: theme.palette.grey[100]
    },
    "&.Mui-disabled + .MuiSwitch-track": {
      opacity: 0.7
    }
  },
  "& .MuiSwitch-thumb": {
    boxSizing: "border-box",
    width: 22,
    height: 22
  },
  "& .MuiSwitch-track": {
    borderRadius: 13,
    backgroundColor: "#E9E9EA",
    opacity: 1,
    transition: theme.transitions.create(["background-color"], {
      duration: 500
    })
  },
}));


const WhatsAppBox = styled(Box)({
  "& .insideBox": {
    padding: "20px",
    width: "100%",
    height: "100%",
    justifyContent: "center",
    alignItems: "center",
    display: "flex",
    flexDirection: "column",
    gap: "5px",
    "@media (max-width: 600px)": {
      width: "unset",
    },
  },
  "& .comingSoonTxt": {
    color: "#000000",
    fontFamily: "Plus Jakarta Sans",
    fontSize: "18px",
    fontWeight: "700"
  },
  "& .comingSoonDescTxt": {
    color: "#000000",
    fontFamily: "Plus Jakarta Sans",
    fontSize: "14px",
    fontWeight: "400"
  }
});

const MainTabBox = styled(Box)({
  margin: "30px 0px 0px 0px",
  "& .MuiAppBar-colorPrimary": {
    backgroundColor: "#fff"
  },
  "& .MuiTab-wrapper": {
    color: "#000"
  },
  "& .MuiBox-root-18": { padding: "24px 0px" },

  "& .MuiTabs-indicator ": {
    backgroundColor: "#34D399",
    height: "5px",
    borderTopLeftRadius: "10px",
    borderTopRightRadius: "10px"
  },
  "& .Styled(MuiBox)-root-3 .MuiTab-wrapper ": {
    color: "blue !important",
    fontSize: "50px"
  },
  "& .MuiPaper-elevation4": {
    boxShadow: "none",
    borderBottom: "2px solid #E2E8F0"
  },
  "& MuiBox-root-18": {
    backgroundColor: "#fff"
  },
  "& .tabLabel": {
    textTransform: "unset",
    fontSize: "15px"
  },
  "& .SvgIconAddBranch": {
    color:"#000",
    "@media (min-width: 600px)": {
      color: "#fff",
    },
    "@media (min-width: 600px) and (max-width: 959px)":{
      color:"#000",
    }
  },
  "& .MuiButton-root": {
    textTransform:"none"
  }
});

const TabBox = styled(Box)({
  padding: "24px 0px"
}

);

const StyledBankCard = styled(Box)({
  "& .MuiCardContent-root": {
    padding: "0px !important",
    minHeight:"400px",
    display:'flex',
    flexDirection:"column",
    justifyContent:"space-between"
  },
  "& .bankHeadingTxt": {
    width: "200px",
    marginLeft: "10px",
    fontWeight: 700,
    fontSize: "18px !important",
    fontFamily: "Plus Jakarta Sans !important",
    color: "#B2363A",
    padding: "10px",
    "@media (max-width: 800px)": {
      padding: "0px"
    }
  },
  "& .headingBoldTxt": {
    marginLeft: "10px",
    width: "200px",
    minWidht:"200px",
    fontWeight: 700,
    fontSize: "14px !important",
    fontFamily: "Plus Jakarta Sans !important",
    color: "#212121",
    padding: "10px",
    "@media (max-width: 800px)": {
      padding: "0px"
    }
  },
  "& .detailsTxt": {
    fontWeight: 400,
    fontSize: "14px !important",
    fontFamily: "Plus Jakarta Sans !important",
    color: "#212121",
    padding: "10px"
  },
  "& .rowBox": {
    display: "flex",
    "@media (max-width: 800px)": {
      flexDirection: "column",
      alignItems: "flex-start"
    },
    "& .addBank": {
      height: "100%",
      backgroundColor: "red"
    },
    "& .detailsTxt":{
      minWidth: "300px",
      maxWidth: "400px"
    }
  },
  "& .cardContentBox": {
    height: "100%",
    display: "flex",
    justifyContent: "center",
    alignItems: "center"
  },
  "& .addBankBtn": {
    fontFamily: "Plus Jakarta Sans",
    color: "#34D399",
    textTransform: "unset",
    fontSize: "14px",
    fontWeight: "normal",
    backgroundColor: "#FFFFFF",
    borderRadius: "10px",
    padding: "5px !important",
    width: "250px",
    border: "1px solid #34D399",
    height: "40px"
  },
  "& .editDeleteBtnBox": {
    borderTop: "1px solid #E2E8F0",
    display: "flex",
    alignItems: "center",
    padding: "20px !important",
    gap: "10px",
    justifyContent:"space-between"
  },
  "& .editBtn": {
    textTransform: "unset",
    fontSize: "14px",
    fontWeight: "bold",
    backgroundColor: "#FFFFFF",
    borderRadius: "10px",
    padding: "5px 10px",
    color: "#34D399",
    border: "1px solid #34D399"
  },
  "& .deleteBtn": {
    textTransform: "unset",
    fontSize: "14px",
    fontWeight: "bold",
    backgroundColor: "#FFFFFF",
    borderRadius: "10px",
    padding: "5px 10px",
    color: "#DC2626",
    border: "1px solid #DC2626",
  },
  "& .primaryBankButton":{
    width: "163px",
    height: "35px",
    borderRadius: "8px",
    fontWeight: 700,
    lineHeight: "24px !important",
    fontFamily: "Plus Jakarta Sans !important",
    backgroundColor: "rgba(52, 211, 153, 1)",
    color: "white",
    textTransform: "none",
  }
});

const DeleteModalBox = styled(Modal)({
  display: "flex",
  justifyContent: "center",
  alignItems: "center",
  height: "100%",
  "& .cardBox": {
    maxWidth: "400px",
    width: "100%",
    display: "flex",
    flexDirection: "column",
    justifyContent: "center",
    alignItems: "center",
    boxSizing: "border-box"
  },
  "& .cardContent": {
    padding: "0px",
    display: "flex",
    flexDirection: "column",
    gap: "15px",
  },
  "& .box": {
    position: "relative"
  },
  "& .closeIcon": {
    position: "absolute",
    right: "20px",
    top: "20px"
  },
  "& .deleteTxtBtn": {
    fontWeight: 700,
    fontSize: "20px !important",
    fontFamily: "Plus Jakarta Sans !important",
    color: "#212121",
    padding:"0 16px"
  },
  "& .deleteTxtBtnBranch": {
    fontWeight: 700,
    fontSize: "20px !important",
    fontFamily: "Plus Jakarta Sans !important",
    color: "#212121",
  },
  "& .marginTop30":{marginTop: "30px"},
  "& .confirmationTxt": {
    color: "#212121",
    fontFamily: "Plus Jakarta Sans",
    fontSize: "18px",
    fontWeight: "400",
    padding:"0 16px",
    paddingBottom:"24px"
  },
  "& .deleteBtnBottomBox": {
    width: "400px",
    height: "80px",
    padding: "24px 0px 0px 0px",
    gap: "16px",
    borderRadius: "10px",
    borderTop: "1px solid #E2E8F0",
    background: "#FFFFFF",
    justifyContent: "flex-end",
    display: "flex",
    alignItems: "center"
  },
  "& .deleteModalInsideBtn": {
    width: "91px",
    height: "32px",
    padding: "6px 10px",
    gap: "6px",
    borderRadius: "8px",
    background: "#FFFFFF",
    border: "1px solid #BB393D",
    boxShadow: "0px 4px 4px 0px #00000040",
    color:"#BB393D"
  },
  "& .confirmModalInsideBtn":{
    width: "91px",
    height: "32px",
    padding: "6px 10px",
    gap: "6px",
    borderRadius: "8px",
    background: "#BB393D",
    color: "#FFFFFF",
    marginRight:"24px"
  }
});

const MainModal = styled(Modal)({
  display: "flex",
  justifyContent: "center",
  padding: "20px",
  "& .paper": {
    overflowY: "scroll",
    width: "480px",
    borderRadius: "10px",
    display: "flex",
    flexDirection: "column",
    padding: "30px 35px",
    gap: "20px",
    position:"relative",
    height:"auto"
  },
  "& .MangeStaff-paper":{
    overflowY: "scroll",
    borderRadius: "10px",
    display: "flex",
    padding: "30px 35px",
    gap: "20px",
    width: "480px",
    position:"relative",
    height:"auto"
  },
  "& .heading": {
    fontWeight: 700,
    fontSize: "24px !important",
    fontFamily: "Plus Jakarta Sans !important",
    color: "rgba(33, 33, 33, 1)",
    textAlign: "center"
  },
  "& .formFieldBox": {
    width: "100%",
    "& .selectField":{
      "& .MuiSelect-select.Mui-disabled":{
        color:"rgba(0, 0, 0, 0.38)"
      }
    }
  },
  "& .inputFieldBox": {
    boxSizing: "border-box",
    width: "100%",
    display: "flex",
    justifyContent: "center",
    flexDirection: "column",
    marginBottom:"10px"
  },
  "& .inputField": {
    width: "100%",
    border: "1px solid #CBD5E1",
    borderRadius: "8px",
    height: "50px",
    margin: "5px 0px",
    display: "flex",
    justifyContent: "center",
    padding: "0px 10px",
    boxSizing: "border-box",
    "& .MuiInput-root": {
      "&:focus": {
        outline: "none"
      },
      "&::before": {
        content: "none"
      },
      "&::after": {
        content: "none"
      }
    }
  },
  "& .fieldText": {
    fontWeight: 700,
    fontSize: "20px !important",
    lineHeight: "28px !important",
    fontFamily: "Plus Jakarta Sans !important",
    color: "rgba(33, 33, 33, 1)"
  },
  "& .errorText": {
    color: "red"
  },
  "& .starTxt": {
    color: "red",
    fontFamily: "Plus Jakarta Sans !important"
  },
  "& .buttonBox": {
    display: "flex",
    alignItems: "center",
    width: "100%",
    justifyContent: "space-between",
    paddingTop:"20px"
  },
  "& .buttonBoxBranch": {
    display:"flex",
    justifyContent: "center",
    paddingTop: "34px",
  }, 
  "& .cancelBtn": {
    color: "rgba(52, 211, 153, 1)",
    background: "rgba(214, 246, 235, 1)",
    borderRadius: "8px",
    width: "45%",
    border: "1px solid rgba(52, 211, 153, 1)",
    textTransform: "none",
    "&.MuiButton-root:hover": {
      background: "rgba(214, 246, 235, 1)",
    }
  },
  "& .addBtn": {
    background: "rgba(52, 211, 153, 1)",
    borderRadius: "8px",
    width: "45%",
    textTransform: "none",
    color: "white",
    "&.MuiButton-root:hover": {
      background: "rgba(52, 211, 153, 1)"
    }
  },
});

const UpdatedMainModelStyle = styled(Paper)({
  "& .HeadingContainer" : {
    marginBottom: "20px",
    display:"flex",
    justifyContent:"flex-start"
  },
  "& .NewHeading":{
    color: "rgba(33, 33, 33, 1)",
    fontWeight: 700,
    fontSize: "24px !important",
    fontFamily: "Plus Jakarta Sans !important"
  },
  "& .MangeStaffMobileContainer": {
    width: "100%"
  }
})
// added for Aggreement screen
// Breakpoints
const breakpoints = {
  mobile: "600px",
  tablet: "900px",
  desktop: "1200px",
  largeDesktop: "1440px",
};

const TermsAndServiceWrapper = styled(Box)(({ theme }) => ({
  height: "auto",
  borderRadius: "16px",
  backgroundColor: "#FFFFFF",
  padding: "50px",
  position: "relative",
  marginBottom:"20px",
  [theme.breakpoints.down("sm")]: {
    padding: "20px",
    marginBottom:"20px"
  },
  [theme.breakpoints.between("sm", "md")]: {
    padding: "30px",
    marginBottom:"30px"
  },
}));

const SectionTitle = styled(Typography)(({ theme }) => ({
  fontFamily: "Plus Jakarta Sans",
  fontSize: "18px",
  fontWeight: 700,
  lineHeight: "26px",
  textAlign: "left",
  color: "#0F172A",
  [theme.breakpoints.down("sm")]: {
    fontSize: "13px",
    lineHeight: "24px",
    width:"50%"
  },
}));

const InstructionTitle = styled(Typography)(({ theme }) => ({
  fontFamily: "Plus Jakarta Sans",
  fontSize: "14px",
  fontWeight: 400,
  lineHeight: "18px",
  textAlign: "left",
  color: "#0F172A",
  [theme.breakpoints.down("sm")]: {
    fontSize: "10px",
    lineHeight: "16px",
    width:"50%"
  },
}));

const AgreementContainer = styled(Box)(({ theme }) => ({
  display: "flex",
  justifyContent: "space-between",
  margin: "24px 0",
  position: "relative",
  zIndex: 5,
  [theme.breakpoints.down("sm")]: {
    flexDirection: "column",
    marginRight: "16px"
  },
}));

const DownloadAgreementButton = styled(Box)(({ theme }) => ({
  width: "330px",
  height: "98px",
  padding: "16px 0px 0px 0px",
  gap: "16px",
  borderRadius: "8px",
  opacity: "0px",
  border: "1px solid #E2E8F0",
  background: "#F1F5F9",
  [theme.breakpoints.down("sm")]: {
    width: "100%",
    height: "auto",
  },
}));

const DownloadDocumentText = styled(Typography)(({ theme }) => ({
  fontFamily: "Plus Jakarta Sans",
  fontSize: "14px",
  fontWeight: 400,
  lineHeight: "18px",
  textAlign: "center",
  [theme.breakpoints.down("sm")]: {
    fontSize: "12px",
  },
}));

const DownloadFileButton = styled(Button)(({ theme }) => ({
  width: "298px",
  padding: "6px 10px",
  gap: "6px",
  borderRadius: "8px",
  opacity: "0px",
  border: "1px solid #64748B",
  margin: "16px",
  display: "flex",
  justifyContent: "center",
  alignItems: "center",
  [theme.breakpoints.down("sm")]: {
    width: "100%",
    margin: "8px 0",
  },
}));

const UploadFileButton = styled(Button)(({ theme }) => ({
  width: "298px",
  height: "32px",
  padding: "6px 10px 6px 10px",
  gap: "6px",
  borderRadius: "8px",
  border: "1px solid #23CF93",
  margin: "16px",
  display: "flex",
  justifyContent: "center",
  alignItems: "center",
  [theme.breakpoints.down("sm")]: {
    width: "100%",
    height: "auto",
    margin: "8px 0",
  },
}));

const DownLoadText = styled(Typography)(({ theme }) => ({
  fontFamily: "Plus Jakarta Sans",
  fontSize: "16px",
  fontWeight: 700,
  lineHeight: "18px",
  textAlign: "left",
  color: "#64748B",
  [theme.breakpoints.down("sm")]: {
    fontSize: "12px",
  },
}));

const UpLoadText = styled(Typography)(({ theme }) => ({
  fontFamily: "Plus Jakarta Sans",
  fontSize: "16px",
  fontWeight: 700,
  lineHeight: "18px",
  textAlign: "left",
  color: "#34D399",
  [theme.breakpoints.down("sm")]: {
    fontSize: "11px",
  },
}));

const ReadItThrough = styled(Box)(({ theme }) => ({
  width: "200px",
  height: "52px",
  padding: "16px 0px 0px 0px",
  gap: "16px",
  borderRadius: "8px 0px 0px 0px",
  [theme.breakpoints.down("sm")]: {
    width: "100%",
    height: "auto",
    padding: "8px 0",
  },
}));

const ReadItTypography = styled(Typography)(({ theme }) => ({
  fontFamily: "Plus Jakarta Sans",
  fontSize: "14px",
  fontWeight: 400,
  lineHeight: "18px",
  textAlign: "center",
  color: "#0F172A",
  [theme.breakpoints.down("sm")]: {
    fontSize: "12px",
  },
}));

const DoneTypography = styled(Typography)(({ theme }) => ({
  fontFamily: "Plus Jakarta Sans",
  fontSize: "14px",
  fontWeight: 400,
  lineHeight: "18px",
  textAlign: "center",
  color: "#64748B",
  [theme.breakpoints.down("sm")]: {
    fontSize: "12px",
  },
}));

const ReadWrapper = styled(Box)(({ theme }) => ({
  display: "flex",
  gap: "8px",
  justifyContent: "center",
  alignItems: "center",
  [theme.breakpoints.down("sm")]: {
    gap: "4px",
    margin: "20px 0px 20px 150px"
  },
}));

const DoneWrapper = styled(Box)(({ theme }) => ({
  display: "flex",
  gap: "8px",
  justifyContent: "center",
  alignItems: "center",
  [theme.breakpoints.down("sm")]: {
    gap: "4px",
    margin: "20px 0px 20px 150px"
  },
}));

const BackgroundLine = styled(Box)(({ theme }) => ({
  top: "60%",
  width: "100%",
  height: "10px",
  backgroundColor: "#F1F5F9",
  position: "absolute",
  left: "0",
  transform: "translateY(-55%)",
  zIndex: -1, // Add z-index here
  [theme.breakpoints.down("sm")]: {
    top: "0",
    bottom: "0",
    width: "10px",
    height: "100%",
    backgroundColor: "#F1F5F9",
    position: "absolute",
    left: "50%",
    transform: "translateX(-50%)",
    zIndex: -1, // Add z-index for small screens as well
  },
}));

const BackgroundLineSuccess2 = styled(Box)(({ theme }) => ({
  top: "60%",
  width: "50%",
  height: "10px",
  backgroundColor: "#34D399",
  position: "absolute",
  left: "0",
  zIndex: -1,
  transform: "translateY(-55%)",
  [theme.breakpoints.down("sm")]: {
    top: "0",
    bottom: "0",
    width: "10px", 
    height: "100%",
    backgroundColor: "#34D399",
    position: "absolute",
    left: "50%",
    transform: "translateX(-50%)",
    zIndex: -1,
  },
}));

const BackgroundLineSuccess = styled(Box)(({ theme }) => ({
  top: "60%",
  width: "100%",
  height: "10px",
  backgroundColor: "#34D399",
  position: "absolute",
  left: "0",
  zIndex: -1,
  transform: "translateY(-55%)",
  [theme.breakpoints.down("sm")]: {
    top: "0",
    bottom: "0",
    width: "10px", 
    height: "100%",
    backgroundColor: "#34D399",
    position: "absolute",
    left: "50%",
    transform: "translateX(-50%)",
    zIndex: -1,
  },
}));

const BackgroundLinePending = styled(Box)(({ theme }) => ({
  top: "60%",
  width: "70%",
  height: "10px",
  backgroundColor: "#FFFF00",
  position: "absolute",
  right: "0",
  zIndex: -1,
  transform: "translateY(-55%)",
  [theme.breakpoints.down("sm")]: {
    top: "0",
    bottom: "0",
    width: "10px", 
    height: "100%",
    backgroundColor: "#34D399",
    position: "absolute",
    left: "50%",
    transform: "translateX(-50%)",
    zIndex: -1,
  },
}));

const BackgroundLineFailure = styled(Box)(({ theme }) => ({
  top: "60%",
  width: "100%",
  height: "10px",
  backgroundColor: "#FEE2E2",
  position: "absolute",
  left: "0",
  transform: "translateY(-50%)",
  zIndex: -1,
  [theme.breakpoints.down("sm")]: {
    top: "0",
    bottom: "0",
    width: "10px", 
    height: "100%",
    backgroundColor: "#FEE2E2",
    position: "absolute",
    left: "50%",
    transform: "translateX(-50%)",
    zIndex: -1,
  },
}));

const FileUploaded = styled(Box)(({ theme }) => ({
  width: "330px",
  height: "98px",
  padding: "8px",
  gap: "16px",
  borderRadius: "8px",
  opacity: "0px",
  border: "1px solid #F8FAFC",
  backgroundColor: "#E2E8F0",
  display: "flex",
  justifyContent: "space-between",
  alignItems: "center",
  marginTop:'-17px',
  marginLeft:"-1px",
  [theme.breakpoints.down("sm")]: {
    width: "100%",
    height: "auto",
    padding: "8px",
  },
}));

const FileUploadedError = styled(Box)(({ theme }) => ({
  width: "311px",
  height: "94px",
  padding: "8px",
  gap: "16px",
  borderRadius: "8px",
  opacity: "0px",
  backgroundColor: "#E2E8F0",
  display: "flex",
  justifyContent: "space-between",
  alignItems: "center",
  marginTop:"-15px",
  [theme.breakpoints.down("sm")]: {
    width: "100%",
    height: "auto",
    padding: "8px",
  },
  "&.error": {
    border: "1px solid #DC2626", // Error border color
    backgroundColor: "#FEE2E2", // Error background color
  }
}));
const FileUploadedErrorNew = styled(Box)(({ theme }) => ({
  borderRadius: "8px",
  opacity: "0px",
  border: "1px solid #F87171",
  [theme.breakpoints.down("sm")]: {
    width: "100%",
    height: "auto",
    padding: "8px",
  },
}));

const FileNameTitleTypography = styled(Typography)(({ theme }) => ({
  fontFamily: "Plus Jakarta Sans",
  fontSize: "14px",
  fontWeight: 400,
  lineHeight: "18px",
  textAlign: "left",
  color: "#0F172A",
  [theme.breakpoints.down("sm")]: {
    fontSize: "12px",
  },
}));

const FilesizeTypography = styled(Typography)(({ theme }) => ({
  fontFamily: "Plus Jakarta Sans",
  fontSize: "12px",
  fontWeight: 400,
  lineHeight: "18px",
  textAlign: "left",
  color: "#64748B",
  [theme.breakpoints.down("sm")]: {
    fontSize: "10px",
  },
}));

const DownloadIconButton = styled(Button)(({ theme }) => ({
  width: "auto",
  height: "auto",
  gap: "6px",
  borderRadius: "8px",
  border: "1px solid",
  borderColor: "#64748B",
  padding: "0px",
}));

const DeleteIconButton = styled(Button)(({ theme }) => ({
  width: "auto",
  height: "auto",
  gap: "6px",
  borderRadius: "8px",
  border: "1px solid",
  borderColor: "#DC2626",
  padding: "0px",
}));

const ActionButtonsWrapper = styled(Box)(({ theme }) => ({
  display: "flex",
  justifyContent: "space-between",
  gap: "8px",
  [theme.breakpoints.down("sm")]: {
    flexDirection: "column",
    gap: "4px",
  },
}));

const FileUploadErrorText = styled(Typography)(({ theme }) => ({
  maxWidth:'246px',
  marginTop:'-15px',
  position:'absolute',
  fontFamily: "Plus Jakarta Sans",
  fontSize: "14px",
  fontWeight: 700,
  lineHeight: "18px",
  textAlign: "left",
  color: "#BB393D",
  padding: "5px",
  marginLeft:'-8px',
  width:"100%",
  [theme.breakpoints.down("sm")]: {
    fontSize: "12px",
    padding: "8px",
    width:"100%",
  },
}));

const FixedRequiredText = styled(Typography)(({ theme }) => ({
  fontFamily: "Inter",
  fontSize: "12px",
  fontWeight: 700,
  lineHeight: "18px",
  textAlign: "center",
  color: "#DC2626",
  padding:'5px',
  [theme.breakpoints.down("sm")]: {
    fontSize: "10px",
  },
}));

const TablePaper = styled(Paper)({
  width: "100%",
  overflowX: "auto",
  "& .table": {
    minWidth: 650,
  },
  "& .tableCellCentered": {
    display: "flex",
    alignItems: "center",
  },
  "& .borderedSpan":{
    border:"2px solid #ECECEE",
    whiteSpace: "nowrap",
    marginRight:"2px",
    marginLeft: "2px"
  },
  "& .statusActive": {
    background: "#CFFBE6",
    color: "#3EAA86",
    padding: "8px",
    borderRadius: "10px",
    textTransform:"uppercase",
    fontWeight:"bold",
    fontSize:"12px"
  },
  "& .statusInactive": {
    background: "#FFE1DF",
    color: "#DB5460",
    padding: "8px",
    borderRadius: "10px",
    textTransform:"uppercase",
    fontWeight:"bold",
    fontSize:"12px"
  },
  "& .MuiTableCell-head":{
    fontWeight:600
  },
  "& .MuiTableCell-root": {
    textAlign: "center",
    justifyContent: "center"
  },
  "& .tableCellExpanded": {
    background: "#F9FAFC",
  },
  "& .expandMore": {
    verticalAlign: "middle",
  },
});

const ExpandedBox = styled(Box)({
  display: "flex",
  gap: "20px",
  flexWrap: "wrap",
  padding: "0px 0px 20px 0px",
  borderBottom: "1px solid rgba(224, 224, 224, 1)",
  "& .expandedContent": {
    padding: "0 0 10px 0",
    background: "#F9FAFC",
    width:"300px"
  },
  "& .boxTop": {
    padding: "10px",
    display: "flex",
    gap: "20px",
    background: "#fff",
    alignItems: "center",
  },
  "& .topLeft": {
    display: "flex",
    flexDirection: "column",
    gap: "5px",
  },

  "& .appIdBox": {
    display: "flex",
    gap: "20px",
    alignItems: "center",
    "& div": {
      fontSize: "12px",
    },
  },

  "& .viewAppBtn": {
    border: "1px solid #10b981",
    color: "#10b981",
    height: "30px",
    fontWeight: "bold",
    textTransform: "none",
  },
  "& .statusInprogress": {
    backgroundColor: "#fef3c7",
    color: "#b45309",
    fontWeight: "500",
    fontSize: "0.875rem",
    padding: "2px 15px",
    borderRadius: "10px",
    textTransform: "uppercase",
  },
  "& .middleBox": {
    display: "flex",
    justifyContent: "space-between",
    alignItems: "center",
    padding: "10px",
    backgroundColor: "#F9FAFC",
  },
  "& .assignedTo": {
    fontSize: "12px",
  },
  "& .reasignBtn": {
    color: "#10b981",
    fontWeight: "bold",
  },
  "& .boxBottom": {
    padding: "0px 20px",
    textAlign:"start"
  },
  "& .boxBottomInner": {
    border: "1px solid #bbb",
    display: "flex",
    gap: "20px",
    padding: "0px 15px",
    borderRadius: "10px",
    backgroundColor: "#F9FAFC",
    lineHeight: 0.6,
  },
  "& .bottomLeft": {
    "& p":{
      lineHeight:1
    }
  },
  "& .bottomRight": {},
  "& p": {
    fontSize: "12px",
  },
  "& .textBold": {
    fontWeight: "bold",
    whiteSpace: "normal",
    wordBreak: 'break-word',     
    overflowWrap: 'anywhere',  
    lineHeight:1  
  },
});

const CustomizeRadio = styled(Radio)({
  '& .MuiSvgIcon-root:not(.MuiSvgIcon-root ~ .MuiSvgIcon-root)':
            {
                color: '#34D399',
                width:24,
                height:24
            },
        '& .MuiSvgIcon-root + .MuiSvgIcon-root': {
            color: '#34D399',
            width:24,
            height:24
        },
})

function RadioButton(props: RadioProps) {
  return (
    <CustomizeRadio
      disableRipple
      color="default"
      checkedIcon={<CheckCircle />}
      {...props}
    />
  );
}

const ReassignActions = styled(Box)({
  display: "flex",
  justifyContent: "space-between",
  padding: "10px 10px 0 10px",
  "& .reassignLeft": {
    display: "flex",
    alignItems: "center",
    gap: "10px",
  },
  "& .reassignToAllBtn": {
    background: "#000",
    color: "#fff",
    textTransform: "none",
    padding: "2px 10px",
    '&.Mui-disabled': {
    backgroundColor: 'lightgray',
    color: 'darkgray',
    cursor: 'not-allowed',
  },
  },
  "& .reassignRight": {
    display: "flex",
    alignItems: "center",
    gap: "10px",
  },
  "& .iconWarning": {
    color: "#CAB052",
    fontSize: "20px",
    paddingTop: "2px",
  },
  "& .reassignWarnText": {
    color: "#5D506F",
  },
  "& .deactivateBtn": {
    background: "#F1F4F9",
    color: "#717987",
    border: "1px solid #717987",
    padding: "2px 10px",
    textTransform: "none",
    '&.Mui-disabled': {
      backgroundColor: 'lightgray',
      color: 'darkgray',
      cursor: 'not-allowed',
    },
  },
});

const ProfileStatusBox = styled(Box)({
  "& p":{
    padding:"5px 10px",
    borderRadius:"15px",
    fontWeight:"bold"
  },
  "& .underReview":{
    background:"#F3F4F9",
    color:"#515B6A"
  },
"& .rejected":{
  background:"#FFE2E1",
  color:"#D33331"
},
"& .conditionallyApproved":{
  background:"#EBF8F9",
  color:"#0943CB"
},
"& .approved":{
  background:"#D1FAE2",
  color:"#08A676"
}
})
const IconManage = styled(Box)({
    // position:'absolute'
})

const BootstrapTooltip = styled(({ className, ...props }: TooltipProps) => (
  <Tooltip {...props} arrow classes={{ popper: className }} />
))(({ theme }) => ({
  [`& .${tooltipClasses.arrow}`]: {
    color: theme.palette.common.black,
  },
  [`& .${tooltipClasses.tooltip}`]: {
    backgroundColor: theme.palette.common.black,
    maxWidth: 750,
    borderRadius: "5px",
    fontSize: '14px',
    fontWeight: "700"
  },
}));

// Aggreement screen ends
// Customizable Area End