// Customizable Area Start
import { BlockComponent } from "framework/src/BlockComponent";
import { IBlock } from "framework/src/IBlock";
import { runEngine } from "framework/src/RunEngine";
import { Message } from "framework/src/Message";
import MessageEnum, {
  getName,
} from "framework/src/Messages/MessageEnum";
import { getStorageData, setStorageData } from "framework/src/Utilities";
import { createRef } from "react";
// Customizable Area End

export interface Props {
  navigation: any;
  // Customizable Area Start
  isAdmin?: boolean;
  // Customizable Area End
}

interface S {
  // Customizable Area Start
  password: string;
  confirmPassword: string;
  isPasswordShow: boolean;
  isConfirmPasswordShow: boolean;
  loading: boolean;
  token: null | string;
  toggleDrawer: boolean;
  userToken: string,
  CardDetailsData: Array<{
    id: string | number,
    heading: string,
    AnnouncementType: string,
    ContryName: Array<string>
    points: string,
    imageUrl: string,
    PublishDate: string,
  }>,
  page: number,
  userDetails: {
    attributes: {
      first_name: string;
      role: string;
      image: {
        url: string;
      },
    },
  },
  valueKey: string;
  updateModalOpen: boolean,
  updateValue: string,
  startDate: null | Date,
  endDate: null | Date,
  formattedStartDate: string,
  formattedEndDate: string,
  HideFirstCell: boolean,
  ExpendProgress: number,
  FilterModelOpen: boolean,
  ApplicationTableData: Array<ApplicationTableDataType>,
  AllData: Array<ApplicationTableDataType>,
  FilterObject: {
    Student: string,
    Associatedbranch: string,
    CreatedBy: string,
    University: string,
    Program: string
  },
  currentPage: number,
  itemsPerPage: number,
  StatusDropDown: Array<string>,
  StatusDropDownValue: string,
  StageDropDown: Array<string>,
  StageDropDownValue: string,
  StudentFilterDropDownData: Array<StudentDropDownData>,
  UniversityFilterDropDownData: Array<string>,
  ProgramFilterDropDownData: Array<string>,
  CreatedByFilterDropDownData: Array<Agent>,
  AssociateBranchNameFilterDropDownData: Array<string>,
  StudentDropDownValue: Array<StudentDropDownData>,
  AssociateBranchValue: Array<string>,
  CreatedByDropDownValue: Array<Agent>,
  UniversityByDropDownValue: Array<string>,
  ProgramDropDownValue: Array<string>,
  FilterCount: number,
  orderBy: string;
  order: "asc" | "desc";
  // Customizable Area End
}

interface SS {
  // Customizable Area Start
  navigation: string;
  // Customizable Area End
}
// Customizable Area Start
export interface StudentDropDownData {
  student_name: string;
  student_id: string;
  passport_number: string;
}

export interface Agent {
  agent_name: string;
  agent_id: string;
}

interface FilterDropDownDataTYpe {
  students: StudentDropDownData[];
  associate_branch_name: string[];
  created_by: Agent[];
  university: string[];
  program: string[];
}

interface ApplicationTableDataType {
  id: string,
  type: string,
  RednerIndex: string,
  AgentBranchName: string,
  agent_email: string,
  application_id: string,
  application_status: string,
  applied_by: string,
  course: string,
  current_stage: string,
  fees: string,
  generated_student_id: string,
  start_date: string,
  student_name: string,
  discontinued_reason: string,
  university_id: string,
  university_name: string,
  updated_at: string,
  create_by: string,
  ApplicationStageForInprogress: ApplicationStageForInprogress;
  ApplicationStageForRefund: ApplicationStageForRefund;
}

interface UserDetailsResponse {
  data: UserDetails;
  meta: {
    message: string;
  };
  error?: string[];
  success: string[]
}

export type ApplicationStageForInprogress = {
  application_fees_paid?: boolean;
  application_received?: boolean;
  submitted_to_institution?: boolean;
  conditional_offer?: boolean;
  unconditional_offer?: boolean;
  tuition_fees_paid?: boolean;
  pre_cas_loa_stage?: boolean;
  cas_loa_applied?: boolean;
  cas_loa_received?: boolean;
  visa_applied?: boolean;
  visa_received?: boolean;
};

interface ApplicationTableDataType {
  id: string,
  type: string,
  AgentBranchName: string,
  agent_email: string,
  application_id: string,
  application_status: string,
  applied_by: string,
  course: string,
  current_stage: string,
  fees: string,
  generated_student_id: string,
  start_date: string,
  student_name: string,
  university_id: string,
  university_name: string,
  updated_at: string,
  create_by: string,
  ApplicationStageForInprogress: ApplicationStageForInprogress;
  ApplicationStageForRefund: ApplicationStageForRefund;
}

export type ApplicationStageForRefund = {
  refund_initiated?: boolean;
  refund_submitted_to_institution?: boolean;
  refund_received?: boolean;
  refund_rejected?: boolean;
};

interface UserDetails {
  attributes: {
    first_name: string;
    role: string;
    image: {
      url: string;
    };
  };
}

interface APIPayloadType {
  body?: object;
  contentType?: string;
  method: string;
  endPoint: string;
  token?: string;
  type?: string;
}

export const configJSON = require("./config");

// Define the type for application stages
type ApplicationStage = {
  application_fees_paid?: boolean;
  application_received?: boolean;
  submitted_to_institution?: boolean;
  conditional_offer?: boolean;
  unconditional_offer?: boolean;
  tuition_fees_paid?: boolean;
  pre_cas_loa_stage?: boolean;
  cas_loa_applied?: boolean;
  cas_loa_received?: boolean;
  visa_applied?: boolean;
  visa_received?: boolean;
  refund_initiated?: boolean;
  refund_submitted_to_institution?: boolean;
  refund_received?: boolean;
  refund_rejected?: boolean;
};

// Define the main attributes type
type ApplicationAttributes = {
  application_id: string;
  application_status: "in_progress" | "enrolled" | "refund" | "discontinued";
  generated_student_id: string;
  application_stage: ApplicationStage;
  current_stage: string;
  student_name: string;
  course: string;
  university_name: string;
  university_id: number;
  agent_email: string;
  applied_by: string;
  start_date: string;
  updated_at: string;
  agent_branch_name: string;
  fees: string;
  discontinued_reason: string;
};

// Define the main application type
type Application = {
  id: string;
  type: "view_application";
  attributes: ApplicationAttributes;
};

// Define the data type that contains an array of applications
type ApplicationData = {
  data: Application[];
};

// Customizable Area End

export default class AllAplicationController extends BlockComponent<
  Props,
  S,
  SS
> {
  // Customizable Area Start
  getProfileAPICallId: string = "";
  GetAllApplicationsListApiCallId: string = "";
  filterLatestUpdatedByDateApiId: string = "";
  TableRef: React.RefObject<HTMLDivElement>;

  GetFilterStatusForDropDown: string = "";
  GetFilterStageForDropDown: string = "";
  ProcessFilterApiCallId: string = "";
  GetApplicationFilterData: string = "";
  ModelApplicationFilterData: string = "";
  // Customizable Area End

  constructor(props: Props) {
    super(props);
    this.subScribedMessages = [
      // Customizable Area Start
      getName(MessageEnum.RestAPIResponceMessage),
      getName(MessageEnum.NavigationPayLoadMessage),
      getName(MessageEnum.CountryCodeMessage),
      // Customizable Area End
    ];

    this.receive = this.receive.bind(this);
    runEngine.attachBuildingBlock(this as IBlock, this.subScribedMessages);
    this.TableRef = createRef<HTMLDivElement>();

    // Customizable Area Start
    this.state = {
      password: "",
      confirmPassword: "",
      isPasswordShow: false,
      isConfirmPasswordShow: false,
      loading: false,
      token: null,
      toggleDrawer: false,
      userToken: '',
      userDetails: {
        attributes: {
          first_name: '',
          role: '',
          image: {
            url: '',
          },
        },
      },
      CardDetailsData: [],
      page: 1,
      valueKey: '',
      updateModalOpen: false,
      updateValue: '',
      startDate: null,
      endDate: null,
      formattedStartDate: "",
      formattedEndDate: "",
      HideFirstCell: false,
      ExpendProgress: -1,
      FilterModelOpen: false,
      ApplicationTableData: [],
      AllData: [],
      FilterObject: {
        Student: "",
        Associatedbranch: "",
        CreatedBy: "",
        University: "",
        Program: ""
      },
      currentPage: 0,
      itemsPerPage: 10,
      StatusDropDown: [],
      StatusDropDownValue: "",
      StageDropDown: [],
      StageDropDownValue: "",
      StudentFilterDropDownData: [],
      UniversityFilterDropDownData: [],
      ProgramFilterDropDownData: [],
      CreatedByFilterDropDownData: [],
      AssociateBranchNameFilterDropDownData: [],
      StudentDropDownValue: [],
      AssociateBranchValue: [],
      CreatedByDropDownValue: [],
      UniversityByDropDownValue: [],
      ProgramDropDownValue: [],
      FilterCount: 0,
      orderBy: "",
      order: 'asc',
    };

    // Customizable Area End
  }

  async receive(from: string, message: Message) {
    // Customizable Area Start
    if (getName(MessageEnum.RestAPIResponceMessage) === message.id) {
      const apiRequestCallId = message.getData(
        getName(MessageEnum.RestAPIResponceDataMessage)
      );
      const responseJson = message.getData(
        getName(MessageEnum.RestAPIResponceSuccessMessage)
      );
      if (apiRequestCallId && responseJson) {
        if (apiRequestCallId === this.getProfileAPICallId) {
          this.getProfileFormSuccessCallBack(responseJson)
        }
        this.ApplicationsListResponse(apiRequestCallId, responseJson)
        this.GetFilterStatusForDropDownApiCallResponse(apiRequestCallId, responseJson)
        this.GetFilterStageForDropDownApiCallResponse(apiRequestCallId, responseJson)
        this.ProcessFilterApiResponseSet(apiRequestCallId, responseJson)
        this.ApplicationDropDataApiResponseSet(apiRequestCallId, responseJson)
        this.ApplicationFilterDataApiResponseSet(apiRequestCallId, responseJson)
      }
    }
    // Customizable Area End
  }

  // Customizable Area Start
  async componentDidMount() {
    const token = await getStorageData("token")
    this.setState({ userToken: token }, () => {
      this.getFormApprovalProfile()
      this.GetAllApplicationsApiCallFuncation()
      this.GetFilterStatusApiCallFuncation()
      this.AplicationDropDownData()
    })
  }

  ApplicationDataMap = (responseJson: ApplicationData) => {
    // AttributesData.application_status
    const ApplicationData = responseJson.data.map((data, index) => {
      console.log(data.attributes.discontinued_reason, "ApplicationData");

      const AttributesData = data.attributes
      const ApplicationStageInnerData = AttributesData?.application_stage
      return {
        id: data.id,
        RednerIndex: `${index + 1}`,
        type: data.type,
        AgentBranchName: AttributesData.agent_branch_name,
        agent_email: AttributesData.agent_email,
        application_id: AttributesData.application_id,
        application_status: AttributesData.application_status,
        applied_by: AttributesData.applied_by,
        course: AttributesData.course,
        current_stage: AttributesData.current_stage,
        fees: AttributesData.fees,
        generated_student_id: AttributesData.generated_student_id,
        start_date: AttributesData.start_date,
        student_name: AttributesData.student_name,
        university_id: `${AttributesData.university_id}`,
        university_name: AttributesData.university_name,
        updated_at: AttributesData.updated_at,
        discontinued_reason: data.attributes.discontinued_reason,
        ApplicationStageForInprogress: {
          application_fees_paid: ApplicationStageInnerData?.application_fees_paid,
          application_received: ApplicationStageInnerData?.application_received,
          cas_loa_applied: ApplicationStageInnerData?.cas_loa_applied,
          cas_loa_received: ApplicationStageInnerData?.cas_loa_received,
          conditional_offer: ApplicationStageInnerData?.conditional_offer,
          pre_cas_loa_stage: ApplicationStageInnerData?.pre_cas_loa_stage,
          submitted_to_institution: ApplicationStageInnerData?.submitted_to_institution,
          tuition_fees_paid: ApplicationStageInnerData?.tuition_fees_paid,
          unconditional_offer: ApplicationStageInnerData?.unconditional_offer,
          visa_applied: ApplicationStageInnerData?.visa_applied,
          visa_received: ApplicationStageInnerData?.visa_received,
        },
        ApplicationStageForRefund: {
          refund_initiated: ApplicationStageInnerData.refund_initiated,
          refund_received: ApplicationStageInnerData.refund_received,
          refund_rejected: ApplicationStageInnerData.refund_rejected,
          refund_submitted_to_institution: ApplicationStageInnerData.refund_submitted_to_institution
        },
        create_by: AttributesData.applied_by
      }
    })
    this.setState({
      ApplicationTableData: ApplicationData.slice(this.state.currentPage, this.state.itemsPerPage),
      AllData: ApplicationData
    })
  }

  ApplicationsListResponse = (apiRequestCallId: string, responseJson: ApplicationData) => {
    if (apiRequestCallId === this.GetAllApplicationsListApiCallId) {
      this.ApplicationDataMap(responseJson)
    }
  }

  SuccessStperCount = (data: ApplicationStageForInprogress) => {
    let SuccessCount = -1; // Start with an initial value of -1
    const {
      application_fees_paid,
      application_received,
      cas_loa_applied,
      conditional_offer,
      pre_cas_loa_stage,
      submitted_to_institution,
      tuition_fees_paid,
      unconditional_offer,
      visa_applied,
      visa_received,
      cas_loa_received
    } = data;

    if (application_fees_paid) {
      SuccessCount += 1;
    }
    if (application_received) {
      SuccessCount += 1;
    }
    if (cas_loa_applied) {
      SuccessCount += 1;
    }
    if (cas_loa_received) {
      SuccessCount += 1;
    }
    if (conditional_offer) {
      SuccessCount += 1;
    }
    if (pre_cas_loa_stage) {
      SuccessCount += 1;
    }
    if (submitted_to_institution) {
      SuccessCount += 1;
    }
    if (tuition_fees_paid) {
      SuccessCount += 1;
    }
    if (unconditional_offer) {
      SuccessCount += 1;
    }
    if (visa_applied) {
      SuccessCount += 1;
    }
    if (visa_received) {
      SuccessCount += 1;
    }

    return SuccessCount;

  }

  RefundStperCount = (data: {
    refund_initiated?: boolean,
    refund_received?: boolean,
    refund_rejected?: boolean,
    refund_submitted_to_institution?: boolean
  }) => {
    let RefundCount = -1;
    const { refund_initiated, refund_received, refund_rejected, refund_submitted_to_institution } = data
    if (refund_initiated) {
      RefundCount += 1
    }
    if (refund_submitted_to_institution) {
      RefundCount += 1
    }
    if (refund_received) {
      RefundCount += 1
    }
    if (refund_rejected) {
      RefundCount += 1
    }
    return RefundCount
  }



  handleToggle = () => {
    this.setState({
      toggleDrawer: !this.state.toggleDrawer
    });
  };

  onHomeClick = (pageName: string) => {
    setStorageData("LandingPageActive", pageName)
    const navigation = new Message(getName(MessageEnum.NavigationMessage));
    navigation.addData(
      getName(MessageEnum.NavigationTargetMessage),
      "LandingPage"
    );
    navigation.addData(getName(MessageEnum.NavigationPropsMessage), this.props);
    this.send(navigation);
  };

  ApplicationApiCall = async (apiData: APIPayloadType) => {
    const { contentType, method, body, type, endPoint } = apiData;
    let token = await getStorageData("token")
    const header = {
      "Content-Type": contentType,
      token: token
    };
    const requestMessageApplicationApiCall = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );
    requestMessageApplicationApiCall.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      endPoint
    );
    requestMessageApplicationApiCall.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );

    requestMessageApplicationApiCall.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      method
    );

    body && type !== "formData"
      ? requestMessageApplicationApiCall.addData(
        getName(MessageEnum.RestAPIRequestBodyMessage),
        JSON.stringify(body)
      )
      : requestMessageApplicationApiCall.addData(
        getName(MessageEnum.RestAPIRequestBodyMessage),
        body
      );

    runEngine.sendMessage(requestMessageApplicationApiCall.id, requestMessageApplicationApiCall);

    return requestMessageApplicationApiCall.messageId;
  };


  getProfileFormSuccessCallBack = (responseJson: UserDetailsResponse) => {
    this.setState({ userDetails: responseJson.data });
  };

  getFormApprovalProfile = async () => {
    let userDetails = await getStorageData("userDetails")
    this.getProfileAPICallId = await this.ApplicationApiCall({
      contentType: "application/json",
      method: "GET",
      endPoint: configJSON.getProfileEndPoint + userDetails
    });
  }

  GetAllApplicationsApiCallFuncation = async () => {
    let token = await getStorageData("token")
    this.GetAllApplicationsListApiCallId = await this.ApplicationApiCall({
      contentType: "application/json",
      method: "GET",
      endPoint: `/bx_block_leadmanagement/forms/all_applications?token=${token}`
    });
  }

  navigationToAnyPage = (pageName: string) => {
    const navigation = new Message(getName(MessageEnum.NavigationMessage));
    navigation.addData(
      getName(MessageEnum.NavigationTargetMessage),
      pageName
    );
    navigation.addData(getName(MessageEnum.NavigationPropsMessage), this.props);
    this.send(navigation);
  };

  StperData = () => {
    let data = ['Application Fees Paid', 'Application', 'Submitted to Institution', 'Conditional Offer', 'Unconditional Offer', 'Tuition Fees Paid', 'Pre-CAS/LOA Stage', 'CAS/LOA Applied', 'CAS/LOA Received', 'Visa Applied', "Visa Received"]
    return data
  }

  SecondStperData = () => {
    let data = [
      "Refund initiated",
      "Refund submitted to institution",
    ]
    return data
  }

  handleScroll = () => {
    if (this.TableRef.current) {
      const { scrollLeft } = this.TableRef.current;
      if (scrollLeft > 100) {
        if (!this.state.HideFirstCell) {
          this.setState({
            HideFirstCell: true
          })
        }
      }
      if (scrollLeft < 100) {
        this.setState({
          HideFirstCell: false
        })
      }
    }
  };

  FilterOpen = () => {
    this.setState({
      FilterModelOpen: !this.state.FilterModelOpen
    })
  }

  // Filter start

  GetFilterStatusApiCallFuncation = async () => {
    this.GetFilterStatusForDropDown = await this.ApplicationApiCall({
      contentType: "application/json",
      method: "GET",
      endPoint: `/bx_block_leadmanagement/forms/get_status_list`
    });
  }

  GetFilterStatusForDropDownApiCallResponse = (apiRequestCallId: string, responseJson: { data: Array<string> }) => {
    if (apiRequestCallId === this.GetFilterStatusForDropDown) {
      this.setState({
        StatusDropDown: responseJson.data
      })
    }
  }

  HandleStatusChange = (event: React.ChangeEvent<{
    name?: string;
    value: unknown;
  }>) => {
    this.setState({
      StatusDropDownValue: event.target.value as string,
      StageDropDownValue: ""
    }, () => {
      this.GetFilterStageApiCallFuncation(this.state.StatusDropDownValue)
      this.GetProcessApiCallFuncation()
    })
  }

  GetFilterStageApiCallFuncation = async (Stage: string) => {
    this.GetFilterStageForDropDown = await this.ApplicationApiCall({
      contentType: "application/json",
      method: "GET",
      endPoint: `/bx_block_leadmanagement/forms/get_stage_list?&status=${Stage}`
    });
  }

  GetFilterStageForDropDownApiCallResponse = (apiRequestCallId: string, responseJson: { data: Array<string> }) => {
    if (apiRequestCallId === this.GetFilterStageForDropDown) {
      this.setState({
        StageDropDown: responseJson.data
      })
    }
  }

  HandleStageChange = (event: React.ChangeEvent<{
    name?: string;
    value: unknown;
  }>) => {
    this.setState({
      StageDropDownValue: event.target.value as string
    }, () => {
      this.GetProcessApiCallFuncation()
    })
  }

  GetProcessApiCallFuncation = async () => {
    this.ProcessFilterApiCallId = await this.ApplicationApiCall({
      contentType: "application/json",
      method: "GET",
      endPoint: `/bx_block_leadmanagement/forms/process_filter?status=${this.state.StatusDropDownValue}&stage=${this.state.StageDropDownValue}`
    });
  }
  handelPropsChange = (e: unknown[]) => {
    if (e.length !== 0) {
      this.setState({
        ApplicationTableData: e as Array<{
          id: string,
          RednerIndex: string,
          type: string,
          AgentBranchName: string,
          agent_email: string,
          application_id: string,
          application_status: string,
          applied_by: string,
          course: string,
          current_stage: string,
          fees: string,
          generated_student_id: string,
          start_date: string,
          student_name: string,
          university_id: string,
          university_name: string,
          updated_at: string,
          create_by: string,
          discontinued_reason: string,
          ApplicationStageForInprogress: ApplicationStageForInprogress;
          ApplicationStageForRefund: ApplicationStageForRefund;
        }>
      })
    }
  }

  NaviGateToViewApplication = (id: string, AppId: string) => {
    this.navigationToAnyPage('ViewApplication')
    setStorageData("ViewAppData", JSON.stringify({ id, AppId }))
  }

  ProcessFilterApiResponseSet = (apiRequestCallId: string, responseJson: ApplicationData) => {
    if (apiRequestCallId === this.ProcessFilterApiCallId) {
      this.ApplicationDataMap(responseJson)
    }
  }

  AplicationDropDownData = async () => {
    this.GetApplicationFilterData = await this.ApplicationApiCall({
      contentType: "application/json",
      method: "GET",
      endPoint: `/bx_block_leadmanagement/forms/set_application_filter_data`
    });
  }

  ApplicationDropDataApiResponseSet = (apiRequestCallId: string, responseJson: FilterDropDownDataTYpe) => {
    if (apiRequestCallId === this.GetApplicationFilterData) {
      this.setState({
        StudentFilterDropDownData: responseJson.students,
        CreatedByFilterDropDownData: responseJson.created_by,
        UniversityFilterDropDownData: responseJson.university,
        ProgramFilterDropDownData: responseJson.program,
        AssociateBranchNameFilterDropDownData: responseJson.associate_branch_name,
      })
    }
  }

  StudentDropDownDataChange = (Value: Array<StudentDropDownData>) => {
    this.setState({
      StudentDropDownValue: Value
    })
  };

  DelteStundeDropDown = (value: string) => {
    const StudentDropDownValue = this.state.StudentDropDownValue.filter((item) => item.student_id !== value)
    this.setState({
      StudentDropDownValue: StudentDropDownValue
    })
  }

  AssociatedBranchDataChange = (Value: Array<string>) => {
    this.setState({
      AssociateBranchValue: Value
    })
  }

  DelteAssociatedBranch = (value: string) => {
    const AssociateBranchValue = this.state.AssociateBranchValue.filter((item) => item !== value)
    this.setState({
      AssociateBranchValue: AssociateBranchValue
    })
  }

  CreatedByDropDownDataChange = (Value: Array<Agent>) => {
    this.setState({
      CreatedByDropDownValue: Value
    })
  }
  DelteCreatedBy = (value: string) => {
    const CreatedByDropDownValue = this.state.CreatedByDropDownValue.filter((item) => item.agent_id !== value)
    this.setState({
      CreatedByDropDownValue: CreatedByDropDownValue
    })
  }

  UniversityDropDownDataChange = (Value: Array<string>) => {
    this.setState({
      UniversityByDropDownValue: Value
    })
  }
  DelteUniversity = (value: string) => {
    const UniversityByDropDownValue = this.state.UniversityByDropDownValue.filter((item) => item !== value)
    this.setState({
      UniversityByDropDownValue: UniversityByDropDownValue
    })
  }

  ProgramDropDownDataChange = (Value: Array<string>) => {
    this.setState({
      ProgramDropDownValue: Value
    })
  }

  DelteProgram = (value: string) => {
    const ProgramDropDownValue = this.state.ProgramDropDownValue.filter((item) => item !== value)
    this.setState({
      ProgramDropDownValue: ProgramDropDownValue
    })
  }

  ResetData = () => {
    this.setState({
      StudentDropDownValue: [],
      AssociateBranchValue: [],
      CreatedByDropDownValue: [],
      UniversityByDropDownValue: [],
      ProgramDropDownValue: [],
      FilterCount: 0
    })
  }

  FilterModelApiCall = async () => {
    const {
      StudentDropDownValue,
      AssociateBranchValue,
      CreatedByDropDownValue,
      UniversityByDropDownValue,
      ProgramDropDownValue
    } = this.state

    const StudentDropDownValueString = StudentDropDownValue.map((item) => item.student_id);
    const AssociateBranchValueString = AssociateBranchValue.map((item) => item);
    const CreatedValueString = CreatedByDropDownValue.map((item) => item.agent_id);
    const UniversityByDropDownValueString = UniversityByDropDownValue.map((item) => item);
    const ProgramDropDownValueString = ProgramDropDownValue.map((item) => item);
    let Count = 0;

    const APiEndPoint = `/bx_block_leadmanagement/forms/application_filter?student_id=${encodeURIComponent(JSON.stringify(StudentDropDownValueString))}&associate_branch=${encodeURIComponent(JSON.stringify(AssociateBranchValueString))}&agent_id=${encodeURIComponent(JSON.stringify(CreatedValueString))}&university_name=${encodeURIComponent(JSON.stringify(UniversityByDropDownValueString))}&program_name=${encodeURIComponent(JSON.stringify(ProgramDropDownValueString))}`
    if (StudentDropDownValue.length > 0) {
      Count = Count + 1;
    }
    if (AssociateBranchValue.length > 0) {
      Count = Count + 1;
    }
    if (CreatedByDropDownValue.length > 0) {
      Count = Count + 1;
    }
    if (UniversityByDropDownValue.length > 0) {
      Count = Count + 1;
    }
    if (ProgramDropDownValue.length > 0) {
      Count = Count + 1;
    }

    this.setState({
      FilterCount: Count
    })

    this.ModelApplicationFilterData = await this.ApplicationApiCall({
      contentType: "application/json",
      method: "GET",
      endPoint: APiEndPoint
    });
  }

  ApplicationFilterDataApiResponseSet = (apiRequestCallId: string, responseJson: ApplicationData) => {
    if (apiRequestCallId === this.ModelApplicationFilterData) {
      this.ApplicationDataMap(responseJson);
      this.setState({
        FilterModelOpen: false
      })
    }
  }

  sortData = (data: Array<ApplicationTableDataType>, orderBy: string, order: string) => {
    return data.sort((sortingA, sortingB) => {
      const aValue = sortingA[orderBy as keyof ApplicationTableDataType];
      const bValue = sortingB[orderBy as keyof ApplicationTableDataType];
      if (typeof aValue === "string" && typeof bValue === "string") {
        return order === "asc" ? aValue.localeCompare(bValue) : bValue.localeCompare(aValue);
      }
      return 0;
    });
  }

  handleRequestSort = (property: string) => {
    const orderBy = property;
    let order: "asc" | "desc" = "asc";
    if (this.state.orderBy === property && this.state.order === "asc") {
      order = "desc";
    }
    this.setState({ orderBy, order });
  };
  // Customizable Area End
}