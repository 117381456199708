export const cakeIcon = require("../assets/cake.svg");
export const locationIcon = require("../assets/location.svg");
export const addIcon = require("../assets/addIcon.svg");
export const addIconWhite = require("../assets/addIcon-white.svg");
export const infoIcon = require("../assets/infoIcon.svg");
export const closeIcon = require("../assets/close-icon.svg");
export const tickIcon = require("../assets/tick-icon.svg");
export const tipsIcon = require("../assets/tips.svg");
export const universityLocation = require("../assets/locationone.svg")
export const fileicon = require("../assets/fileicon.svg")
export const downLoadIcon = require("../assets/downloadIcon.svg")