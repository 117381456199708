Object.defineProperty(exports, "__esModule", {
  value: true
});

// Customizable Area Start
exports.httpGetMethod = "GET";
exports.httpPostMethod = "POST";
exports.httpPutMethod = "PUT";
exports.searchApiContentType = "application/json";
exports.getSearchApiEndPoint = "search/users";
exports.errorTitle = "Error";
exports.getCustomizableProfileEndPoint = "bx_block_profile/profile/current_user_profile";
exports.updateCustomizableProfileEndPoint = "bx_block_profile/profile/update_user_profile";
exports.getCustomizableProfileFieldsEndPoint =
  "bx_block_profile/profile/custom_user_profile_fields";
exports.viewProfileTxt = "View Profile";
exports.personalTxt = "Personal Info";
exports.startTxt = "*";
exports.memberTxt = "Member Name";
exports.memberEmailTxt = "Member Email";
exports.mobileTxt = "Mobile";
exports.birthdayTxt = "Birthday";
exports.genderMale = "Male";
exports.genderFemale = "Female";
exports.genderOther = "Other";
exports.select = "Select";
exports.maritalSingle = "Single";
exports.maritalMarried = "Married";
exports.maritalDivored = "Divored";
exports.maritalStatusTxt = "Marital Status";
exports.countryTxt = "Country";
exports.stateTxt = "State";
exports.cityTxt = "City";
exports.saveBtnTxt = "Save Changes";
exports.securityTxt = "Security";
exports.currentPswrdTxt = " Current Password";
exports.newPswrdTxt = "New Password";
exports.genderTxt = "Gender";
exports.confirmPswrdTxt = "Confirm New Password";
exports.pswrdBtnTxt = "Update Password";
exports.emailTxt = "Email Preferences";
exports.tableHeader1 = "ACTION";
exports.tableHeader2 = "DESCRIPTION";
exports.tableHeader3 = "IP ADDRESS";
exports.tableHeader4 = "DATE";
exports.tableHeaderStaff1 = "S.No";
exports.tableHeaderStaff2 = "First name";
exports.tableHeaderStaff3 = "Last name";
exports.tableHeaderStaff4 = "Email";
exports.tableHeaderStaff5 = "Mobile";
exports.tableHeaderStaff6 = "Status";
exports.commigSoonTxt = "Coming Soon!";
exports.headingTxt = "Vision International Educational Consultants Ltd";
exports.userNameTxt = "Dinesh"
exports.userLastLogin = "Your Last Login :"
exports.roleTxt = "Role:";
exports.activityTxt = "Activity";
exports.bankNameHeading = "Add New Bank Account";
exports.accountHolderName = "Account Holder Name";
exports.accountName = "Account Number";
exports.bankNameTxt = "Bank Name";
exports.bankAdrsTxt = "Bank Address";
exports.swiftCodeTxt = "Swift Code";
exports.cancleBtnTxt = "Cancel";
exports.addBtnTxt = "Add";
exports.ibanTxt = "IBAN / IFSC";
exports.createTxt = "Created Invoices ";
exports.paidInvoiceTxt = "Paid Invoices";
exports.valueTxt = "";
exports.primaryBtnTxt = "Make it primary";
exports.addBankBtnTxt = "Add New Bank Account";
exports.currentPswrdReq = "Current password is required.";
exports.currentPswrdValid = "Password should be 8-15 characters long and contain at least one uppercase letter, one lowercase letter, one digit, and one special character.";
exports.newPswrdReq = "New password is required.";
exports.newPswrdValid = "Password should be 8-15 characters long and contain at least one uppercase letter, one lowercase letter, one digit, and one special character.";
exports.newPswrdNotSame = "New password should not be the same as the current password.";
exports.confrimPswrdReq = "Confirm password is required.";
exports.confrimPswrdValid = "Password should be 8-15 characters long and contain at least one uppercase letter, one lowercase letter, one digit, and one special character.";
exports.confrimPswrdNotSame = "New password and confirm password do not match"; exports.confrimPswrdNotSame = "New password and confirm password do not match";
exports.accountNameReq = "Please provide the account holder name.";
exports.accountNumberReq = "Please provide the account number.";
exports.bankNameReq = "Please provide the bank name.";
exports.bankAdrsReq = "Please provide the bank address.";
exports.ibanReq = "Please provide the IBAN.";
exports.shiftReq = "Please provide the swift code.";
exports.namePlaceHolderTxt = "Enter name";
exports.starTxt = "*";
exports.continueBtnTxt = "Continue";
exports.nameTxt = "Name";
exports.namePlaceHolderTxt = "Enter name";
exports.emailTxt = "Email";
exports.emailPlaceHolderTxt = "Enter email";
exports.designationTxt = "Designation";
exports.designationPlaceHolderTxt = "Enter designation";
exports.mobilenumberTxt = "Mobile number";
exports.mobilenumberPlaceHolderTxt = "Enter Mobile number";
exports.staffTxt = "";
exports.agreementNameError = "Please enter the agreement holder's name.";
exports.agreementEmailError = "Please enter the agreement holder's email address.";
exports.validEmailTxt = "Please enter a valid email address.";
exports.documentValid = "Accepted file type: .jpg .jpeg .pdf .doc";
exports.viewProfileTxt = "View Profile";
exports.memberNamePrompt = "Enter full name";
exports.memberEmailPrompt = "Enter email";
exports.memberMobilePrompt = "Enter mobile number";
exports.memberGenderPrompt = "Select gender";
exports.memberMaritalStatusPrompt = "Select marital status";
exports.memberCountryPrompt = "Select country";
exports.memberStatePrompt = "Select state";
exports.currentPasswordPrompt = "Enter current password";
exports.newPasswordPrompt = "Enter new password";
exports.confirmPasswordPrompt = "Confirm new password";
exports.changePswrdContentType = "application/json";
exports.changePswrdApimethod = "POST";
exports.changePswrdEndPoint = "/account_block/accounts/change_password";
exports.apiPassTxt = "API Pass";
exports.apiFailTxt = "API Fail";
exports.submitProfileContentType = "application/json";
exports.submitProfileApimethod = "PUT";
exports.submitProfileEndPoint = "/account_block/accounts/";
exports.bankDetailsContentType = "application/json";
exports.bankDetailsApimethod = "POST";
exports.bankDetailsEndPoint = "/account_block/bank_informations/";
exports.staffContentType = "application/json";
exports.staffApimethod = "GET";
exports.staffEndPoint = "/account_block/manage_staffs/";
exports.regaxText = /^[A-Za-z ]*$/;
exports.getBankEndPoint = "/account_block/bank_informations/";
exports.headingTxt = "Activity Log";
exports.bankdetailsType = "formData";
exports.getProfileEndPoint = "/account_block/accounts/";
exports.userMemberTxt = "Member Since: ";
exports.teamSizeTxt = "Team Size: ";
exports.contactPersonTxt = "Contact Person: ";
exports.genderValue = "none";
exports.birthdayValue = "Select birthday";
exports.makePrimaryEndPoint = "/account_block/bank_informations/";
exports.alreadyPrimaryBtnTxt = "Already a primary account";
exports.addDocumentEndpoint = "bx_block_documentation/documents/add_documents";
exports.addBusinessEndpoint = "bx_block_documentation/documents/add_business_registration_certificate";
exports.addDocumentTxt = "Add Document";
exports.getAllDocumentEndPoint = "/bx_block_documentation/documents/get_documents";
exports.addPersonalEndpoint = "bx_block_documentation/documents/add_personal_id";
exports.documentAlertTxt = "Only JPG, JPEG, PDF, and DOC files are allowed.";
exports.personalIdTxt = "Personal ID";
exports.businessTxtId = "Business Registration Certificate";
exports.addDocumentTxt = "Add Documents";
exports.addPersonalEndpoint = "bx_block_documentation/documents/add_personal_id";
exports.deleteMethodType = "DELETE";
exports.deleteEndpoint = "bx_block_documentation/documents/";
exports.shiftCodePlaceHolder = "Enter swift code";
exports.postProfileEndPoint = "/account_block/accounts/profile_picture";
exports.accountNumberLengthReq = "Account number must be 9 to 18 characters long.";
exports.accountNumberRegex = /^\d{9,18}$/;
exports.emailRegex = /^[a-zA-Z0-9._-]+@[a-zA-Z0-9-]+(?:\.[a-zA-Z0-9-]+)*\.[a-zA-Z]{2,4}$/;
exports.bankInformationTxt = "Bank Information";
exports.documentsnTxt = "Documents";
exports.agreementTxt = "Agreement";
exports.staffTxt = "Manage Staff";
exports.limitValidation = "File size must be less than or equal to 10 MB.";
exports.error = "error";
exports.valueProgressBar = "14%";
exports.sendReviewBtn = "Send for Review";
exports.marriageTxt = "Marriage anniversary";
exports.accountTxt = "Account";
exports.cpText = "Cp profile";
exports.passwordRegex =  /^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)(?=.*[!@#$%^&*()_+={}\[\]:;"'<>,.?/\\]).{8,15}$/;
exports.notificationsTxt ="Notification Preferences";
exports.emailNotification="Email notifications";
exports.applicationsStage ="Change on application stage";
exports.applicationBulk ="Bulk university updates";
exports.conversationMsg='Conversation message';
exports.newApplicationRequest='New application requirement';
exports.whatsappComingSoonTitle = "Coming soon!";
exports.whatsappComingSoonDesc = "Whatsapp notifications will be available soon.";
exports.conversationMsg = "Conversation message";
exports.newApplicationRequest = "New application requirement";
exports.mobileReagx=/^\d{0,10}$/;
exports.accountHolderNameLabel = "Account Holder Name:";
exports.accountHolderNameValue = "Vision International Educational";
exports.accountNumberLabel = "Account Number:";
exports.accountNumberValue = "019101016599";
exports.bankNameLabel = "Bank Name:";
exports.bankNameValue = "Mashreq Bank";
exports.bankAddressLabel = "Bank Address:";
exports.bankAddressValue = "Al Ghurair City, 339-C, AGC, Al Rigga";
exports.banIfscLabel = "IBAN / IFSC:";
exports.banIfscValue = "AE900330000019101016599";
exports.swiftCodeLabel = "Swift Code:";
exports.swiftCodeValue = "BOMLAEAD";
exports.currencyPreferenceLabel = "Currency Preference:";
exports.currencyPreferenceValue = "USD";
exports.editButtonText = "Edit";
exports.deleteButtonText = "Delete";
exports.addNewBankAccountButtonText = "Add New Bank Account";
exports.deleteBankAccountTitle = "Delete Bank Account?";
exports.deleteBankAccountConfirmation = "Are you sure you want to delete";
exports.bankName = "State Bank Of India "; 
exports.deleteButtonText="Delete";
exports.goBackBtnText="Go back";
exports.uploadFile = "Upload Agreement"
exports.signedDcoument = "Add Signed Agreement"
exports.fileUploadError = "This document doesn’t consist of required fields. Please make changes and upload again."
exports.doneText = "Done"
exports.pendingText = "Pending"
exports.failedText = "Fix Required"
exports.sectionTitle = "Terms & conditions"
exports.acceptedFileFormats = "Accepted file format: .png, .jpg, .pdf, .doc, .docx"
exports.downloadText = "Download Agreement"
exports.downloadBtnText = "Download"
exports.readItThrough = "Read it through"
exports.manageBranch = "Manage Branch"
exports.addBranchPopupTitle = "Add Branch"
exports.branchName = "Branch Name"
exports.branchEmail = "E-mail"
exports.branchNamePlaceholder = "Enter Branch Name"
exports.branchEmailPlaceholder =  "Enter E-mail ID"
exports.branchMobile = "Mobile"
exports.branchMobilePlaceholder = "Enter Mobile number"
exports.manageMember = "Managed By"
exports.addBranchBtn = "Add Branch"
exports.fetchAllBranch = "account_block/manage_branches"
exports.fetchSingleBranch = "account_block/manage_branches/"
exports.getCountryCode = "/bx_block_dashboard/students/get_country_code"
exports.getManagedBy = "account_block/manage_branches/managed_by"
exports.saveBranch = "account_block/manage_branches"
exports.validateMobileNumber = "/bx_block_dashboard/students/check_mobile_number"
exports.addDocuments = "Add document"
exports.fetchAllBankDetails = "account_block/bank_informations"
exports.getAllDocuments="bx_block_documentation/documents"
exports.updateBankDetail = "account_block/bank_informations"
exports.uploadlDocuments="bx_block_documentation/documents/add_documents"
exports.uploadPersonalId="bx_block_documentation/documents/add_personal_id"
exports.uploadBuisnessDocs="bx_block_documentation/documents/add_business_registration_certificate"
exports.deleteDocuments="bx_block_documentation/documents/"
exports.documentAddText="Add Document"
exports.fetchAllAgreementsDoc = "bx_block_terms_and_conditions/admin_agreements"
exports.uploadlAgreementDoc="bx_block_terms_and_conditions/agent_agreements"
exports.deleteAgentAgreement= "bx_block_terms_and_conditions/agent_agreements/"
exports.updateNotificationPreference = "account_block/accounts/enable_notification"
exports.addStaffBtnText = "Add New Staff"
exports.staffSerialNo = "S.No."
exports.staffFirstName = "First name"
exports.staffLastName = "Last name"
exports.staffEmail = "Email"
exports.staffDesignation = "Designation"
exports.staffRole = "Role"
exports.staffMobile = "Mobile"
exports.staffStatus = "Status"
exports.staffActions = "Actions"
exports.asignedDummyValue = " assigned"
exports.dummyExpandedText = "Lorem ipsum dolor sit, amet consectetur adipisicing elit.Tempora, officia!"
exports.dummyManageStaffData = [
  {
      id: 406,
      type: "account",
      attributes: {
        first_name: "rajat",
        last_name: "sonaniya",
        email: "rajat@yopmail.com",
        phone_number: 9630146262,
        country_code: 91,
        role: "sub_admin",
        status: "active",
      },
    },
    {
      id: 407,
      type: "account",
      attributes: {
        first_name: "rajat",
        last_name: "sonaniya",
        email: "rajat123@yopmail.com",
        phone_number: 9630146261,
        country_code: 91,
        role: "sub_admin",
        status: "active",
      },
    },
    {
      id: 408,
      type: "account",
      attributes: {
        first_name: "test user",
        last_name: "kjkhknkj",
        email: "rajat123@yopmail.com",
        phone_number: 9630146261,
        country_code: 91,
        role: "sub_admin",
        status: "inactive",
      },
    },
    {
      id: 409,
      type: "account",
      attributes: {
        first_name: "rajat",
        last_name: "sonaniya",
        email: "rajat123@yopmail.com",
        phone_number: 9630146261,
        country_code: 91,
        role: "sub_admin",
        status: "active",
      },
    },
]
exports.appIdText = "App Id"
exports.dummyAppId = "1234567"
exports.statusText = "Status"
exports.dummyStatus = "In Progress"
exports.viewAppBtnText = "View App"
exports.assignedToText = "Assigned To"
exports.reassignText = "Reassign"
exports.userNameTxt = "Name:"
exports.designationTxt = "Designation:"
exports.emailTxt = "Email:"
exports.dummyName = "Neha Bhatia"
exports.dummyDesignation = "Counsellor"
exports.dummyLongEmail = "nehwwwwwwwwwwwwwwwwabhatia@gmail.com"
exports.dummyNormalEmail = "nehabhatia@gmail.com"
exports.assignedAppTxt = "Assigned Application"
exports.reassignAllToTxt = "Reassign"
exports.reassignWarnTxt = " Reassign all assigned apps to other agent(s) in order to deactivate this profile"
exports.Character30Limit = /^.{30}$/
exports.deactivateBtntxt = "Deactivate"
exports.sendForRevieEndPoint = "account_block/accounts/send_for_review"
exports.percentageSymbol = "%"
exports.underReview = "UNDER REVIEW"
exports.rejected = "REJECTED"
exports.conditionallyApproved = "CONDITIONALLY APPROVED"
exports.fullyApproved = "FULLY APPROVED"
exports.getAllStudentListForReassign = "bx_block_roles_permissions/roles_and_permissions/set_reassign_student"
exports.reassignAllStudents = "bx_block_roles_permissions/roles_and_permissions/reassign_student"
exports.activeDeactive = "account_block/accounts/deactivate_staff"
// Customizable Area End
