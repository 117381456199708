// Customizable Area Start
import React from "react";
import { styled } from "@mui/material/styles";
import Stack from '@mui/material/Stack';
import PaginationCompoentController from "../src/PaginationCompoentController.web";
import Pagination from '@material-ui/lab/Pagination';
import { Box, Typography } from "@mui/material";
// Customizable Area End

export default class PaginationCompoent extends PaginationCompoentController {
  // Customizable Area Start

  

  RednerPagination = () => {
    const { currentPage } = this.state;
    const { data, itemsPerPage } = this.props;
    const totalPages = Math.ceil(data.length / itemsPerPage);
    return (
      <div>
        <PagginationBox>
          <Pagination
            count={totalPages}
            page={currentPage}
            onChange={this.handleChangePage}
            color="primary"
            data-test-id="pagination-component"
          />
          <Typography className="showingTxt">
            {this.renderResultText()}
          </Typography>
        </PagginationBox>
      </div>
    )
  }
  // Customizable Area End

  render() {
    return (
      // Customizable Area Start
      this.RednerPagination()
      // Customizable Area End
    );
  }
}

// Customizable Area Start
const PagginationBox = styled(Stack)({
  display: "flex",
  justifyContent: "center",
  alignItems: "center",
  flexDirection: "row",
  marginTop: "20px",
  position: "relative",
  "& .MuiSvgIcon-root": {
    fill: "green"
  },
  "& .MuiPaginationItem-page.Mui-selected": {
    backgroundColor: "unset",
    color: "green",
    borderBottom: "unset !important"
  },
  "& .MuiPaginationItem-page.Mui-selected::before": {
    backgroundColor: "transparent !important",
  },
  "& .MuiPagination-ul .MuiButtonBase-root": {
    position: "relative"
  },
  "& .showingTxt": {
    fontFamily: "Plus Jakarta Sans",
    fontSize: "14px",
    color: "#212121",
    position: "absolute",
    right: "0px"
  }
});

// Customizable Area End