Object.defineProperty(exports, "__esModule", {
  value: true
});

// Customizable Area Start
exports.validationApiContentType = "application/json";
exports.validationApiMethodType = "GET";
exports.exampleAPiEndPoint = "EXAMPLE_URL";
exports.exampleAPiMethod = "POST";
exports.exampleApiContentType = "application/json";
exports.textInputPlaceHolder = "Enter Text";
exports.labelTitleText = "conversationthreading";
exports.labelBodyText = "conversationthreading Body";
exports.getProfileEndPoint = "/account_block/accounts/";

exports.btnExampleTitle = "CLICK ME";
exports.showSinglePostApiEndPoint = "bx_block_conversationthreading/posts/";
exports.showSinglePostAPiMethod = "GET"
exports.SendCommentApiEndPoint = (postid) => {
  let apiendpoint =  `bx_block_conversationthreading/posts/${postid}/comments`
  return apiendpoint
}
exports.SendCommentAPiMethod = "POST"
exports.GetAllUserCommentApiEndPoint = (postid) => {
  let apiendpoint =  `bx_block_conversationthreading/posts/${postid}/comments`
  return apiendpoint
}
exports.GetAllUserCommentAPiMethod = "GET"

exports.SendChildCommentApiEndPoint = (postid) => {
  let apiendpoint =  `bx_block_conversationthreading/posts/${(postid)}/comments`
  return apiendpoint
}
exports.SendChildCommentAPiMethod = "POST"
exports.fetchChildCommentsButtonApiEndPoint = (postid,parentid) => {
  let apiendpoint =  `bx_block_conversationthreading/posts/${postid}/comments/${parentid}/show_child_comments`
  return apiendpoint
}
exports.fetchChildCommentsButtonAPiMethod = "GET"

//Labels
exports.labelReplyText="Reply"
exports.labelCommentText="Comment"
exports.labelShowMoreText="Show More"
exports.labelShowLessText="Show Less"

exports.labelsCreateFormFields = {
  reply: { label: "Send Reply", placeHolder: "Reply..." },
  projectDescription: { label: "Project Description", placeHolder: "Enter Project Description" },
  clientDetails: { label: "Client Details", placeHolder: "Enter Client Details" },
  teamSize: { label: "Team Size", placeHolder: "Enter Team Size" },
  projectUrl: { label: "Project URL", placeHolder: "Enter Team Size" },
  developerEmail: { label: "Email", placeHolder: "Enter Email" },
  developerFullName: { label: "Full Name", placeHolder: "Enter Full Name" },
  phoneNumber: { label: "Phone Number", placeHolder: "Enter Phone Number" },
  startDate: { label: "Start Date", placeHolder: "Enter Start Date" },
  endDate: { label: "End Date", placeHolder: "Enter End Date" },
  projectImages: { label: "Project Images", placeHolder: "" },
  projectDocuments: { label: "Project Documents", placeHolder: "" },
}
// Customizable Area End