// Customizable Area Start
import { BlockComponent } from "framework/src/BlockComponent";
import { IBlock } from "framework/src/IBlock";
import { runEngine } from "framework/src/RunEngine";
import { Message } from "framework/src/Message";
import MessageEnum, {
    getName,
} from "framework/src/Messages/MessageEnum";
// Customizable Area End

export interface Props {
    navigation: any;
    // Customizable Area Start
    data: unknown[];
    itemsPerPage: number;
    sendData: (data: unknown[]) => void;
    IsScrolToTop?: boolean;
    // Customizable Area End
}

interface S {
    // Customizable Area Start
    currentPage: number; // Current page number
    ShowResultText: string;
    // Customizable Area End
}

interface SS {
    // Customizable Area Start
    navigation: string;
    // Customizable Area End
}
// Customizable Area Start

export const configJSON = require("./config");

// Customizable Area End

export default class PaginationCompoentController extends BlockComponent<
    Props,
    S,
    SS
> {
    // Customizable Area Start
    getProfileAPICallId: string = "";
    GetAllApplicationsListApiCallId: string = "";
    filterLatestUpdatedByDateApiId: string = "";
    // Customizable Area End

    constructor(props: Props) {
        super(props);
        this.subScribedMessages = [
            // Customizable Area Start
            getName(MessageEnum.RestAPIResponceMessage),
            getName(MessageEnum.NavigationPayLoadMessage),
            getName(MessageEnum.CountryCodeMessage),
            // Customizable Area End
        ];

        this.receive = this.receive.bind(this);
        runEngine.attachBuildingBlock(this as IBlock, this.subScribedMessages);


        // Customizable Area Start
        this.state = {
            currentPage: 1,
            ShowResultText: ""
        };

        // Customizable Area End
    }

    async receive(from: string, message: Message) {
        // Customizable Area Start
        if (getName(MessageEnum.RestAPIResponceMessage) === message.id) {
            const apiRequestCallId = message.getData(
                getName(MessageEnum.RestAPIResponceDataMessage)
            );
            const responseJson = message.getData(
                getName(MessageEnum.RestAPIResponceSuccessMessage)
            );
            if (apiRequestCallId && responseJson) {
                if (apiRequestCallId === this.getProfileAPICallId) {
                    //   this.getProfileFormSuccessCallBack(responseJson)
                }
            }
        }
        // Customizable Area End
    }

    // Customizable Area Start
    notifyParent = () => {
        const { data, itemsPerPage, sendData } = this.props;
        const { currentPage } = this.state;

        const startIndex = (currentPage - 1) * itemsPerPage;
        const currentData = data.slice(startIndex, startIndex + itemsPerPage);

        if (sendData) {
            sendData(currentData); // Call the callback with updated data
        }
    };
    handleChangePage = (_event: React.ChangeEvent<unknown>, value: number) => {
        if (this.props.IsScrolToTop === undefined) {
            window.scrollTo(0, 0);
        }
        this.setState({ currentPage: value }, () => {
            this.notifyParent()
        });
    };

    renderResultText = () => {
        const { data, itemsPerPage } = this.props;
        const rowsPerPage = itemsPerPage;
        const currentPage = this.state.currentPage;
        const startIndex = (currentPage - 1) * rowsPerPage + 1;
        // const totalRecords = data.slice(startIndex, startIndex + itemsPerPage).length;
        const endIndex = Math.min(startIndex + rowsPerPage - 1, data.length);
        if (data.length === 0) {
            return `${"Showing Results"} 0 to 0 of 0`;
        }
        return `${"Showing Results"} ${Number(startIndex)} to ${endIndex} of ${data.length}` 
    };

    // Customizable Area End
}