import React from "react";
// Customizable Area Start
import AppHeader from "../../../../packages/components/src/AppHeader.web";
import { Box, Chip, InputAdornment, ListItemText, Modal, Paper, Select, Typography, Button, Checkbox, styled, TextField, SvgIcon, MenuItem, Card, Icon } from "@material-ui/core";
import { createTheme, ThemeProvider } from "@material-ui/core/styles";
import SideMenuBar from "../../../../packages/components/src/SideMenuBar.web";
import SearchIcon from "@material-ui/icons/Search";
import FilterListIcon from "@material-ui/icons/FilterList";
import * as IMG_CONST from "./assets";
import { ProgramData } from "./CatalogueController";
import FilterListOutlinedIcon from '@material-ui/icons/FilterListOutlined';
import Autocomplete from '@mui/material/Autocomplete';
import CloseIcon from '@mui/icons-material/Close';
import CancelOutlinedIcon from '@mui/icons-material/CancelOutlined';
import ClearOutlinedIcon from '@mui/icons-material/ClearOutlined';
import { ToastContainer } from "react-toastify";
import DeleteIcon from '@mui/icons-material/Delete';
import SentRequest from "../../requestmanagement/src/SentRequest.web";
import PaginationCompoent from "../../pagination/src/PaginationCompoent.web";
import Loader from "../../../components/src/Loader.web"
// Customizable Area End

import CatalogueController, { Props } from "./CatalogueController";

const theme = createTheme({
  palette: {
    primary: {
      main: "#0000ff",
      contrastText: "#fff",
    },
  },
});

export default class Catalogue extends CatalogueController {
  constructor(props: Props) {
    super(props);
    // Customizable Area Start
    // Customizable Area End
  }

  // Customizable Area Start
  getWebList(item: any) {
    let value = item.attributes;
    return (
      <Box sx={webStyle.productBox} key={`product-item-${item.id}`}>
        <Box sx={webStyle.ImgContainer}>
          <img style={webStyle.productImg} src={value.images?.[0].url} />
        </Box>
        <Box sx={webStyle.detailContent}>
          <Typography variant="h6">{value.name}</Typography>
          <Typography variant="h6">{value.price}</Typography>
          <Typography variant="h6">{value.average_rating}</Typography>
        </Box>
      </Box>
    );
  }
  showErrorToast = () => {
    return (
      <ToastContainer
        position="top-right"
        autoClose={3000}
        hideProgressBar={false}
        closeOnClick={true}
        pauseOnHover={true}
        draggable={true}
        theme="colored"
      />
    )
  }

  renderShortlistButton(program:any, isShortlisted:any) {
    if (this.state.courseFinderData) {
      if (isShortlisted) {
        return (
          <div className="addedBtns-wrapp">
            <Button className="added-btns">
              <img src={IMG_CONST.tickIcon} alt="Tick Icon" />
              Added to shortlist
            </Button>
            <Button onClick={() => this.handleDeleteSHortlist(program)}>
              <DeleteIcon />
            </Button>
          </div>
        );
      } else {
        return (
          <Button className="added-btns" onClick={() => this.addShortlistBtn(program)}>
            <img src={IMG_CONST.addIcon} alt="Add Icon" />
            Add to shortlist
          </Button>
        );
      }
    } else {
      return (
        <Button
          variant="outlined"
          color="primary"
          className="shortListbtn"
          onClick={() => this.shortListModal(program)}
          data-test-id="shortlist-btn"
        >
          <img src={IMG_CONST.addIconWhite} alt="Add Icon" />
          Add to shortlist
        </Button>
      );
    }
  }

  renderFilter = () => {
    const {isFilterPopUpOpen, countries, programLevel, discipline, selectedCountries, selectedProgramLevel, selectedDiscipline, intakes, selectedIntakes, durations, selectedDurations} = this.state;

    return (
      <MainModal
        disablePortal
        disableEnforceFocus
        disableAutoFocus
        open={isFilterPopUpOpen}
        onClose={this.handleFilterPopUp}
        className="modal"
        data-test-id="mainModalTestID"
      >
        <Paper className="paper" data-test-id="paperTestID">
          <BoxPopupClose data-test-id="boxPopupCloseTestID">
            <CloseIcon data-test-id="closeIconTestID" onClick={this.handleFilterPopUp} />
          </BoxPopupClose>
          <Box>
            <BoxPopUpTitle className="filterTitle">
              <FilterListOutlinedIcon/>
              <Typography className="heading" data-test-id="headingTestID">
                Filter
              </Typography>
            </BoxPopUpTitle>
          </Box>
          

            <Box className="formFieldBox">
              <Typography className="fieldText" data-test-id="currencyCode">
              Country
              </Typography>
              <Autocomplete
                multiple
                value={selectedCountries}
                data-test-id="selectCountries"
                className="selectInput autocompleteField"
                options={countries}
                getOptionLabel={(country: any) => country}
                disableCloseOnSelect
                onChange={this.handleCountriesChange}
                renderOption={(props, country: any, { selected }) => {
                  const { key, ...otherProps } = props;
                  return (
                    <Box component="li" key={country} {...otherProps}>
                      <StyledCheckBox checked={selected} style={{ marginRight: 8 }} />
                      <ListItemText
                        primary={country}
                      />
                    </Box>
                  );
                }}
                renderInput={(params) => (
                  <TextField
                    {...params}
                    className="intakePlaceholder"
                    variant="standard"
                    placeholder={"Select Country"}
                    InputLabelProps={{ shrink: true, ...params.InputProps }}
                  />
                )}
                renderTags={() => null}
              />
              <Box mt={2}>
                {selectedCountries.map((option) => (
                  <StyledChip
                    data-test-id="removedSelectedCountries"
                    key={option}
                    label={option}
                    onDelete={() => this.handleRemoveCountries(option)}
                    deleteIcon={<SvgIcon component={CancelOutlinedIcon} style={{ color: 'red' }} />}
                  />
                ))}
              </Box>
            </Box>

            <Box className="countryBox formFieldBox">
              <Typography className="fieldText" data-test-id="currencyCode">
               Program Level
              </Typography>
              <Autocomplete
                multiple
                value={selectedProgramLevel}
                data-test-id="selectProgramLevel"
                className="selectInput autocompleteField"
                options={programLevel}
                onChange={this.handleProgramLevelChange}
                renderOption={(props, programLevel: any, { selected }) => {
                  const { key, ...otherProps } = props;
                  return (
                    <Box component="li" key={programLevel} {...otherProps}>
                      <StyledCheckBox checked={selected} style={{ marginRight: 8 }} />
                      <ListItemText
                        primary={programLevel}
                      />
                    </Box>
                  );
                }}
                getOptionLabel={(programLevel: any) => programLevel}
                disableCloseOnSelect
                renderInput={(params) => (
                  <TextField
                    {...params}
                    className="intakePlaceholder"
                    variant="standard"
                    placeholder={"Select Program Level"}
                    InputLabelProps={{ shrink: true, ...params.InputProps }}
                  />
                )}
                renderTags={() => null}
              />
              <Box mt={2}>
                {selectedProgramLevel.map((option) => (
                  <StyledChip
                    data-test-id="removedSelectedProgramLevel"
                    key={option}
                    label={option}
                    onDelete={() => this.handleRemoveProgram(option)}
                    deleteIcon={<SvgIcon component={CancelOutlinedIcon} style={{ color: 'red' }} />}
                  />
                ))}
              </Box>
            </Box>

            <Box className="formFieldBox">
              <Typography className="fieldText" data-test-id="currencyCode">
              Discipline
              </Typography>
              <Autocomplete
                options={discipline}
                onChange={this.handleDisciplineChange}
                multiple
                value={selectedDiscipline}
                data-test-id="selectDiscipline"
                className="selectInput autocompleteField"
                renderOption={(props, discipline: any, { selected }) => {
                  const { key, ...otherProps } = props;
                  return (
                    <Box component="li" key={discipline} {...otherProps}>
                      <StyledCheckBox checked={selected} style={{ marginRight: 8 }} />
                      <ListItemText
                        primary={discipline}
                      />
                    </Box>
                  );
                }}
                getOptionLabel={(discipline: any) => discipline}
                disableCloseOnSelect
                renderInput={(params) => (
                  <TextField
                    {...params}
                    className="intakePlaceholder"
                    variant="standard"
                    placeholder={"Select Discipline"}
                    InputLabelProps={{ shrink: true, ...params.InputProps }}
                  />
                )}
                renderTags={() => null}
              />
              <Box mt={2}>
                {selectedDiscipline.map((option) => (
                  <StyledChip
                    data-test-id="removedSelectedDiscipline"
                    key={option}
                    label={option}
                    onDelete={() => this.handleRemoveDiscipline(option)}
                    deleteIcon={<SvgIcon component={CancelOutlinedIcon} style={{ color: 'red' }} />}
                  />
                ))}
              </Box>
            </Box>

            <Box className="formFieldBox">
              <Typography className="fieldText" data-test-id="currencyCode">
                Intake
              </Typography>

              <Autocomplete
                  data-test-id="selectIntake"
                  className="selectInput autocompleteField"
                  multiple
                options={intakes}
                getOptionLabel={(option: string) => option}
                value={selectedIntakes}
                  disableCloseOnSelect
                onChange={this.handleIntakesChange}
                renderOption={(props, option: string, { selected }) => {
                    const { key, ...otherProps } = props;
                    return (
                    <Box component="li" key={option} {...otherProps}>
                        <StyledCheckBox checked={selected} style={{ marginRight: 8 }} />
                        <ListItemText
                        primary={option}
                        />
                      </Box>
                    );
                  }}
                  renderInput={(params) => (
                    <TextField
                      {...params}
                      className="intakePlaceholder"
                      variant="standard"
                      placeholder={"Select Intake"}
                    InputLabelProps={{ shrink: true, ...params.InputProps }}
                    />
                  )}
                  renderTags={() => null}
                />
              <Box mt={2}>
                {selectedIntakes.map((option) => (
                  <StyledChip
                    data-test-id="removedSelectedIntakes"
                    key={option}
                    label={option}
                    onDelete={() => this.handleRemoveIntakes(option)}
                    deleteIcon={<SvgIcon component={CancelOutlinedIcon} style={{ color: 'red' }} />}
                  />
                ))}
              </Box>
            </Box>
            <Box className="formFieldBox">
              <Typography className="fieldText" data-test-id="currencyCode">
              Duration
              </Typography>
              <Autocomplete
                options={durations}
                onChange={this.handleDurationsChange}
                multiple
                value={selectedDurations}
                data-test-id="selectDuration"
                renderOption={(props, duration: string, { selected }) => {
                  const { key, ...otherProps } = props;
                  return (
                    <Box component="li" key={duration} {...otherProps}>
                      <StyledCheckBox checked={selected} style={{ marginRight: 8 }} />
                      <ListItemText
                        primary={duration}
                      />
                    </Box>
                  );
                }}
                className="selectInput autocompleteField"
                getOptionLabel={(duration: string) => duration}
                disableCloseOnSelect
                renderInput={(params) => (
                  <TextField
                    {...params}
                    className="intakePlaceholder"
                    variant="standard"
                    placeholder={"Select Duration"}
                    InputLabelProps={{ shrink: true, ...params.InputProps }}
                  />
                )}
                renderTags={() => null}
              /><Box mt={2}>
                {selectedDurations.map((option) => (
                  <StyledChip
                    data-test-id="removedSelectedDurations"
                    key={option}
                    label={option}
                    onDelete={() => this.handleRemoveDurations(option)}
                    deleteIcon={<SvgIcon component={CancelOutlinedIcon} style={{ color: 'red' }} />}
                  />
                ))}
              </Box>
            </Box>

            <StyledBoxButtonWrapper>
              <StyledButtonReset data-test-id="resetFilter" onClick={this.handleReset}><StyledTypoGraphyReset>Reset</StyledTypoGraphyReset></StyledButtonReset>
              <StyledApplyFilterButton data-test-id="applyFilter" onClick={this.collectFilterData}><StyledTypoGraphyApply>Apply Filter</StyledTypoGraphyApply></StyledApplyFilterButton>
            </StyledBoxButtonWrapper>
  
        </Paper>
      </MainModal>
    )
  };

  renderMangeShortlist = () => {
    return (
      <MainModal
        disablePortal
        disableEnforceFocus
        disableAutoFocus
        open={this.state.MangeShortListModel}
        className="modal"
        data-test-id="MangeStaffModel"
        onClose={() => this.OpenCloseMangeStaff()}
      >
        <UpdatedMainModelStyle style={{flexDirection:'column'}} className="MangeStaff-paper" data-test-id="ManeStaffpaperTestID">
        <BoxPopupClose data-test-id="MangeStaffPopupCloseTestID">
                <CloseIcon
                  data-test-id="MangeStaffcloseIconTestID"
                  onClick={() => this.OpenCloseMangeStaff()}
                />
              </BoxPopupClose>
          <h4 style={{margin:0,fontSize:'20px'}}>Add to shortlist</h4>
          <p style={{margin:0}}>Select the student for which this program should be shortlisted. Once shortlisted selected program list will also be displayed in Student profile.</p>
          <Box style={{ width: "100%" }}>
            <Box className="HeadingContainer">
              <Box style={{width:'100%'}} >
              <Typography className="NewHeading" data-test-id="MangeStaffheadingTestID">
  {Array.isArray(this.state.shortListData) ? (
    this.state.shortListData.map((program: any, index: number) => (
      <div key={index} className="manageShortlist-list">
<strong>
  {`${program?.attributes?.university?.university_name}, ${program?.attributes?.university?.location}`}
</strong>        <p>{program?.attributes?.program_name}</p>
        <span>
  {`${program?.attributes?.sessions
    ?.filter((session: any) => session.availability === "open")
    ?.map((session: any) => session.intake_label)
    .join(', ')}`}
</span>
      </div>
    ))
  ) : (
    <div className="manageShortlist-list">
      <strong>{`${this.state.shortListData?.attributes?.university?.university_name} , ${this.state.shortListData?.attributes?.university?.location}`}</strong>
      <p>{this.state.shortListData?.attributes?.program_name}</p>
      <span>
  {`${this.state.shortListData?.attributes?.sessions
    ?.filter((session: any) => session.availability === "open")
    ?.map((session: any) => session.intake_label)
    .join(', ')}`}
</span>
    </div>
  )}
</Typography>
<p style={{fontSize:'16px',fontWeight:'600'}}>Student</p>
<Select
  value={this.state.selectedStudent?.id || ""}
  onChange={this.handleStudentChange}
  displayEmpty
  fullWidth
  className="student-select"
>
  <MenuItem value="">
    Select a student
  </MenuItem>
  {this.state.studentList?.map((student: any) => (
    <MenuItem className="menu-bar-item" key={student.id} value={student.id} style={{display:'flex',flexDirection:'column',alignItems:'flex-start',
    [theme.breakpoints.down("md")]: {
      maxWidth:'310px'
    },
    overflow:'scroll'}}>
      <p style={{ margin: 0,color:'#0F172A' }}>{student.first_name} {student.last_name}</p><div>
      <strong>Student ID:</strong> {student.student_id}, <strong>Passport No:</strong> {student.passport_number}</div>
    </MenuItem>
  ))}
</Select>
{!this.state.isStudentSelected && (
                  <Typography
                    style={{ color: "red", marginTop: "8px", fontSize: "17px" }}
                  >
                    Please select a student.
                  </Typography>
                )}
<div style={{textAlign:'center'}}>
<Button style={{
                backgroundColor: "#00D794",
                color: "white",
                height: "44px",
                padding: "0px 16px",
                borderRadius: "8px",
                textTransform: "none",
                // whiteSpace:'nowrap',
                marginTop:'10%'
              }}
              onClick={this.handleChoose}
              >
                <Typography style={{ textTransform: "none", fontWeight: 600, fontSize: "16px" }}>Add to the chosen student shortlist</Typography>
              </Button>
</div>



              </Box>
            </Box>
          </Box>
        </UpdatedMainModelStyle>
      </MainModal>
    );
  };
  // Customizable Area End

  render() {
    return (
      //Merge Engine DefaultContainer
      // Customizable Area Start
      <ThemeProvider theme={theme}>
        <Loader loading={this.state.loading} />
        <AppHeader 
       onHomeClick={this.onHomeClick}
       onLoginClick={undefined}
       userName={this.state.userDetails?.attributes?.first_name}
       onSignUpClick={undefined}
       toggleData={this.state.toggleDrawer}
       data-test-id="appHeaderTestId"
       handleToggle={this.handleToggle}
       isUserLogin={true}
       isLogin={false}
       role={this.state.userDetails?.attributes?.role}
       profileImg={this.state.userDetails?.attributes?.image}
          /> 
         {this.state.isAskForExpertVisible && <SentRequest
         {...this.state.courseFinderData && { defaultStudent:{
          student_id: this.state.courseFinderData.id,
          first_name: this.state.courseFinderData.firstName,
          passport_number: this.state.courseFinderData.passport_number
        } }}
         data-test-id="askForExpertModal" navigation="" id="" isAddRequestPopup={true} handleAddRequestPopup={this.handleHideAskForExpert}/>}
        <UserProfileBox>
          <SideMenuBar
            data-test-id="sidebarNavigationBtn"
            navigationToAnyPage={this.navigationToAnyPage}
            activeComponent="Catalogue"
          />
          <Box className="innerBox">
            <BigBox>
              <Box>
              <div className="card-wraps">
              <Box>
              <Typography data-test-id="courseFinderId"
                  style={{
                    fontWeight: 700,
                    fontSize: "22px",
                    fontFamily: "Plus Jakarta Sans",
                    marginLeft: "8px",
                    whiteSpace:'nowrap',
                  }}
                >
                  Course Finder
                </Typography>
              </Box>              
              {this.state.courseFinderData ? (
          <Card style={{ display: "flex", alignItems: "center", padding: "10px" }}>
            <div style={{ display: "flex", alignItems: "center" }}>
              <img
                onClick={() => this.navigationToAnyPage("ViewProfile")}
                style={{ height: '38px', width: '38px', borderRadius: '50%' }}
                src={this.state.courseFinderData.image || "https://www.w3schools.com/html/img_chania.jpg"}
                alt="student image"
                data-test-id="studentImgTestID"
              />
              <h4 data-test-id="studentNameTestID" style={{ marginLeft: '10px', cursor:"pointer" }} onClick={() => this.navigationToAnyPage("ViewProfile")}>
                {this.state.courseFinderData.firstName} {this.state.courseFinderData.lastName}
              </h4>
            </div>
            <img onClick={this.handleDeleteUser} src={IMG_CONST.closeIcon} alt="Close Icon" style={{ marginLeft: 'auto' }} />
          </Card>
        ) : (
          null
        )}
              </div>
              </Box>
              <Box className="otherBlock">
              {this.state.selectedPrograms.length === 0 && (
              <Button className="expertBtn" onClick={this.handleShowAskForExpert} data-test-id="askForExpertBtn">
                <img src={IMG_CONST.tipsIcon} />
                <Typography style={{ textTransform: "none", fontWeight: 600, fontSize: "16px" }}>Ask for the Expert</Typography>
              </Button>
                )}             

{this.state.selectedPrograms.length >0 &&
              <div className="selected-divs">
               
                {this.state.selectedPrograms.length >5 && 
                <span className="max-size"><img src={IMG_CONST.infoIcon} style={{marginRight:'5px'}} /> Maximum 5 courses can be shortlisted to each student.</span>
                }
                 <span style={{margin:'0 26px',color:'#4D4D4D'}}>{this.state.selectedPrograms.length} courses selected</span>
                <Button
                style={{
                  backgroundColor: "#D6F6EB",
                  color: "#059669",
                   width: "224px",
                  height: "44px",
                  padding: "0px 16px",
                  borderRadius: "8px",
                  marginRight: "8px",
                  textTransform: "none",
                  border:'1px solid #34D399',
                  fontSize:'16px'
                }}
                onClick={
                  this.state.courseFinderData
                    ? this.handleShortlistLocalstore 
                    : this.handleShortlistAll         
                }
              disabled={this.state.selectedPrograms.length >5}
            >
              <img
                src={IMG_CONST.addIcon}
                alt="Add Icon"
                style={{marginRight:'10px'}}
              />
              Add to Shortlist
            </Button>
              </div>
              }
              
              <ExportButton data-test-id="exportButtonTestID" onClick={this.exportToExcel} startIcon={<img src={IMG_CONST.downLoadIcon} alt="icon" style={{ width: 24, height: 24 }} />}><ExportText>Export to Excel</ExportText></ExportButton>
              </Box>
            </BigBox>
            <div
              className="input-box-wrap">     
              <InputField
                name="passport"
                className="search-bar"
                data-test-id="passportId"
                placeholder="Search for programs,institution or other keywords"
                value={this.state.searchTerm}
                onChange={this.handleSearchTermChange}
                InputProps={{
                  startAdornment: (
                    <InputAdornment position="start">
                      <SearchIcon />
                    </InputAdornment>
                  ),
                }}
              />
              <Button 
              style={{
                width: "190px",
                height: "44px",
                padding: "10px 16px",
                gap: "8px",
                borderRadius: "8px",
                backgroundColor: "#373335",
                color: "white",
                textTransform: "none",
                  marginRight: "10px",
                }}
                onClick={this.handleFilterPopUp}
                data-test-id="handleFilterTestID"
              >
                {this.state.filteredCount > 0 ? (
                    <>
                      <Typography style={{marginRight: "10px",}}>{this.state.filteredCount}</Typography>
                      <Typography className="filterText">Filters Applied</Typography>
                      <SvgIcon
                        component={ClearOutlinedIcon}
                        style={{ color: '#FFF', cursor: 'pointer', marginLeft: 8 }}
                        onClick={this.handleReset}
                      />
                    </>
                  ) : (
                    <>
                      <FilterListOutlinedIcon />
                      Filters
                    </>
                  )}
              </Button>
            </div>
            {this.showErrorToast()}
            <div>
              {(this.state?.searchTerm
                ? this.state?.searchResults
                : this.state?.catalogueList
              ).length > 0 ? (
                (this.state?.searchTerm
                  ? this.state?.searchResults
                  : this.state?.catalogueList
                ).map((program: any) => {
                  const getShortListData = Array.isArray(this.state.getshortListData)
                    ? this.state.getshortListData
                    : [];

                    console.log(this.state.getshortListData,'gettt')
                    
                  const isShortlisted = getShortListData.some(
                    (shortlistedProgram: any) =>
                      shortlistedProgram.id === program.id &&
                      shortlistedProgram.attributes.is_shortlisted
                  );            
                  return (
                    <DashboardBox key={program.id}>
                      <Box
                        style={{ ...webStyles.backgroundColor }}
                        className="contentBox"
                      >
                        <Box className="content-finder">
                          <Checkbox
                            className="checkboxx"
                            checked={getShortListData.find(
                              (p: any) => p.id === program.id
                            )}
                            disabled={isShortlisted}
                            onChange={() => this.handleCheckboxChange(program)}
                            data-test-id={`shortlist${program.id}`}
                          />
                          <Box
                            className="university-boxes"
                          >
                            <Box display="flex" alignItems="center">
                              <img
                                src={
                                  program?.attributes?.university?.logo?.url ||
                                  "https://www.w3schools.com/html/pic_trulli.jpg"
                                }
                                alt="University logo"
                                className="university-logo"
                              />
                              <Box>
                                <Typography className="course-heading">
                                  {program.attributes.program_name}
                                </Typography>
                                <div
                                  className="course-heading-wrap"
                                >
                                  <div
                                    style={{
                                      display: "flex",
                                      alignItems: "center",
                                      marginRight: "20%",
                                      cursor:'pointer',
                                    }}
                                  >
                                    <img
                                      src={IMG_CONST.cakeIcon}
                                      alt="WhatsApp Icon"
                                    />
                                    <Typography
                                      className="university"
                                      color="textSecondary"
                                      onClick={() => this.navigationToInstitute(program.id, program.attributes.university
                                        .university_name)}
                                    >
                                      {
                                        program.attributes.university
                                          .university_name
                                      }
                                    </Typography>
                                  </div>
                                  <div
                                    style={{
                                      display: "flex",
                                      alignItems: "center",
                                    }}
                                  >
                                    <img
                                      src={IMG_CONST.locationIcon}
                                      alt="WhatsApp Icon"
                                    />
                                    <Typography
                                      className="university"
                                      color="textSecondary"
                                      style={{ marginLeft: "5%" }}
                                    >
                                      {program.attributes.university.location},
                                      {program.attributes.university.country}
                                    </Typography>
                                  </div>
                                </div>
                              </Box>
                            </Box>
                            {this.renderShortlistButton(program, isShortlisted)}

                          </Box>

                          <Box
                            sx={{
                              borderTop: "2px solid #B6373C",
                              marginTop: "26px",
                              paddingTop: "26px",
                            }}
                          >
                            {/* Intake Information */}
                            <Box
                              className="intake-box"
                              display="flex"
                              justifyContent="space-around"
                            >
                              {program?.attributes?.sessions?.map(
                                (session: any, index: number) => (
                                  <Box
                                    key={index}
                                    textAlign="center"
                                    className="intake-box-detail"
                                  >
                                    <Typography
                                      variant="body2"
                                      style={{ fontWeight: '700', fontFamily: 'Plus Jakarta Sans' }}
                                    >
                                      {session.intake_label}
                                    </Typography>

                                    {/* Display the availability as status */}
                                    <span
                                      className={
                                        session.availability === "open"
                                          ? "statusActive"
                                          : "statusInactive"
                                      }
                                    >
                                      {session.availability}
                                    </span>
                                  </Box>
                                )
                              )}
                            </Box>

                            {/* Fee Details */}
                            <Box
                              className="secondary-boxes"
                            >
                              <Box>
                                <Typography
                                  className="manage-text"
                                  variant="body2"
                                  color="textSecondary"
                                >
                                  FEES AFTER SCHOLARSHIP (APPROX)
                                </Typography>
                                <Typography
                                  className="manage-text-detail"
                                  variant="body2"
                                >
                                  {program.attributes.fees_after_scholarship}
                                </Typography>
                              </Box>
                              <Box>
                                <Typography
                                  className="manage-text"
                                  variant="body2"
                                  color="textSecondary"
                                >
                                  CAS DEPOSIT (APPROX)
                                </Typography>
                                <Typography
                                  className="manage-text-detail"
                                  variant="body2"
                                >
                                  {program.attributes.cash_deposit}
                                </Typography>
                              </Box>
                              <Box>
                                <Typography
                                  className="manage-text"
                                  variant="body2"
                                  color="textSecondary"
                                >
                                  APP FEES
                                </Typography>
                                <Typography
                                  className="manage-text-detail"
                                  variant="body2"
                                >
                                  {program.attributes.fees}
                                </Typography>
                              </Box>
                              <Box>
                                <Typography
                                  className="manage-text"
                                  variant="body2"
                                  color="textSecondary"
                                >
                                  OFFER TAT
                                </Typography>
                                <Typography
                                  className="manage-text-detail"
                                  variant="body2"
                                >
                                  {program.attributes.offer_tat} Days
                                </Typography>
                              </Box>
                              <Box>
                                <Typography
                                  className="manage-text"
                                  variant="body2"
                                  color="textSecondary"
                                >
                                  DURATION
                                </Typography>
                                <Typography
                                  className="manage-text-detail"
                                  variant="body2"
                                >
                                  {program.attributes.duration}
                                </Typography>
                              </Box>
                              <Box>
                                <Typography
                                  className="manage-text"
                                  variant="body2"
                                  color="textSecondary"
                                >
                                  PROGRAM FIELD
                                </Typography>
                                <Typography
                                  className="manage-text-detail"
                                  variant="body2"
                                >
                                  {program.attributes.program_level}
                                </Typography>
                              </Box>
                              <Box>
                                <Typography
                                  className="manage-text"
                                  variant="body2"
                                  color="textSecondary"
                                >
                                  DISCIPLINE
                                </Typography>
                                <Typography
                                  variant="body2"
                                  className="manage-text-detail"
                                >
                                  {program.attributes.discipline}
                                </Typography>
                              </Box>
                            </Box>
                          </Box>
                        </Box>
                      </Box>
                    </DashboardBox>
                  )
                })
              ) : (
                <p style={{ paddingLeft: '190px' }}>{this.state.loading}</p>
              )}
              <PaginationCompoent data-test-id="PagiNationCompoentId" data={this.state?.searchTerm
                  ? this.state?.searchResults
                  : this.state?.AllcatalogueList} itemsPerPage={10} sendData={this.handelPropsChange} navigation={undefined}  />
            </div>
          </Box>

          {/* write your code here for that UI */}

          
          
    {this.state.MangeShortListModel && this.renderMangeShortlist()}
        </UserProfileBox>
        {this.state.isFilterPopUpOpen && this.renderFilter()}
      </ThemeProvider>
      // Customizable Area End
      //Merge Engine End DefaultContainer
    );
  }
}

// Customizable Area Start
const webStyle = {
  productContainer: {
    display: "flex",
    flexDirection: "row",
    flexWrap: "wrap",
    width: "100%",
    backgroundColor: "white",
    marginTop: "20px",
    fontFamily: "Plus Jakarta Sans",
  },
  productBox: {
    height: 250,
    width: "49%",
    marginRight: "5px",
    flexDirection: "column",
    fontFamily: "Plus Jakarta Sans",
  },
  ImgContainer: {
    //marginBottom: 15,
    height: 150,
  },
  productImg: {
    width: "100%",
    height: "100%",
  },
  detailContent: {
    display: "flex",
    flexDirection: "column",
  },
};

const ExportButton = styled(Button)({
    backgroundColor: "#34D399",
    color: "#ffffff",
    fontFamily: "Plus Jakarta Sans",
    fontSize: "14px",
    fontWeight: 700,
    lineHeight: "18px",
    padding: "10px 20px",
    borderRadius: "8px",
    textTransform: "none",
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    gap: "8px",
    "&:hover": {
      backgroundColor: "#34D399",
    },
})

const ExportText = styled(Typography)({
  fontFamily: "Plus Jakarta Sans",
  fontSize: "16px",
  fontWeight: 700,
  lineHeight: "24px",
  textAlign: "left",
  textUnderlinePosition: "from-font",
  textDecorationSkipInk: "none",
})

const UserProfileBox = styled(Box)({
  display:"flex",
  "& .university-boxes":{
    display:"flex",
    alignItems:"center",
    justifyContent:"space-between",
    "@media (max-width: 600px)": {
      flexDirection:'column'
    },
    "& button":{
      "@media (max-width: 600px)": {
        marginTop:'6px'
      },
    },
  },
  "& .search-bar input::placeholder": {
    color: '#94A3B8',
    fontWeight:400,
    fontSize:'16px',
  },
    "& .search-bar .MuiSvgIcon-root":{
      fontSize: '1.5rem',
      fill:'#94A3B8B2',
    },
  "& .selected-divs":{
    display: 'flex', 
    alignItems: 'center',
    "@media (max-width: 768px)": {
      flexDirection:'column'
    }
  },
  "& .addedBtns-wrapp":{
    "& .MuiSvgIcon-fontSizeMedium":{
      color:'#f00',
      marginTop:'-3px',
    }
  },
  "& .added-btns":{
    border:'1px solid #E2E8F0',
    textTransform:'capitalize',
    color:'#0F172A',
    fontSize:'14px',
    borderRadius: '8px',
    "& img":{
      marginRight: '7px',
  marginBottom: '3px'
    }
  },
  "& .expertBtn":{
    border:'1px solid #34D399',
    background:'#D6F6EB',
    color:'#059669',
    borderRadius:'8px',
    padding: '10px 17px',
  },
  "& .input-box-wrap":{
    display: "flex",
    alignItems: "center",
    justifyContent: "space-between",
    flexDirection:'row',
    "& button":{
      marginLeft:'2%'
    },
    "@media (max-width: 768px)": {
      flexDirection:'column'
    }
},
"& .card-wraps":{
display:'flex',
alignItems:'center',
"@media (max-width: 1200px)": {
  flexDirection:'column'
},
"& .MuiPaper-rounded":{
  padding: '0 18px',
  minWidth: '400px',
  justifyContent: 'space-between',
  borderRadius: '8px',
  display:'flex',
  alignItems:'center',
  border: '1px solid #CBD5E1',
  marginLeft:'3%',
  "@media (max-width: 1300px)": {
    padding: '0 10px',
    minWidth: '240px',
  },
  "@media (max-width: 768px)": {
    margin: '10px 0px',
  },
  "& div":{
    display:'flex',
    alignItems:'center',
  }
}
},
  "& .innerBox": {
    width:"100%",
    display: "flex",
    backgroundColor: "#F7F7F7",
    flexDirection: "column",
    padding: "0PX 40px",
    // justifyContent: "space-between",
    fontFamily: "Plus Jakarta Sans",
    overflowY:"scroll",
    [theme.breakpoints.down("md")]: {
      marginLeft: "44px",
      padding: "0PX",
    },
    "& .otherBlock": {
      display:"flex",
      justifyContent:"flex-end",
      gap:"10px",
      [theme.breakpoints.down("md")]: {
        flexDirection:"column"
      },
    },
  },
  "& .max-size":{
    backgroundColor: '#fff',
    padding: '11px 19px',
    fontSize: '12px',
    color: '#D97706',
    borderRadius: '6px',
    alignItems:'center',
    display:'inherit'
  },
});
const BigBox = styled(Box)({
  "@media (max-width:960px)": {
    display: "flex",
    flexDirection: "column",
    justifyContent: "center",
    alignItems: "center",
    marginTop: "8px",
    marginBottom: "8px",
  },
  "@media (min-width:720px)": {
    display: "flex",
    justifyContent: "space-between",
    alignItems: "center",
    marginTop: "20px",
    marginBottom: "20px",
  },
});

const DashboardBox = styled(Box)({
  background: "#F7F7F7",
  display: "flex",
  fontFamily: "Plus Jakarta Sans",
  "& .mainContainer": {
    padding: "30px",
  },
  "& .secondary-boxes":{
    display:"flex",
    justifyContent:"space-around",
    marginTop:"16px",
    textAlign:"center",
    "@media (max-width: 1440px)": {
      justifyContent: 'space-between',
      flexWrap: 'wrap'
    }
  },
  "& .secondary-boxes .MuiBox-root":{
    "@media (max-width: 1200px)": {
      width: '25%',
      margin: '0 2%',
    },
    "@media (max-width: 600px)": {
      width: '42%',
      margin: '0 2%',
    }
  },
  "& .university-logo":{
      height: "72px",
      marginRight: "23px",
      width: "72px",
      borderRadius: "50%",
      "@media (max-width: 1440px)": {
        height: "32px",
      marginRight: "15px",
      width: "32px",
      marginLeft:'8px'
      },
  },
  "& .MuiCheckbox-colorSecondary.Mui-checked":{
    color:'rgb(0, 215, 148)'
  },
  "& .checkboxx": {
    padding: "16px",
    position: "absolute",
    marginTop: "-44px",
    marginLeft: "-34px",
    "@media (max-width: 1440px)": {
      marginTop: '-21px',
      marginLeft: '-32px'
    }
  },
  "& .contentBox": {
    width: "100%",
    padding: "20px 0px",
    boxSizing: "border-box",
    maxWidth: "100%",
    "@media (max-width: 500px)": {
      padding: "20px",
    },
  },
  "& .content-finder": {
    position:"relative",
    background: "#fff",
    padding: "42px 42px 42px 38px",
    borderRadius: "5px",
    "@media (max-width: 1440px)": { // Adjust for smaller screens
      padding: "5px 17px",
    },
  },
  "& .shortListbtn": {
    color: "#FFFFFF",
    border: "1px solid #23CF93",
    borderRadius: "8px",
    textTransform: "unset",
    fontSize: "14px",
    padding:'4px 16px',
    fontFamily: "Plus Jakarta Sans",
    backgroundColor: "#34D399",
    width: "auto",
    maxWidth: "100%",
    "&:hover": {
      backgroundColor: "#34D399",
      color: "#FFFFFF",
    },
    "@media (max-width: 768px)": {
      fontSize: "12px",
    },
  },
  "& .course-heading": {
    fontWeight: "700",
    fontFamily: "Plus Jakarta Sans",
    fontSize: "20px",
    marginBottom:'11px',
    "@media (max-width: 1440px)": {
      fontSize: "16px",
    },
  },
  "& .course-heading-wrap":{
    display: 'flex',
     whiteSpace: 'nowrap',
     "@media (max-width: 768px)": {
      flexDirection:'column'
    },
  },
  "& .university": {
    fontFamily: "Plus Jakarta Sans",
    fontSize: "20px",
    marginLeft: "15px",
    color:'212121',
    "@media (max-width: 1440px)": {
     fontSize:'15px',
    },
    "@media (max-width: 768px)": {
      marginLeft: "6px",
    },
  },
  "& .intake-box": {
    borderBottom: "2px solid #B6373C",
    paddingBottom: "26px",
    "@media (max-width: 768px)": {
      flexDirection: 'column',
      alignItems: 'center',
     }
  },
  "& .intake-box-detail": {
    display: "flex",
    alignItems: "center",
    whiteSpace: "nowrap", // Default value
  
    "@media (max-width: 1440px)": {
      whiteSpace: "unset",
    },
    "@media (max-width: 768px)": {
      marginBottom:'13px',
     },
    "& p": {
      marginRight: "10%",
      fontSize:'16px', // Default margin-right for larger screens
  
      "@media (max-width: 1440px)": { // Adjust for smaller screens
        marginRight: "5%",
        fontSize:'12px' // For example, reduce margin-right on smaller screens
      },
      "@media (max-width: 1600px)": { // Adjust for smaller screens
        marginRight: "5%",
        fontSize:'14px' // For example, reduce margin-right on smaller screens
      },
    },
  },
  
  "& .statusActive": {
    color: "#3EAA86",
    padding: "8px 25px",
    fontSize: "14px",
    background: "#CFFBE6",
    fontWeight: "700",
    borderRadius: "5px",
    textTransform: "capitalize",
    border: "1px solid #3EAA86",
    "@media (max-width: 1440px)": { // Adjust for smaller screens
      padding: "5px 20px",
      fontSize:'12px' // For example, reduce margin-right on smaller screens
    },
  },
  "& .statusInactive": {
    background: "#DBC05433",
    color: "#D97706",
    padding: "8px 25px",
    borderRadius: "5px",
    textTransform: "capitalize",
    fontWeight: "700",
    fontSize: "14px",
    border: "1px solid",
    "@media (max-width: 1440px)": { // Adjust for smaller screens
      padding: "5px 20px",
      fontSize:'12px' // For example, reduce margin-right on smaller screens
    },
  },
  "& .manage-text": {
    color: "#4D4D4D",
    fontSize: "12px",
    textAlign: "left",
    fontFamily:'Plus Jakarta Sans',
    fontWeight:'700',
    "@media (max-width: 1400px)": { // Adjust for smaller screens
      fontSize:'10px' // For example, reduce margin-right on smaller screens
    },
  },
  "& .manage-text-detail": {
    color: "#4D4D4D",
    fontSize: "16px",
    textAlign: "left",
    marginTop: "10px",
    fontFamily:'Plus Jakarta Sans',
    fontWeight:'400',
    "@media (max-width: 1600px)": {
      fontSize:'15spx', 
    },
  },

  "& .mainItem": {
    paddingRight: "20px",
    boxSizing: "border-box",
  },
  "& .cardContainer": {
    boxSizing: "border-box",
  },
  "& .cards": {
    display: "flex",
    alignItems: "center",
    width: "100%",
    height: "90px",
    borderRadius: "8px",
    boxShadow: "rgba(149, 157, 165, 0.2) 0px 8px 24px",
    paddingLeft: "20px",
    bgcolor: "white",
    boxSizing: "border-box",
    backgroundColor: "#FFFFFF",
  },
});
const webStyles = {
  container: {
    display: "grid",
    gridTemplateColumns: "3fr 7fr",
    boxShadow: "rgba(0, 0, 0, 0.35) 0px 5px 15px",
    width: "32rem",
    padding: "0.5rem 1rem",
    minHeight: "12rem",
    gap: "2rem",
  },
  defaultTxt: {
    fontFamily: "Plus Jakarta Sans",
    fontSize: "16px",
  },
  defaultTxtBold: {
    fontFamily: "Plus Jakarta Sans",
    fontSize: "16px",
    fontWeight: 700,
  },
  totalCandidates: {
    display: "grid",
    gridTemplateRows: "3fr 7fr",
    alignItems: "start",
    justifyContent: "center",
    justifyItems: "center",
    color: "black",
    margin: "1rem 0rem",
  },
  italicStyle: {
    fontWeight: 500,
    fontFamily: "Plus Jakarta Sans",
    fontSize: "14px",
    fontStyle: "italic",
    color: "#212121",
    opacity: "40%",
  },
  candidatesFontSize: {
    fontSize: "1rem",
  },
  flexCenter: {
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
  },
  columnCenter: {
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
  },
  spaceAround: {
    display: "flex",
    justifyContent: "space-around",
    alignItems: "center",
  },
  spaceBetween: {
    display: "flex",
    justifyContent: "space-between",
    alignItems: "center",
    width: "100%",
  },
  paddingRight: {
    paddingRight: "40px",
  },
  cardContent: {
    fontWeight: 700,
    fontSize: "22px",
    lineHeight: "1",
    "&. MuiTypography-body1": {
      lineHeight: "1",
    },
  },
  cardTitle: {
    fontWeight: 400,
    fontSize: "14px",
    color: "#64748B",
  },
  fontBig: {
    fontFamily: "Plus Jakarta Sans",
    fontWeight: 400,
    fontSize: "16px",
    color: "#64748B",
  },
  secondCardHeader: {
    fontFamily: "Plus Jakarta Sans",
    fontWeight: 700,
    fontSize: "20px",
    color: "#212121",
  },
  btn: {
    backgroundColor: "blue",
    color: "white",
    borderRadius: "0",
    marginTop: "0.5rem",
    padding: "0.125rem",
  },
  flexEnd: {
    display: "flex",
    justifyContent: "flex-end",
    alignItems: "center",
  },
  fontSizeForType: { fontSize: "1 rem" },
  mediumFontSizeForType: { fontSize: "3rem" },
  itemStyle: { margin: "1rem 0rem" },
  type: { fontSize: "0.8rem", textTransform: "capitalize" },
  titletext: {
    color: "grey",
    fontWeight: "lighter",
    fontSize: "3rem",
  },
  typeText: { fontSize: "0.8rem", textTransform: "capitalize" },
  regularFont: {
    fontSize: "14px",
    color: "#212121",
    lineHeight: "17.64px",
    opacity: "80%",
  },
  listPadding: { padding: "0 11px" },
  backgroundColor: {
    fontFamily: "Plus Jakarta Sans",
    background: "#F7F7F7",
  },
  paddingAll: {
    padding: "20px",
  },
};
const InputField = styled(TextField)({
  width: "86%",
  border: "1px solid #CBD5E1",
  borderRadius: "8px",
  height: "50px",
  margin: "5px 0px",
  background: "white",
  display: "flex",
  justifyContent: "center",
  padding: "0px 10px",
  boxSizing: "border-box",
  "& .MuiInput-root": {
    "&:focus": {
      outline: "none",
    },
    "&::before": {
      content: "none",
    },
    "&::after": {
      content: "none",
    },
  },
  "& .MuiInputBase-input": {
    "&::placeholder": {
      color: "#64748B",
      fontWeight: 700,
      opacity: 10,
    },
  },
  "& .MuiSvgIcon-root": {
    fontSize: "2rem",
  },
});
const MainModal = styled(Modal)({
  display: "flex",
  justifyContent: "center",
  padding: "20px",
  "& .paper": {
    overflowY: "scroll",
    width: "480px",
    borderRadius: "10px",
    display: "flex",
    flexDirection: "column",
    padding: "30px 35px",
    gap: "20px",
    position:"relative",
    height:"auto"
  },
  "& .manageShortlist-list":{
    border: '1px solid #B6373C',
  padding: '9px',
  fontSize: '17px',
  textAlign: 'center',
  borderRadius: '28px',
  marginBottom:'20px',
  },
  "& .manageShortlist-list strong":{
  fontSize: '16px',
  },
  "& .manageShortlist-list p":{
    fontSize: '16px',
    fontWeight:400,
    color: '#4D4D4D',
  padding: '0',
  margin:'0',
    },
    "& .student-select":{
      padding: '8px 15px',
      border: '1px solid #CBD5E1',
      borderBottomWidth: '1px',
      borderBottomStyle: 'solid',
      borderBottomColor: 'rgb(203, 213, 225)',
      borderRadius: '8px',
      borderBottom: 'none',
      "&::before": {
        display: 'none',
      },
      "&::after": {
        display: 'none', 
      },
    },
    "& .student-select strong":{
      fontSize:'14px',
    },
    "& .student-select .MuiSelect-selectMenu":{
      background:'transparent',
    },
  "& .MangeStaff-paper":{
    overflowY: "scroll",
    borderRadius: "10px",
    display: "flex",
    padding: "30px 35px",
    gap: "20px",
    width: "480px",
    position:"relative",
    height:"auto"
  },
  "& .heading": {
    fontWeight: 700,
    fontSize: "20px",
    fontFamily: "Plus Jakarta Sans !important",
    color: "#000000",
    textAlign: "center",
    lineHeight:"24px"
  },
  "& .formFieldBox": {
    width: "100%",
    "& .selectField":{
      "& .MuiSelect-select.Mui-disabled":{
        color:"rgba(0, 0, 0, 0.38)"
      }
    },
    "& .selectInput": {
      justifyContent: "center",
      width: "100%",
      backgroundColor: "#fff",
      border: "1px solid #CBD5E1",
      borderRadius: "8px",
      margin: "5px 0px",
      height: "50px",
      display: "flex",
      padding: "10px 10px",
      boxSizing: "border-box",
      "& .MuiSelect-icon": {
        color: "#0E172A",
        right: "5px"
      },
      "& .MuiInput-root": {
        "&::before": {
          content: "none"
        },
        "&:focus": {
          outline: "none"
        },
        "&::after": {
          content: "none"
        }
      },
      "&:hover:not(.Mui-disabled):before": {
        borderBottom: "0px solid red"
      },
      "&:hover:not(.Mui-disabled):after": {
        borderBottom: "0px solid red"
      },
      "&.MuiInput-underline:before": {
        borderBottom: "0px solid red"
      },
      "&.MuiInput-underline:after": {
        borderBottom: "0px solid red"
      },
      "& .MuiSelect-select:focus": {
        backgroundColor: "transparent"
      }
    },
    "& .intakePlaceholder": {
      "& .MuiAutocomplete-input": {
        color: "rgba(0, 0, 0, 0.87)",
      }
    }
  },
  "& .inputFieldBox": {
    boxSizing: "border-box",
    width: "100%",
    display: "flex",
    justifyContent: "center",
    flexDirection: "column",
    marginBottom:"10px"
  },
  "& .inputField": {
    width: "100%",
    border: "1px solid #CBD5E1",
    borderRadius: "8px",
    height: "50px",
    margin: "5px 0px",
    display: "flex",
    justifyContent: "center",
    padding: "0px 10px",
    boxSizing: "border-box",
    "& .MuiInput-root": {
      "&:focus": {
        outline: "none"
      },
      "&::before": {
        content: "none"
      },
      "&::after": {
        content: "none"
      }
    }
  },
  "& .fieldText": {
    fontWeight: 700,
    fontSize: "16px",
    lineHeight: "24px",
    fontFamily: "Plus Jakarta Sans",
    color: "#334155"
  },
  "& .errorText": {
    color: "red"
  },
  "& .starTxt": {
    color: "red",
    fontFamily: "Plus Jakarta Sans !important"
  },
  "& .buttonBox": {
    display: "flex",
    alignItems: "center",
    width: "100%",
    justifyContent: "space-between",
    paddingTop:"20px"
  },
  "& .buttonBoxBranch": {
    display:"flex",
    justifyContent: "center",
    paddingTop: "34px",
  }, 
  "& .cancelBtn": {
    color: "rgba(52, 211, 153, 1)",
    background: "rgba(214, 246, 235, 1)",
    borderRadius: "8px",
    width: "45%",
    border: "1px solid rgba(52, 211, 153, 1)",
    textTransform: "none",
    "&.MuiButton-root:hover": {
      background: "rgba(214, 246, 235, 1)",
    }
  },
  "& .addBtn": {
    background: "rgba(52, 211, 153, 1)",
    borderRadius: "8px",
    width: "45%",
    textTransform: "none",
    color: "white",
     fontWeight:"700",
    fontSize:"16px",
    lineHeight:"24px",
    "&.MuiButton-root:hover": {
      background: "rgba(52, 211, 153, 1)"
    }
  },
  "@media (min-width: 600px)": {
    right: "14px",
    top: "14px"
  },
});
const UpdatedMainModelStyle = styled(Paper)({
  fontFamily: "Plus Jakarta Sans !important",
  "& .HeadingContainer" : {
    marginBottom: "20px",
    display:"flex",
    justifyContent:"flex-start"
  },
  "& .NewHeading":{
    color: "rgba(33, 33, 33, 1)",
    fontWeight: 700,
    fontSize: "24px !important",
    fontFamily: "Plus Jakarta Sans !important"
  },
  "& .MangeStaffMobileContainer": {
    width: "100%"
  },


})

const BoxPopupClose = styled(Box)({
  display: "flex",
  padding: "4px",
  alignItems: "flex-start",
  gap: "10px",
  position: "absolute",
  right: "16px",
  top: "16px",
  "@media (min-width: 600px)": {
    right: "14px",
    top: "14px"
  },
  "@media (min-width: 960px)": {
    right: "12px",
    top: "12px"
  }
});

const SelectInput = styled(Select)({
  "@media (max-width: 600px)": {
    fontSize: "15px"
  },
  backgroundColor: "#fff",
  width: "100%",
  border: "1px solid #CBD5E1",
  borderRadius: "8px",
  height: "50px",
  margin: "5px 0px",
  display: "flex",
  justifyContent: "center",
  padding: "0px 10px",
  boxSizing: "border-box",
  "& .MuiSelect-icon": {
    color: "#0E172A",
    right: "5px"
  },
  "& .MuiInput-root": {
    "&:focus": {
      outline: "none"
    },
    "&::before": {
      content: "none"
    },
    "&::after": {
      content: "none"
    }
  },
  "&.MuiInput-underline:before": {
    borderBottom: "0px solid red"
  },
  "&.MuiInput-underline:after": {
    borderBottom: "0px solid red"
  },
  "&:hover:not(.Mui-disabled):before": {
    borderBottom: "0px solid red"
  },
  "&:hover:not(.Mui-disabled):after": {
    borderBottom: "0px solid red"
  },
  "& .MuiSelect-select:focus": {
    backgroundColor: "transparent"
  }
});

const BoxPopUpTitle = styled(Box)({
  display: "flex",
  gap: "12px",
  justifyContent:"center",
  alignContent:"center",
  alignItems:"center"
});

const StyledCheckBox = styled(Checkbox)({
  borderRadius: "6px",
  width: "20px",
  height: "20px",
  "&.Mui-checked": {
    color: '#3D70B2',
  },
  "@media (min-width: 600px)": {
    width: "18px",
    height: "18px"
  },
  "@media (min-width: 960px)": {
    width: "16px",
    height: "16px"
  }
});


const StyledBoxButtonWrapper = styled(Box)({
  display: "flex",
  justifyContent: "center",
  gap: "24px",
  "@media (min-width: 600px)": {
    gap: "20px"
  },
  "@media (min-width: 960px)": {
    gap: "16px"
  }
});

const StyledButtonReset = styled(Button)({
  display: "flex",
  padding: "10px 16px",
  justifyContent: "center",
  alignItems: "center",
  gap: "8px",
  borderRadius: "8px",
  border: "1px solid #34D399",
  background: "#D6F6EB",
  "&:hover": {
    background: "#D6F6EB"
  },
  "@media (min-width: 600px)": {
    padding: "8px 14px"
  },
  "@media (min-width: 960px)": {
    padding: "6px 12px"
  }
});

const StyledApplyFilterButton = styled(Button)({
  display: "flex",
  padding: "10px 16px",
  justifyContent: "center",
  alignItems: "center",
  gap: "8px",
  borderRadius: "8px",
  background: "var(--Primary-green, #34D399)",
  "&:hover": {
    backgroundColor: "var(--Primary-green, #34D399) !important"
  },
  "@media (min-width: 600px)": {
    padding: "8px 14px"
  },
  "@media (min-width: 960px)": {
    padding: "6px 12px"
  }
});

const StyledTypoGraphyReset = styled(Typography)({
  color: "var(--Status-Green-600, var(--primary-green, #059669))",
  fontFamily: "Plus Jakarta Sans",
  fontSize: "16px",
  fontStyle: "normal",
  fontWeight: 700,
  lineHeight: "24px"
});

const StyledTypoGraphyApply = styled(Typography)({
  color: "var(--Basic-White, var(--0, #FFF))",
  fontFamily: "Plus Jakarta Sans",
  fontSize: "16px",
  fontStyle: "normal",
  fontWeight: 700,
  lineHeight: "24px"
});

const StyledChip = styled(Chip)({
  display: "inline-flex",
  justifyContent: "center",
  alignItems: "center",
  paddingRight: "4px",
  backgroundColor: "white !important",
  "& .MuiChip-label": {
    color: "var(--Neutrals-Cool-gray-600, #475569)",
    fontFamily: "Plus Jakarta Sans",
    fontSize: "14px",
    fontStyle: "normal",
    fontWeight: 700,
    lineHeight: "22px",
    "@media (min-width: 600px)": {
      fontSize: "13px",
      lineHeight: "20px"
    },
    "@media (min-width: 960px)": {
      fontSize: "12px",
      lineHeight: "18px"
    }
  }
});
// end of filter
// Customizable Area End
